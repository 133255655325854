/**
 * @generated SignedSource<<6f683e77921b2e664ddeac7234e3a857>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MainMenuFooter_content$data = {
  readonly footerLinks: ReadonlyArray<{
    readonly text: string;
    readonly url: string;
  }>;
  readonly " $fragmentType": "MainMenuFooter_content";
};
export type MainMenuFooter_content$key = {
  readonly " $data"?: MainMenuFooter_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"MainMenuFooter_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainMenuFooter_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MenuFooterLink",
      "kind": "LinkedField",
      "name": "footerLinks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Menu",
  "abstractKey": null
};

(node as any).hash = "a10076cc06bad2d8707045a16c90f23f";

export default node;
