import { PAFColor } from '@pafcloud/style';

export const Colors = {
  Text: PAFColor.Lighter,
  DepositQuickButtons: undefined,
  DepositQuickButtonsHover: undefined,
  Border: undefined,
  Background: PAFColor.Darker,
  SidebarContent: undefined,
  SidebarTopBackground: undefined,
  SidebarOverlay: 'rgba(0,0,0,0.3)',
  EmphasizedLink: undefined,
};
