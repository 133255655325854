/**
 * @generated SignedSource<<f27d6bddfb710e3da65ddee538a40b57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InboxMessageSubject = "MESSAGE" | "PAYMENT" | "PROMOTION" | "RADAR" | "RADAR_INBOX" | "RADAR_POPUP" | "WINNER";
import { FragmentRefs } from "relay-runtime";
export type MessageListItem_item$data = {
  readonly category: InboxMessageSubject;
  readonly messageId: string;
  readonly opened: boolean;
  readonly publishedAt: string;
  readonly subject: string;
  readonly " $fragmentType": "MessageListItem_item";
};
export type MessageListItem_item$key = {
  readonly " $data"?: MessageListItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"MessageListItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MessageListItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "opened",
      "storageKey": null
    }
  ],
  "type": "InboxMessagePreview",
  "abstractKey": null
};

(node as any).hash = "9fe85c21ef6710ada665eab26a981283";

export default node;
