import { createTransparentColor } from '../create-transparent-color';
import type {
  CornerRadiusType,
  ElevationType,
  GeneralType,
  HeroColorType,
  LayoutColors,
  PrimitiveColorType,
  SignalColorType,
  SurfaceColorType,
  TextColorType,
} from './color-types';

const OneOneBrandColor = {
  Carbon: '#1A1C21',

  // Primary Colors
  Primary: '#EF0E39',
  PrimaryDarker: '#E3092E',

  // Alternate Colors
  // 11 does not have a true alternate color, but white is used as such
  Alternate: '#ffffff',

  // Gradients
  GradientStart: '#EF0E39',
  GradientEnd: '#E53535',

  Black: '#000000',
  Darker: '#121316',
  Dark: '#25272d',
  Light: '#cdcbcb',
  Lightest: '#E0E2EA',

  Green: '#3fb950',
  Yellow: '#FFC659',
  Error: '#ff3333',

  Neutral: '#85858A',
  White: '#ffffff',
};

const Primitive: PrimitiveColorType = {
  Primary: OneOneBrandColor.Primary,
  PrimaryTint: OneOneBrandColor.PrimaryDarker,
  PrimaryContrast: OneOneBrandColor.White,

  Secondary: OneOneBrandColor.White,
  SecondaryTint: OneOneBrandColor.Lightest,
  SecondaryContrast: OneOneBrandColor.Darker,

  Accent: OneOneBrandColor.Primary,
  AccentTint: OneOneBrandColor.PrimaryDarker,
  AccentContrast: OneOneBrandColor.White,

  Gradient: `linear-gradient(86deg, ${OneOneBrandColor.GradientStart}, ${OneOneBrandColor.GradientEnd}) border-box`,
  GradientTint: `linear-gradient(86deg, ${OneOneBrandColor.PrimaryDarker}, ${OneOneBrandColor.GradientEnd}) border-box`,
  GradientContrast: OneOneBrandColor.White,
};

const TextColors: TextColorType = {
  HeadingText: OneOneBrandColor.White,
  BodyText: OneOneBrandColor.White,
  MutedText: OneOneBrandColor.Light,
  HighlightedText: OneOneBrandColor.White,
  LinkText: OneOneBrandColor.Yellow,
  ErrorText: OneOneBrandColor.Yellow,
  DisabledText: OneOneBrandColor.Neutral,
};

const Surface: SurfaceColorType = {
  Base: {
    Background: OneOneBrandColor.Black,
    Foreground: OneOneBrandColor.White,
    Dimmed: createTransparentColor(OneOneBrandColor.White, 0.25),
  },
  Nested: {
    Background: OneOneBrandColor.Dark,
    Foreground: OneOneBrandColor.White,
    Dimmed: createTransparentColor(OneOneBrandColor.White, 0.15),
  },
  Disabled: {
    Background: OneOneBrandColor.Neutral,
    Foreground: OneOneBrandColor.Dark + '80',
    Dimmed: createTransparentColor(OneOneBrandColor.Darker, 0.15),
  },
  Floating: {
    Background: OneOneBrandColor.Darker,
    Foreground: OneOneBrandColor.White,
    Dimmed: createTransparentColor(OneOneBrandColor.White, 0.15),
  },
};

const Signal: SignalColorType = {
  Success: OneOneBrandColor.Green,
  SuccessContrast: OneOneBrandColor.White,

  Info: OneOneBrandColor.Lightest,
  InfoContrast: OneOneBrandColor.Darker,

  Attention: OneOneBrandColor.Yellow,
  AttentionContrast: OneOneBrandColor.Darker,

  Danger: OneOneBrandColor.Error,
  DangerContrast: OneOneBrandColor.White,
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '12px',
  Large: '12px',
};

const Elevation: ElevationType = {
  Level1: `0 3px 12px 0 var(--shadow-color, ${createTransparentColor(OneOneBrandColor.Black, 0.15)})`,
  Level2: '0 4px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 12px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: GeneralType = {
  Bonus: OneOneBrandColor.Primary,
  BonusContrast: OneOneBrandColor.White,
};

const Layout: LayoutColors = {
  Background: OneOneBrandColor.Carbon,
  BrandBorder: Primitive.Primary,
  BrowserTheme: Primitive.Primary,
  LoadingColor: Primitive.Primary,
  Overlay: OneOneBrandColor.Black + 'CC',
  Sidebar: {
    Background: `linear-gradient(0deg, ${OneOneBrandColor.Black}, ${OneOneBrandColor.Darker})`,
    Foreground: OneOneBrandColor.Lightest,
  },
};

const Hero: HeroColorType = {
  Heading: OneOneBrandColor.White,
  Text: OneOneBrandColor.White,
  TextHighlight: OneOneBrandColor.White,

  Button: {
    Solid: OneOneBrandColor.White,
    Rest: OneOneBrandColor.White,
    Hover: OneOneBrandColor.Lightest,
    Active: OneOneBrandColor.Lightest,
    Text: OneOneBrandColor.Black,
  },
};
export const Color = {
  ...OneOneBrandColor,
  ...TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,

  Background: OneOneBrandColor.Carbon,
  CardBackground: OneOneBrandColor.Dark,

  Aux: OneOneBrandColor.Primary,

  // Dummy color to make ignore type errors.
  FocusHighlight: OneOneBrandColor.Primary,

  // Shadows, highlight and transparency
  Shadow: createTransparentColor(OneOneBrandColor.Black, 0.15),
  ShadowInset: createTransparentColor(OneOneBrandColor.Light, 0.2),

  DividerLight: createTransparentColor(OneOneBrandColor.White, 0.15),
  DividerDark: createTransparentColor(OneOneBrandColor.Black, 0.15),

  // Transparent colors
  BlackTransparent50: createTransparentColor(OneOneBrandColor.Black, 0.5),
  DarkerTransparent69: createTransparentColor(OneOneBrandColor.Darker, 0.69),

  LightTransparent90: createTransparentColor(OneOneBrandColor.Light, 0.9),
  LightTransparent20: createTransparentColor(OneOneBrandColor.Light, 0.2),
  PrimaryTransparent40: createTransparentColor(OneOneBrandColor.Primary, 0.4),

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart}, ${ColorEnd})`,
  AdaptiveBackground: `linear-gradient(86deg, ${OneOneBrandColor.GradientStart}, ${OneOneBrandColor.GradientEnd}) border-box`,

  // Theme color for mobile browsers
  BrowserTheme: OneOneBrandColor.Primary,

  // Validation and notification colors
  Success: OneOneBrandColor.Primary,
  Warning: OneOneBrandColor.Yellow,
  Error: OneOneBrandColor.Yellow,
};
