import { useEffect, useState } from 'react';
import { useDebounce } from '@pafcloud/react-hook-utils';
import { useSearchState } from './useSearchState';

export const useSearch = (gameListId: string) => {
  const { search, isSearching, results, total } = useSearchState(gameListId);
  const [searchValue, setSearchValue] = useState('');
  const { isDebouncing, debouncedFn, cancel } = useDebounce(search, {
    wait: 500,
  });

  const isLoading = isDebouncing || isSearching;

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debouncedFn(value);
  };

  const resetSearch = () => {
    handleSearch('');
  };

  useEffect(() => cancel, [cancel]);

  return { reset: resetSearch, search: handleSearch, isLoading, results, value: searchValue, total };
};
