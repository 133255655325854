import { pxToRem } from '../pxToRem';
import type * as Fonts from './font-types';

export const BaseFont = {
  DefaultFont: `'Open Sans', sans-serif`,
  BrandFont: `'Gilroy', sans-serif`,
  AlternateFont: `Open Sans, sans-serif`,
};

export const Font: Fonts.FontType = {
  Body: BaseFont.DefaultFont,
  Heading: BaseFont.BrandFont,
  HeadingAlt: BaseFont.BrandFont,
  Button: BaseFont.BrandFont,
  Tiny: BaseFont.DefaultFont,
};

export const FontHeadingSize: Fonts.FontHeadingSizeType = {
  Huge: pxToRem(48),
  Big: pxToRem(40),
  Normal: pxToRem(32),
  Small: pxToRem(28),
  Tiny: pxToRem(26),
};

export const FontTextSize: Fonts.FontTextSizeType = {
  Huge: pxToRem(22),
  Big: pxToRem(18),
  Normal: pxToRem(16),
  Small: pxToRem(14),
  Tiny: pxToRem(12),
};
