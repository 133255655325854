import styled from '@emotion/styled';
import { FormInputProperties } from './form-input-style/properties';
import { FormInputStyle } from './form-input-style';
import { Label, Text, floatingLabelStyle, hiddenLabelStyle } from './FormInputLabel';
import { FormInputColors } from './form-input-colors';
import { FormInputZIndex } from './FormInputZIndex';

type InputProps = {
  error?: boolean;
  dirty?: boolean;
  touched?: boolean;
  floatingLabel?: boolean;
};

export const Input = styled.input<InputProps>(({ error, dirty, touched, floatingLabel }) => ({
  wordBreak: 'normal',
  width: '100%',
  height: '100%',
  padding: '0 8px 0 0',
  border: 'none',
  borderRadius: FormInputStyle.BorderRadius,
  outline: 'none',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  fontWeight: 'inherit',
  color: `var(${FormInputProperties.TextColor})`,
  WebkitTextFillColor: `var(${FormInputProperties.TextColor})`,
  opacity: 1,
  textIndent: `var(${FormInputProperties.TextIndent})`,
  textTransform: 'inherit',
  backgroundColor: 'transparent',
  caretColor: FormInputColors.Caret,
  zIndex: FormInputZIndex.Field,
  WebkitTapHighlightColor: 'transparent',
  WebkitAppearance: 'none',

  '&[readonly]': {
    [FormInputProperties.TextColor]: FormInputColors.TextReadOnly,
    [`& ~ ${Label}`]: {
      [FormInputProperties.BorderWidth]: FormInputStyle.BorderWidth,
      [FormInputProperties.BorderColor]: FormInputColors.BorderReadOnly,
      [FormInputProperties.LabelColor]: FormInputColors.LabelReadOnly,
      [FormInputProperties.LabelWeight]: FormInputStyle.LabelWeightHeavy,
    },
  },
  '&[disabled]': {
    cursor: 'not-allowed',
    [FormInputProperties.TextColor]: FormInputColors.TextDisabled,
    [`& ~ ${Label}`]: {
      [FormInputProperties.LabelColor]: FormInputColors.LabelDisabled,
      [FormInputProperties.BorderWidth]: FormInputStyle.BorderWidth,
      [FormInputProperties.BorderColor]: FormInputColors.BorderDisabled,
      [FormInputProperties.BackgroundColor]: FormInputColors.InputBackgroundDisabled,
    },
  },
  '&:focus': {
    [FormInputProperties.TextColor]: FormInputColors.TextFocused,
  },
  '&:focus, &:active, &[data-active="true"]': {
    [`&:not([disabled]) ~ ${Label}`]: {
      [FormInputProperties.BorderColor]: FormInputColors.BorderFocused,
      [FormInputProperties.BorderWidth]: FormInputStyle.BorderWidth,
      [FormInputProperties.LabelColor]: FormInputColors.LabelFocused,
      [FormInputProperties.LabelWeight]: FormInputStyle.LabelWeightHeavy,
    },
  },

  '&': (() => {
    if (error) {
      return {
        caretColor: FormInputColors.TextFocused,
        [FormInputProperties.TextColor]: FormInputColors.TextInvalid,

        [`& ~ ${Label}`]: {
          [FormInputProperties.BorderColor]: FormInputColors.BorderInvalid,
          [FormInputProperties.BorderWidth]: FormInputStyle.BorderWidth,
          [FormInputProperties.LabelColor]: FormInputColors.LabelInvalid,
          [FormInputProperties.LabelWeight]: FormInputStyle.LabelWeightHeavy,
        },
      };
    }

    if (dirty && touched) {
      return {
        [FormInputProperties.TextColor]: FormInputColors.TextValid,

        [`& ~ ${Label}`]: {
          [FormInputProperties.BorderColor]: FormInputColors.BorderValid,
          [FormInputProperties.LabelColor]: FormInputColors.LabelValid,
          [FormInputProperties.BorderWidth]: FormInputStyle.BorderWidth,
          [FormInputProperties.LabelWeight]: FormInputStyle.LabelWeightHeavy,
        },

        [`&:focus ~ ${Label}`]: {
          [FormInputProperties.BorderColor]: FormInputColors.BorderFocused,
        },
      };
    }

    if (dirty) {
      return {
        [FormInputProperties.TextColor]: FormInputColors.TextValid,

        [`& ~ ${Label}`]: {
          [FormInputProperties.LabelColor]: FormInputColors.LabelFocused,
          [FormInputProperties.LabelWeight]: FormInputStyle.LabelWeightHeavy,
        },
      };
    }

    return {};
  })(),

  [`& ~ ${Label}`]: floatingLabel && dirty ? floatingLabelStyle : {},

  '&:-webkit-autofill, &:not(:placeholder-shown), &:focus': {
    // This allows us to manually prevent hiding/floating the label.
    '&:not([data-empty="true"])': {
      [`& ~ ${Label}`]: floatingLabel ? floatingLabelStyle : hiddenLabelStyle,
    },
  },

  '&::placeholder': {
    color: FormInputColors.Placeholder,
    opacity: 1,
  },
  // Hide placeholder text unless the field has focus
  '&:not(:focus)::placeholder': {
    color: 'transparent',
  },

  [`&:required ~ ${Label} ${Text},
    &[aria-required=true] ~ ${Label} ${Text}`]: {
    '::after': {
      content: '"*"',
      marginLeft: 2,
    },
  },

  // Hide number input arrows
  '&[type=number]': {
    [`&::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button`]: {
      WebkitAppearance: 'none',
      margin: 0,
    },
    MozAppearance: 'textfield',
  },
  '&[type=search]': {
    [`&::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration`]: {
      WebkitAppearance: 'none',
    },
  },
  '&[type=date]': {
    textIndent: 'unset',
    marginLeft: `var(${FormInputProperties.TextIndent})`,
    fontFamily: 'inherit',
  },

  // autofill reset
  '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
    fontFamily: 'inherit',
    WebkitTextFillColor: `var(${FormInputProperties.TextColor})`,
    WebkitBoxShadow: '0 0 0 0 transparent inset',
    transition: 'background-color 90000s ease-in-out 0s',
    filter: 'grayscale(100%);', // firefox
  },
  // autofill reset
  '&:-webkit-autofill::first-line': {
    fontSize: FormInputStyle.FontSize,
  },
}));
