import { createTransparentColor } from '../create-transparent-color';
import type { GeneralType, LayoutColors, PrimitiveColorType, SignalColorType } from './color-types';
import { Color as SpeedyAppColor } from './color.clean.shared';

const Primitive: PrimitiveColorType = {
  Primary: SpeedyAppColor.Orange,
  PrimaryTint: SpeedyAppColor.OrangeLight,
  PrimaryContrast: SpeedyAppColor.White,

  Secondary: SpeedyAppColor.DarkBlue,
  SecondaryTint: SpeedyAppColor.DarkerBlue,
  SecondaryContrast: SpeedyAppColor.White,

  Accent: SpeedyAppColor.Green,
  AccentTint: SpeedyAppColor.GreenLight,
  AccentContrast: SpeedyAppColor.White,

  Gradient: SpeedyAppColor.Orange,
  GradientTint: SpeedyAppColor.OrangeLight,
  GradientContrast: SpeedyAppColor.White,
};

const Signal: SignalColorType = {
  Success: SpeedyAppColor.Green,
  SuccessContrast: SpeedyAppColor.White,

  Info: SpeedyAppColor.Green,
  InfoContrast: SpeedyAppColor.White,

  Attention: SpeedyAppColor.Orange,
  AttentionContrast: SpeedyAppColor.White,

  Danger: SpeedyAppColor.Red,
  DangerContrast: SpeedyAppColor.White,
};

const General: GeneralType = {
  Bonus: SpeedyAppColor.Orange,
  BonusContrast: SpeedyAppColor.White,
};

const Layout: LayoutColors = {
  Background: SpeedyAppColor.Ice,
  BrandBorder: Primitive.Primary,
  BrowserTheme: SpeedyAppColor.Ice,
  LoadingColor: Primitive.Primary,
  Overlay: SpeedyAppColor.NeutralLighter + 'CC',
  Sidebar: {
    Background: Primitive.Secondary,
    Foreground: Primitive.SecondaryContrast,
  },
};

export const Color = {
  ...SpeedyAppColor,
  Primitive,
  Signal,
  General,
  Layout,

  Primary: SpeedyAppColor.Orange,
  AdaptiveBackground: SpeedyAppColor.Orange,
  LinkText: SpeedyAppColor.Orange,
  PrimaryTransparent80: createTransparentColor(SpeedyAppColor.Orange, 0.8),
  PrimaryTransparent60: createTransparentColor(SpeedyAppColor.Orange, 0.6),
};
