import styled from '@emotion/styled';
import { Color, FontTextSize } from '@pafcloud/style';
import { Icon } from '@pafcloud/base-components';

type BonusbarContainerProps = {
  disabledInOfferFlow?: boolean;
};

export const BonusbarContainer = styled.div<BonusbarContainerProps>(({ disabledInOfferFlow }) => {
  return {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    maxWidth: 'calc(100vw - (var(--side-column) * 2))',
    color: Color.BodyText,
    cursor: 'pointer',
    pointerEvents: disabledInOfferFlow ? 'none' : 'auto',
    position: 'relative',
    isolation: 'isolate',
  };
});

export const ProgressIcon = styled(Icon)({
  marginLeft: 8,
  color: 'currentColor',
});

export const BonusInformationContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const BonusDates = styled.span({
  fontSize: FontTextSize.Tiny,
});
