import styled from '@emotion/styled';
import { Button, CardBody, InfoSausage } from '@pafcloud/base-components';
import { Breakpoint, FontTextSize } from '@pafcloud/style';
import { BonusColors } from '../bonus-colors';

export const ProgressInformation = styled.div({
  display: 'flex',
  gap: 6,
  color: BonusColors.Text,
  fontSize: '1.2rem',
});

export const BonusesSection = styled.ul({
  '--grid-gap': '24px',
  display: 'grid',
  gap: 'var(--grid-gap)',
  width: '100%',
  margin: 0,
  marginBottom: 48,
  padding: 0,
  gridTemplateColumns: 'repeat(auto-fill, minmax(208px, 1fr))',
  [Breakpoint.LaptopOrLarger]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(256px, 1fr))',
  },
});
export const EmptyListMessage = styled.p({
  margin: '0 0 16px 0',
  color: BonusColors.TextMuted,
  textAlign: 'center',
});

export const RemainingUnitsText = styled.p({
  margin: '0 auto 16px',
});

export const BonusText = styled.p({
  flexGrow: 1,
  margin: 0,
  fontSize: FontTextSize.Tiny,
  color: BonusColors.TextMuted,
});
export const BonusCardWrapper = styled.li<{ disabled?: boolean }>(({ disabled }) => ({
  width: '100%',
  minWidth: 0,
  listStyle: 'none',
  scrollSnapAlign: 'center',
  borderRadius: 8,
  boxShadow: BonusColors.CardShadow,
  filter: disabled ? 'grayscale(100%)' : undefined,
  cursor: disabled ? 'not-allowed' : undefined,
}));
export const BonusTypeSausage = styled(InfoSausage)({
  position: 'absolute',
  top: 0,
  transform: 'translateY(-50%)',
});
export const BonusCardBody = styled(CardBody)({
  position: 'relative',
  justifyContent: 'unset',
  padding: '24px 16px 16px',
});
export const BonusCtaButton = styled(Button)({
  marginTop: 24,
});
