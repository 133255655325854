import dynamic from 'next/dynamic';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useGetQueryParam } from '@pafcloud/react-hook-utils';
import { FLOW_QUERY_PARAM } from '@pafcloud/flow-router';
import { useConfig } from '@pafcloud/contexts';

const P3DepositFlow = dynamic(() => import('../transaction/paf-payment-platform/DepositFlow'));
const P3WithdrawalFlow = dynamic(() => import('../transaction/paf-payment-platform/WithdrawalFlow'));
const ReceiptFlow = dynamic(() => import('../transaction/paf-payment-platform/ReceiptFlow'));
const DepositFlow = dynamic(() => import('../transaction/deposit/DepositFlow'));
const WithdrawalFlow = dynamic(() => import('../transaction/withdrawal/WithdrawalFlow'));
const GamingOverviewFlow = dynamic(() => import('../gaming-overview/GamingOverviewFlow'));
const LoginFlow = dynamic(() => import('../login-flow/LoginFlow'));
const ResetPasswordFlow = dynamic(() => import('../reset-password/ResetPasswordFlow'));
const AboutLimits = dynamic(() => import('../limits-form/AboutLimitsFlow'));
const TransactionResultFlow = dynamic(() => import('../transaction/TransactionResultFlow'));
const BonusDepositFlow = dynamic(() => import('../transaction/BonusDepositFlow'));
const PromotionRouterFlow = dynamic(() => import('../promotion/PromotionRouterFlow'));
const BonusRouterFlow = dynamic(() => import('../bonus/BonusRouterFlow'));
const BonusTermsRouterFlow = dynamic(() => import('../bonus/BonusTermsRouterFlow'));
const MessageRouterFlow = dynamic(() => import('../inbox/message/MessageRouterFlow'));
const MessageHelperRouterFlow = dynamic(() => import('../inbox/message/bonus-notifications/MessageHelperRouterFlow'));
const DocumentUploadFlow = dynamic(() => import('../document-upload/DocumentUploadFlow'));
const SourceOfFundsInfoFlow = dynamic(() => import('../document-upload/source-of-funds/SourceOfFundsInfoFlow'));
const AboutYearlyLimitsFlow = dynamic(() => import('../profile/profile-limits-tab/AboutYearlyLimitsFlow'));
const CookieConsentSettingsFlow = dynamic(() => import('../cookie-consent/CookieConsentSettingsFlow'));
const ManualRegistrationFlow = dynamic(() => import('../create-account/ManualRegistrationFlow'));
const RegisterAndVerifyPhoneFlow = dynamic(() => import('../register-phone/RegisterAndVerifyPhoneFlow'));
const VerifyIdentityFlow = dynamic(() => import('../document-upload/VerifyIdentityFlow'));
const VerifyEmailFlow = dynamic(() => import('../profile/profile-dialog/VerifyEmailFlow'));
const VerifyIdentityVeridasFlow = dynamic(() => import('../document-upload/veridas/VerifyIdentityVeridasFlow'));
const LimitQuestionnaireFlow = dynamic(() => import('../profile/profile-limits-tab/LimitQuestionnaireFlow'));
const BeneficialOwnershipInfoFlow = dynamic(
  () => import('../document-upload/beneficial-ownership/BeneficialOwnershipInfoFlow'),
);
const ResponsibleGamingInfoFlow = dynamic(
  () => import('../document-upload/responsible-gaming/ResponsibleGamingInfoFlow'),
);
const CalendarPromotionRouterFlow = dynamic(() => import('../calendar-promotion/CalendarPromotionRouterFlow'));
const KreditzIframeFlow = dynamic(() => import('../document-upload/kreditz/KreditzIframeFlow'));

export const FlowRouter = () => {
  const config = useConfig();
  const flowType = useGetQueryParam(FLOW_QUERY_PARAM);
  const veridasFlow = $buildEnv.market === 'spain' || $buildEnv.site === 'pafbet.lv';

  if (flowType == null) {
    return null;
  }

  switch (flowType) {
    case 'deposit':
      if (config.p3PaymentEnabled) {
        return <P3DepositFlow />;
      } else {
        return <DepositFlow />;
      }
    case 'withdrawal':
      if (config.p3PaymentEnabled) {
        return <P3WithdrawalFlow />;
      } else {
        return <WithdrawalFlow />;
      }
    case 'transaction-result':
      return <TransactionResultFlow />;
    case 'receipt':
      return <ReceiptFlow />;
    case 'gamingOverview':
      return <GamingOverviewFlow />;
    case 'login':
      return <LoginFlow />;
    case 'resetPassword':
      return <ResetPasswordFlow />;
    case 'aboutSessionLimits':
      return <AboutLimits type="SESSION_LIMITS" />;
    case 'aboutDepositLimits':
      return <AboutLimits type="DEPOSIT_LIMITS" />;
    case 'aboutLossLimits':
      return <AboutLimits type="LOSS_LIMITS" />;
    case 'aboutStakeLimits':
      return <AboutLimits type="STAKE_LIMITS" />;
    case 'aboutYearlyLimits':
      return <AboutYearlyLimitsFlow />;
    case 'promotion':
      return <PromotionRouterFlow />;
    case 'bonus':
      return <BonusRouterFlow />;
    case 'bonusTerms':
      return <BonusTermsRouterFlow />;
    case 'message':
      return <MessageRouterFlow />;
    case 'messageHelper':
      return <MessageHelperRouterFlow />;
    case 'documentUpload':
      return <DocumentUploadFlow />;
    case 'open-banking':
      return <KreditzIframeFlow />;
    case 'bonusDeposit':
      return <BonusDepositFlow />;
    case 'sourceOfFundsInfo':
      return <SourceOfFundsInfoFlow />;
    case 'cookieConsent':
      return <CookieConsentSettingsFlow />;
    case 'manualRegistration':
      return <ManualRegistrationFlow />;
    case 'changePhoneNumber':
      return <RegisterAndVerifyPhoneFlow />;
    case 'verifyIdentity':
      return <VerifyIdentityFlow />;
    case 'verifyIdentityVeridas':
      return veridasFlow ? <VerifyIdentityVeridasFlow /> : null;
    case 'verifyEmail':
      return <VerifyEmailFlow />;
    case 'limitQuestionnaire':
      return <LimitQuestionnaireFlow />;
    case 'beneficialOwnershipInfo':
      return <BeneficialOwnershipInfoFlow />;
    case 'responsibleGaming':
      return <ResponsibleGamingInfoFlow />;
    case 'calendarPromotion':
      return <CalendarPromotionRouterFlow />;
    default:
      return null;
  }
};
