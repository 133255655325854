import { useRef, useState } from 'react';
import { logger } from '@pafcloud/logging';
import { useHandler } from './useHandler';

/**
 * @example
 * const {startPolling, stopPolling} = usePoller(2000);
 * startPolling(async () => {
 *   // Polling continues until false is returned or stopPolling() is called.
 * });
 */
export const usePoller = (interval: number) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isPolling, setPolling] = useState(false);

  const stopPolling = useHandler(() => {
    setPolling(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  });

  const startPolling = useHandler((callback: () => Promise<boolean>) => {
    const poll = async () => {
      if (document.hidden) {
        timeoutRef.current = setTimeout(poll, interval);
        return;
      }

      try {
        const shouldContinuePolling = await callback();
        if (shouldContinuePolling && timeoutRef.current !== null) {
          timeoutRef.current = setTimeout(poll, interval);
        } else {
          setPolling(false);
        }
      } catch (error) {
        logger.error('Polling failed with error', { error });
        timeoutRef.current = setTimeout(poll, interval);
      }
    };

    stopPolling();
    setPolling(true);

    timeoutRef.current = setTimeout(poll, interval);
  });

  return {
    startPolling,
    stopPolling,
    isPolling,
  };
};
