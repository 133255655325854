import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import {
  BaseFont as BaseFont_classic,
  Font as Font_classic,
  FontHeadingSize as FontHeadingSize_classic,
  FontTextSize as FontTextSize_classic,
} from './font.classic';
import {
  BaseFont as BaseFont_classic_red,
  Font as Font_classic_red,
  FontHeadingSize as FontHeadingSize_classic_red,
  FontTextSize as FontTextSize_classic_red,
} from './font.classic-red';
import {
  BaseFont as BaseFont_clean,
  Font as Font_clean,
  FontHeadingSize as FontHeadingSize_clean,
  FontTextSize as FontTextSize_clean,
} from './font.clean';
import {
  BaseFont as BaseFont_golden,
  Font as Font_golden,
  FontHeadingSize as FontHeadingSize_golden,
  FontTextSize as FontTextSize_golden,
} from './font.golden';
import {
  BaseFont as BaseFont_quirky,
  Font as Font_quirky,
  FontHeadingSize as FontHeadingSize_quirky,
  FontTextSize as FontTextSize_quirky,
} from './font.quirky';
import {
  BaseFont as BaseFont_quirky_purple,
  Font as Font_quirky_purple,
  FontHeadingSize as FontHeadingSize_quirky_purple,
  FontTextSize as FontTextSize_quirky_purple,
} from './font.quirky-purple';
import {
  BaseFont as BaseFont_dreams,
  Font as Font_dreams,
  FontHeadingSize as FontHeadingSize_dreams,
  FontTextSize as FontTextSize_dreams,
} from './font.dreams';
import {
  BaseFont as BaseFont_momentum,
  Font as Font_momentum,
  FontHeadingSize as FontHeadingSize_momentum,
  FontTextSize as FontTextSize_momentum,
} from './font.momentum';

export const BaseFont = (() => {
  if ($buildEnv.theme === 'classic') {
    return BaseFont_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return BaseFont_classic_red;
  }
  if ($buildEnv.theme === 'clean') {
    return BaseFont_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return BaseFont_clean;
  }
  if ($buildEnv.theme === 'quirky') {
    return BaseFont_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return BaseFont_quirky_purple;
  }
  if ($buildEnv.theme === 'golden') {
    return BaseFont_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return BaseFont_golden;
  }
  if ($buildEnv.theme === 'dreams') {
    return BaseFont_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return BaseFont_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    // momentum neon is using the same brand font as SpeedyBet
    return BaseFont_clean;
  }

  console.warn('BaseFont not specified!');
  return BaseFont_classic;
})();

export const Font = (() => {
  if ($buildEnv.theme === 'classic') {
    return Font_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return Font_classic_red;
  }
  if ($buildEnv.theme === 'clean') {
    return Font_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Font_clean;
  }
  if ($buildEnv.theme === 'quirky') {
    return Font_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return Font_quirky_purple;
  }
  if ($buildEnv.theme === 'golden') {
    return Font_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Font_golden;
  }
  if ($buildEnv.theme === 'dreams') {
    return Font_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return Font_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    // momentum neon is using the same brand font as SpeedyBet
    return Font_clean;
  }

  console.warn('Font not specified!');
  return Font_classic;
})();

export const FontHeadingSize = (() => {
  if ($buildEnv.theme === 'classic') {
    return FontHeadingSize_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return FontHeadingSize_classic_red;
  }
  if ($buildEnv.theme === 'clean') {
    return FontHeadingSize_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return FontHeadingSize_clean;
  }
  if ($buildEnv.theme === 'quirky') {
    return FontHeadingSize_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return FontHeadingSize_quirky_purple;
  }
  if ($buildEnv.theme === 'golden') {
    return FontHeadingSize_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return FontHeadingSize_golden;
  }
  if ($buildEnv.theme === 'dreams') {
    return FontHeadingSize_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return FontHeadingSize_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    // momentum neon is using the same brand font as SpeedyBet
    return FontHeadingSize_clean;
  }

  console.warn('FontHeadingSize not specified!');
  return FontHeadingSize_classic;
})();

export const FontTextSize = (() => {
  if ($buildEnv.theme === 'classic') {
    return FontTextSize_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return FontTextSize_classic_red;
  }
  if ($buildEnv.theme === 'clean') {
    return FontTextSize_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return FontTextSize_clean;
  }
  if ($buildEnv.theme === 'quirky') {
    return FontTextSize_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return FontTextSize_quirky_purple;
  }
  if ($buildEnv.theme === 'golden') {
    return FontTextSize_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return FontTextSize_golden;
  }
  if ($buildEnv.theme === 'dreams') {
    return FontTextSize_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return FontTextSize_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    // momentum neon is using the same brand font as SpeedyBet
    return FontTextSize_clean;
  }

  console.warn('FontTextSize not specified!');
  return FontTextSize_classic;
})();
