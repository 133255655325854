import styled from '@emotion/styled';
import { useFragment, graphql } from 'react-relay/hooks';
import type { FC } from 'react';
import { useState } from 'react';
import { LayoutProps, getBrandOverlay } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import {
  MarkdownParser,
  ModalContent,
  ModalBackgroundOverlay,
  Button,
  Stack,
  renderIntoRootContainer,
} from '@pafcloud/base-components';
import { useFlowRouter } from '@pafcloud/flow-router';
import { RichText } from '@pafcloud/rich-text';
import type { SourceOfFundsReminder_data$key } from './__generated__/SourceOfFundsReminder_data.graphql';

const dataFragment = graphql`
  fragment SourceOfFundsReminder_data on SourceOfFunds {
    status
    reminderSentAt
  }
`;

const Overlay = styled(ModalBackgroundOverlay)({
  ...getBrandOverlay(),
});

const Content = styled(ModalContent)({
  margin: 16,
  marginTop: LayoutProps.HeaderHeight,
  padding: '32px 24px',
});

const ButtonStack = styled(Stack)({
  marginTop: 32,
});

const ActionButton = styled(Button)({
  width: '100%',
  align: 'center',
});

type Props = {
  data: SourceOfFundsReminder_data$key;
};

export const SourceOfFundsReminder: FC<Props> = (props) => {
  const data = useFragment(dataFragment, props.data);
  const { t } = useTranslation(['document-upload', 'flow']);
  const flowRouter = useFlowRouter();
  const [showReminder, setShowReminder] = useState(() => {
    return Boolean(data.status === 'REQUIRED' && data.reminderSentAt);
  });

  if (!showReminder) {
    return null;
  }

  const close = () => {
    setShowReminder(false);
  };

  const openSourceOfFundsInfo = async () => {
    await flowRouter.openFlow('sourceOfFundsInfo');
    close();
  };

  const openDocumentUpload = async () => {
    await flowRouter.openFlow('documentUpload', { type: 'sourceOfFunds' });
    close();
  };

  return renderIntoRootContainer(
    <Overlay>
      <Content>
        <RichText>
          <h4>{t('flow:title.your-finances')}</h4>
          <MarkdownParser>{t('source-of-funds.short-description')}</MarkdownParser>
        </RichText>
        <ButtonStack>
          <ActionButton size="default" type="button" ctaEffect onClick={openDocumentUpload}>
            {t('provide-documents-button')}
          </ActionButton>
          <ActionButton size="default" type="button" variant="secondary" onClick={openSourceOfFundsInfo}>
            {t('source-of-funds.read-more')}
          </ActionButton>
          <ActionButton size="default" type="button" variant="secondary" onClick={close}>
            {t('close-button')}
          </ActionButton>
        </ButtonStack>
      </Content>
    </Overlay>,
  );
};
