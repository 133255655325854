import type { FC } from 'react';
import styled from '@emotion/styled';
import { Icon, InternalLink } from '@pafcloud/base-components';
import { FontTextSize, Color } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { InboxColors } from '../../../inbox/inbox-colors';
import { TrayHeader } from '../../../tray';

const Header = styled(TrayHeader)({
  borderBottom: `1px solid ${InboxColors.BorderColor}`,
});

const InboxLink = styled(InternalLink)({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gridAutoFlow: 'column',
  gap: 8,
  fontSize: FontTextSize.Normal,
  lineHeight: 1,
  fontWeight: 'normal',

  '&:focus': {
    color: Color.LinkText,
    outline: `1px solid ${Color.LinkText}`,
    outlineOffset: '4px',
  },
});

type Props = {
  numberOfMessages: number;
  closeTray: () => void;
};

export const MessageMenuHeader: FC<Props> = ({ numberOfMessages, closeTray }) => {
  const { t } = useTranslation('inbox');
  return (
    <Header>
      {t('message-preview.header.title')} {numberOfMessages ? `(${numberOfMessages})` : null}
      <InboxLink
        href="/profile/inbox"
        aria-label={t('message-preview.header.open-inbox-action.aria-label')}
        onClick={closeTray}
      >
        {t('message-preview.header.open-inbox-action.text')}
        <Icon name="chevronRightFat" size="1em" />
      </InboxLink>
    </Header>
  );
};
