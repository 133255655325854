import Head from 'next/head';
import styled from '@emotion/styled';
import { Heading, MarkdownParser, PageContainer } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { Color, Font, FontHeadingSize, FontTextSize } from '@pafcloud/style';
import { LinkToCustomerService } from '@pafcloud/block-components/src/link-to-customer-service';
import { useIsSSR } from '@pafcloud/react-hook-utils';

const Header = styled(Heading)({
  fontSize: FontHeadingSize.Small,
  fontFamily: Font.Heading,
});

const Paragraph = styled(MarkdownParser)({
  fontSize: FontTextSize.Huge,
  color: Color.BodyText,
});

const Link = styled(LinkToCustomerService)({
  fontSize: FontTextSize.Huge,
  color: Color.LinkText,
});

export const ErrorPage = () => {
  const { t } = useTranslation('common');
  const isSSR = useIsSSR();

  if (isSSR) {
    return null;
  }

  return (
    <>
      <Head>
        <title>{t('error.page-title')}</title>
      </Head>
      <PageContainer>
        <Header>{t('error.header')}</Header>
        <Paragraph>{t('error.text')}</Paragraph>
        <Link />
      </PageContainer>
    </>
  );
};
