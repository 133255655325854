import { $buildEnv } from '@pafcloud/config/src/buildEnv';

import { Color as Color_classic } from './color.classic';
import { Color as Color_classic_red } from './color.classic-red';
import { Color as Color_default } from './color.default';

import { Color as Color_clean } from './color.clean';
import { Color as Color_clean_blue } from './color.clean-blue';

import { Color as Color_golden } from './color.golden';
import { Color as Color_goldenV2 } from './color.goldenV2';
import { Color as Color_quirky } from './color.quirky';
import { Color as Color_quirky_purple } from './color.quirky-purple';

import { Color as Color_dreams } from './color.dreams';
import { Color as Color_momentum } from './color.momentum';
import { Color as Color_momentum_neon } from './color.momentum-neon';

export { Color_classic as PAFColor };
export { Color_classic_red as MyCasinoColor };

export const SpeedyColor = (() => {
  if ($buildEnv.theme === 'clean') {
    return Color_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Color_clean_blue;
  }

  return Color_clean;
})();

export const PMLColor = (() => {
  if ($buildEnv.theme === 'golden') {
    return Color_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Color_goldenV2;
  }

  return Color_golden;
})();

export { Color_quirky as PrankColor };
export { Color_quirky_purple as PinataColor };

export { Color_dreams as X3000Color };

export const OneOneColor = (() => {
  if ($buildEnv.theme === 'momentum') {
    return Color_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return Color_momentum_neon;
  }

  return Color_momentum;
})();

export const Color = (() => {
  if ($buildEnv.theme === 'classic') {
    return Color_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return Color_classic_red;
  }
  if ($buildEnv.theme === 'clean') {
    return Color_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Color_clean_blue;
  }
  if ($buildEnv.theme === 'quirky') {
    return Color_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return Color_quirky_purple;
  }
  if ($buildEnv.theme === 'golden') {
    return Color_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Color_goldenV2;
  }
  if ($buildEnv.theme === 'dreams') {
    return Color_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return Color_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return Color_momentum_neon;
  }

  console.warn('Colors not specified for color!');
  return Color_default;
})();

export type ColorType = typeof Color;
