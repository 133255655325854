import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  Suffix: Color.BodyText,

  ErrorMessageBackground: Color.Surface.Base.Background,
  ErrorMessageTextColor: Color.Surface.Base.Foreground,

  InfoMessageBackground: Color.Surface.Base.Background,
  InfoMessageTextColor: Color.Surface.Base.Foreground,
};
