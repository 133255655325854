/**
 * @generated SignedSource<<c1c86950f50d85eedf9c727e9c9d5b06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type App_data$data = {
  readonly accessRequirements: {
    readonly browse: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"BrowseAppAccessGuard_requirements">;
    }>;
  };
  readonly player: {
    readonly sourceOfFunds: {
      readonly " $fragmentSpreads": FragmentRefs<"SourceOfFundsReminder_data">;
    };
  } | null;
  readonly site: {
    readonly hasCountryRedirect: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"Contexts_data" | "Footer_content" | "Header_content" | "SiteAlerts_data" | "SyncAppData_data" | "Tracking_data">;
  readonly " $fragmentType": "App_data";
};
export type App_data$key = {
  readonly " $data"?: App_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"App_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "App_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Contexts_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SyncAppData_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Tracking_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SiteAlerts_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Header_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Footer_content"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Player",
      "kind": "LinkedField",
      "name": "player",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "concreteType": "SourceOfFunds",
            "kind": "LinkedField",
            "name": "sourceOfFunds",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SourceOfFundsReminder_data"
              }
            ],
            "storageKey": null
          },
          "action": "NONE",
          "path": "player.sourceOfFunds"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessRequirements",
      "kind": "LinkedField",
      "name": "accessRequirements",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "browse",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BrowseAppAccessGuard_requirements"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasCountryRedirect",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2e46214e1be56545ead9afeaf3eeb622";

export default node;
