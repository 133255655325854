import type { ComponentProps, ReactNode } from 'react';
import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Font, FontTextSize, Color } from '@pafcloud/style';
import { Icon } from '@pafcloud/base-components';

const CheckboxBox = styled.span({
  position: 'relative',
  cursor: 'pointer',
  padding: 0,
  marginRight: 10,
  display: 'inline-block',
  verticalAlign: 'top',
  width: 24,
  height: 24,
  background: 'transparent',
  color: Color.BodyText,
  border: `2px solid ${Color.Surface.Base.Dimmed}`,
});

const Label = styled.span({
  fontFamily: Font.Body,
  fontSize: FontTextSize.Big,
});

const StyledCheckMark = styled(Icon)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const Input = styled.input<{ error?: boolean }>(({ error }) => ({
  opacity: 0,
  position: 'absolute',
  [`:not(:checked) + ${CheckboxBox} > ${StyledCheckMark}`]: {
    display: 'none',
  },
  [`& + ${CheckboxBox} + ${Label}`]: {
    color: Color.HighlightedText,
  },
  [`+ ${CheckboxBox}`]: {
    borderColor: Color.MutedText,
  },
  [`:checked + ${CheckboxBox}`]: {
    color: Color.Signal.Success,
    borderColor: Color.Signal.Success,
  },
  [`:hover + ${CheckboxBox}`]: {
    borderColor: Color.Signal.Success,
  },
  [`:focus + ${CheckboxBox}::after`]: {
    content: "''",
    width: 24,
    height: 24,
    outline: `2px solid ${Color.Signal.Success}`,
    position: 'absolute',
    top: -2,
    right: 0,
    left: -2,
    bottom: 0,
    opacity: 0.33,
  },
  [`:disabled + ${CheckboxBox},
    :disabled + ${CheckboxBox} + ${Label}`]: {
    color: Color.MutedText,
    borderColor: Color.Surface.Disabled.Background,
    cursor: 'auto',
  },
  ...(error
    ? {
        [`+ ${CheckboxBox},
      + ${CheckboxBox} + ${Label}`]: {
          color: Color.Signal.Danger,
          borderColor: Color.Signal.Danger,
        },
      }
    : {}),
}));

const Wrapper = styled.label({
  position: 'relative',
});

export type CheckboxInputProps = ComponentProps<typeof Input> & {
  children?: ReactNode;
  disabled?: boolean;
  label: ReactNode;
  name?: string;
  checked?: boolean;
  id?: string;
  error?: boolean;
  className?: string;
};

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ error, label, children, className, ...props }, ref) => {
    return (
      <Wrapper className={className}>
        <Input {...props} error={error} ref={ref} type="checkbox" />
        <CheckboxBox>
          <StyledCheckMark name="check" size="11" />
        </CheckboxBox>
        <Label>{label}</Label>
        {children}
      </Wrapper>
    );
  },
);

CheckboxInput.displayName = 'CheckboxInput';
