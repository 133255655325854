/**
 * @generated SignedSource<<f3d0afe7adb2dc43357ca8d6c38fca0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BonusOfferStepStatus = "ACTIVE" | "CANCELLED" | "COMPLETED" | "PENDING" | "REWARD_PENDING";
export type TurnoverGameCategory = "BETTING" | "BINGO" | "LIVECASINO" | "LOTTERIES" | "SLOTS" | "TABLEGAMES" | "VIDEOPOKER";
import { FragmentRefs } from "relay-runtime";
export type useBonusOfferSteps_offer$data = {
  readonly steps: ReadonlyArray<{
    readonly __typename: "ClaimOfferStep";
    readonly reward: {
      readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_reward">;
    } | null;
    readonly status: BonusOfferStepStatus;
    readonly stepId: number | null;
  } | {
    readonly __typename: "DepositOfferStep";
    readonly minDepositAmount: number;
    readonly reward: {
      readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_reward">;
    } | null;
    readonly status: BonusOfferStepStatus;
    readonly stepId: number | null;
  } | {
    readonly __typename: "ImmediateOfferStep";
    readonly reward: {
      readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_reward">;
    } | null;
    readonly status: BonusOfferStepStatus;
    readonly stepId: number | null;
  } | {
    readonly __typename: "PlayRealMoneyOfferStep";
    readonly game: {
      readonly familyName: string;
      readonly name: string;
    } | null;
    readonly minBetOdds: number | null;
    readonly playAmount: number;
    readonly remainingAmount: number;
    readonly reward: {
      readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_reward">;
    } | null;
    readonly status: BonusOfferStepStatus;
    readonly stepId: number | null;
  } | {
    readonly __typename: "PlayRealRoundsOfferStep";
    readonly game: {
      readonly familyName: string;
      readonly name: string;
    } | null;
    readonly minBetOdds: number | null;
    readonly playRounds: number;
    readonly remainingRounds: number;
    readonly reward: {
      readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_reward">;
    } | null;
    readonly status: BonusOfferStepStatus;
    readonly stepId: number | null;
  } | {
    readonly __typename: "RecentDepositOfferStep";
    readonly mustDeposit: boolean | null;
    readonly reward: {
      readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_reward">;
    } | null;
    readonly status: BonusOfferStepStatus;
    readonly stepId: number | null;
  } | {
    readonly __typename: "SafeBetOfferStep";
    readonly game: {
      readonly name: string;
    } | null;
    readonly playAmount: number;
    readonly reward: {
      readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_reward">;
    } | null;
    readonly status: BonusOfferStepStatus;
    readonly stepId: number | null;
  } | {
    readonly __typename: "TurnoverOfferStep";
    readonly reward: {
      readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_reward">;
    } | null;
    readonly status: BonusOfferStepStatus;
    readonly stepId: number | null;
    readonly turnoverAmount: number | null;
    readonly turnoverCategories: ReadonlyArray<{
      readonly category: TurnoverGameCategory;
      readonly contributionPercentage: number;
      readonly minBetOdds: number | null;
    }> | null;
    readonly turnoverTimes: number | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
  readonly " $fragmentType": "useBonusOfferSteps_offer";
};
export type useBonusOfferSteps_offer$key = {
  readonly " $data"?: useBonusOfferSteps_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_offer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useBonusOfferSteps_offer"
};

(node as any).hash = "18b7320467e864776d369df0c9d50b38";

export default node;
