import { createTransparentColor } from '../create-transparent-color';
import type { CornerRadiusType, ElevationType, HeroColorType, SurfaceColorType, TextColorType } from './color-types';

const SpeedyBrandColors = {
  Primary: '#fab',
  Ice: '#EBF1F2',

  // Alternate Colors
  Alternate: '#7D838B',

  Blue: '#58B1CB', // SB brand primary
  DarkBlue: '#213E51',
  DarkerBlue: '#112D40',
  NeutralDarkest: '#434343',
  NeutralDarker: '#707070',
  Neutral: '#b3babb',
  NeutralLighter: '#DCE5EB',

  Green: '#18CF65',
  GreenLight: '#30EA76',
  GreenHighlight: '#40F27E',
  Orange: '#FF8956', // SC brand primary
  OrangeLight: '#F69166',
  Red: '#E6213F',
  White: '#ffffff',
  AlmostWhite: '#f5fafc',
};

const TextColors: TextColorType = {
  HeadingText: SpeedyBrandColors.DarkerBlue,
  BodyText: SpeedyBrandColors.DarkerBlue,
  MutedText: '#738794',
  HighlightedText: SpeedyBrandColors.DarkerBlue,
  LinkText: SpeedyBrandColors.Primary,
  ErrorText: SpeedyBrandColors.Red,
  DisabledText: SpeedyBrandColors.Neutral,
};

const Surface: SurfaceColorType = {
  Base: {
    Background: SpeedyBrandColors.White,
    Foreground: SpeedyBrandColors.DarkerBlue,
    Dimmed: createTransparentColor(SpeedyBrandColors.DarkBlue, 0.15),
  },
  Nested: {
    Background: SpeedyBrandColors.AlmostWhite,
    Foreground: SpeedyBrandColors.DarkerBlue,
    Dimmed: createTransparentColor(SpeedyBrandColors.DarkBlue, 0.15),
  },
  Disabled: {
    Background: SpeedyBrandColors.Alternate,
    Foreground: SpeedyBrandColors.Neutral,
    Dimmed: createTransparentColor(SpeedyBrandColors.NeutralDarker, 0.15),
  },
  Floating: {
    Background: SpeedyBrandColors.Ice,
    Foreground: SpeedyBrandColors.DarkerBlue,
    Dimmed: createTransparentColor(SpeedyBrandColors.DarkBlue, 0.15),
  },
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '8px',
  Large: '24px',
};

const Elevation: ElevationType = {
  Level1: `0 1px 2px -1px var(--shadow-color, ${createTransparentColor(SpeedyBrandColors.DarkerBlue, 0.1)})`,
  Level2: `0 3px 6px -2px var(--shadow-color, ${createTransparentColor(SpeedyBrandColors.DarkerBlue, 0.1)})`,
  Level3: `0 8px 12px var(--shadow-color, ${createTransparentColor(SpeedyBrandColors.DarkerBlue, 0.1)}), 0 3px 6px -1px var(--shadow-color, ${createTransparentColor(SpeedyBrandColors.DarkerBlue, 0.1)})`,
};

const Hero: HeroColorType = {
  Heading: SpeedyBrandColors.DarkerBlue,
  Text: SpeedyBrandColors.DarkBlue,
  TextHighlight: SpeedyBrandColors.Green,

  Button: {
    Solid: SpeedyBrandColors.Green,
    Rest: SpeedyBrandColors.Green,
    Hover: SpeedyBrandColors.GreenLight,
    Active: SpeedyBrandColors.GreenHighlight,
    Text: SpeedyBrandColors.White,
  },
};

export const Color = {
  ...SpeedyBrandColors,
  ...TextColors,
  Surface,
  Elevation,
  CornerRadius,
  Hero,

  Background: SpeedyBrandColors.Ice,

  // Shadows, highlight and transparency
  FocusHighlight: createTransparentColor(SpeedyBrandColors.Green, 0.3),

  Shadow: createTransparentColor(SpeedyBrandColors.DarkerBlue, 0.2),

  LightTransparent50: createTransparentColor(SpeedyBrandColors.NeutralLighter, 0.5),

  DarkTransparent10: createTransparentColor('#000000', 0.1),
  DarkTransparent25: createTransparentColor('#000000', 0.25),
  DarkTransparent75: createTransparentColor('#000000', 0.75),

  PrimaryTransparent80: '#fab',
  PrimaryTransparent60: '#fab',

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart} ${ColorEnd})`,
  AdaptiveBackground: SpeedyBrandColors.Alternate,

  // Theme color for mobile browsers
  BrowserTheme: SpeedyBrandColors.Ice,
};
