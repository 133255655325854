import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_classic } from './colors.classic';
import { Colors as Colors_clean } from './colors.clean';
import { Colors as Colors_golden } from './colors.golden';
import { Colors as Colors_quirky } from './colors.quirky';
import { Colors as Colors_quirky_purple } from './colors.quirky-purple';
import { Colors as Colors_dreams } from './colors.dreams';
import { Colors as Colors_momentum_neon } from './colors.momentum-neon';
import { Colors as Colors_momentum } from './colors.momentum';

export const Colors = (() => {
  if ($buildEnv.theme === 'classic') {
    return Colors_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return Colors_classic;
  }
  if ($buildEnv.theme === 'clean') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'quirky') {
    return Colors_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return Colors_quirky_purple;
  }
  if ($buildEnv.theme === 'golden') {
    return Colors_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Colors_golden;
  }
  if ($buildEnv.theme === 'dreams') {
    return Colors_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return Colors_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return Colors_momentum_neon;
  }

  console.warn('Colors not specified for sidebar-colors!');
  return Colors_classic;
})();
