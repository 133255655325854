import type { FC } from 'react';
import { LoadingLine } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import type { SearchResult as SearchResultType, SearchResultClickedInfo } from './Search';
import { searchResultStyles } from './search-result-styles/searchResultStyles';
import { SearchResultChildren } from './SearchResultChildren';

const { ResultList, ResultListItem, ResultWrapper, ResultHeader, NoResultMessage } = searchResultStyles;

type SearchResultProps = {
  gameListId: string;
  isLoading: boolean;
  searchResults: SearchResultType[];
  total?: number;
  onClickResult?: (result: SearchResultClickedInfo) => void;
};

export const SearchResult: FC<SearchResultProps> = ({ isLoading, searchResults, total, gameListId, onClickResult }) => {
  const { t } = useTranslation('search');

  const hasMoreResults = total !== undefined && total > searchResults.length;
  const numberOfResults = `${searchResults.length}${hasMoreResults ? '+' : ''}`;

  return (
    <ResultWrapper>
      {isLoading && <LoadingLine />}
      <ResultHeader>{t('search-result.results', { numberOfResults })}</ResultHeader>
      {searchResults.length === 0 ? (
        <NoResultMessage>{t('search-result.no-results')}</NoResultMessage>
      ) : (
        <ResultList tabIndex={-1}>
          {searchResults.map((searchResult, index) => (
            <ResultListItem key={`${searchResult.id}-${searchResult.supplier}`}>
              <SearchResultChildren
                searchResult={searchResult}
                gameListId={gameListId}
                onClickResult={() => {
                  onClickResult?.({
                    name: searchResult.rawTitle ?? searchResult.title,
                    launchUrl: searchResult.launchUrl,
                    index,
                    totalResults: searchResults.length,
                  });
                }}
              />
            </ResultListItem>
          ))}
        </ResultList>
      )}
    </ResultWrapper>
  );
};
