import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { PrankColor } from '@pafcloud/style';
import { Icon } from '@pafcloud/base-components';
import { FormInputStyle } from './form-input-style';
import { FormInputProperties } from './form-input-style/properties';
import { FormInputZIndex } from './FormInputZIndex';

export const Text = styled.span({
  position: 'absolute',
  display: 'flex',
  fontWeight: `var(${FormInputProperties.LabelWeight})` as 'bold', // workaround for enabling custom properties for fontWeight.
  alignItems: 'center',
  marginLeft: FormInputStyle.TextIndent,
  willChange: 'transform',
  transition: 'scale 0.1s ease-in, transform 0.1s ease-in',
  transformOrigin: '0 0',
  zIndex: FormInputZIndex.Label,
});

export const floatingLabelStyle: CSSObject = {
  '&::before': {
    clipPath: `polygon(
                0 0,
                calc(var(${FormInputProperties.TextIndent}) - ${FormInputStyle.LabelGap}) 0,
                calc(var(${FormInputProperties.TextIndent}) - ${FormInputStyle.LabelGap}) calc(var(${FormInputProperties.BorderWidth}) + 1px),
                calc(var(${FormInputProperties.TextIndent}) + ${FormInputStyle.LabelGap} + var(${FormInputProperties.LabelWidth}) * ${FormInputStyle.LabelFloatScale}) calc(var(${FormInputProperties.BorderWidth}) + 1px),
                calc(var(${FormInputProperties.TextIndent}) + ${FormInputStyle.LabelGap} + var(${FormInputProperties.LabelWidth}) * ${FormInputStyle.LabelFloatScale}) 0,
                100% 0,
                100% 100%,
                0 100%
              )`,
  },
  [`${Text}`]: {
    transform: `translate(0, calc((((${FormInputStyle.Height} + var(${FormInputProperties.BorderWidth})) / 2) * -1))) scale(${FormInputStyle.LabelFloatScale})`,
  },
};

export const hiddenLabelStyle: CSSObject = {
  [`${Text}`]: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: 1,
  },
};

export const Label = styled.label({
  width: 'inherit',
  height: 'inherit',
  display: 'grid',
  position: 'absolute',
  alignItems: 'center',
  pointerEvents: 'none',
  color: `var(${FormInputProperties.LabelColor})`,
  boxShadow: $buildEnv.theme === 'quirky' ? `0 4px 8px 0 ${PrankColor.DarkerTransparent10}` : undefined,

  '&::before': {
    boxShadow: $buildEnv.theme === 'quirky' ? `0 4px 8px 0 ${PrankColor.DarkerTransparent10}` : undefined,
    width: 'inherit',
    height: 'inherit',
    backgroundColor: `var(${FormInputProperties.BackgroundColor})`,
    borderColor: `var(${FormInputProperties.BorderColor})`,
    borderWidth: `var(${FormInputProperties.BorderWidth})`,
    borderRadius: FormInputStyle.BorderRadius,
    borderStyle: 'solid',
    content: '""',
    position: 'absolute',
  },
});

type LabelIconProps = {
  // We need to us a number here to prevent an issue
  // with emotion passing the attribute to the DOM element.
  shown: 0 | 1;
};

export const LabelIcon = styled(Icon)<LabelIconProps>(({ shown }) => ({
  height: 'auto',
  width: 12,
  marginRight: 4,

  display: shown ? 'flex' : 'none',
}));
