import { graphql, readInlineData } from 'relay-runtime';
import { useRouter } from 'next/router';
import { useTranslation } from '@pafcloud/i18n';
import { formatCurrency } from '@pafcloud/locale';
import { createObjectKeyValidator, satisfies, isType } from '@pafcloud/collection-utils';
import { useConfig } from '@pafcloud/contexts';
import type {
  useGeneratedBonusTitleQuery$data,
  useGeneratedBonusTitleQuery$key,
} from './__generated__/useGeneratedBonusTitleQuery.graphql';

const bonusTitleQuery = graphql`
  fragment useGeneratedBonusTitleQuery on IBonusOffer @inline {
    isProgramGroup
    recurrence {
      availableMaxTimes
    }
    steps {
      __typename
      ... on DepositOfferStep {
        minDepositAmount
        ...useGeneratedBonusTitle_reward @relay(mask: false)
      }
      __typename
      ... on RecentDepositOfferStep {
        mustDeposit
        ...useGeneratedBonusTitle_reward @relay(mask: false)
      }

      ... on ClaimOfferStep {
        ...useGeneratedBonusTitle_reward @relay(mask: false)
      }

      ... on ImmediateOfferStep {
        ...useGeneratedBonusTitle_reward @relay(mask: false)
      }

      ... on TurnoverOfferStep {
        ...useGeneratedBonusTitle_reward @relay(mask: false)
      }

      ... on PlayRealMoneyOfferStep {
        playAmount
        game {
          name
        }
        ...useGeneratedBonusTitle_reward @relay(mask: false)
      }

      ... on PlayRealRoundsOfferStep {
        playRounds
        game {
          name
        }
        ...useGeneratedBonusTitle_reward @relay(mask: false)
      }

      ... on SafeBetOfferStep {
        playAmount
        ...useGeneratedBonusTitle_reward @relay(mask: false)
      }
    }
  }
`;

graphql`
  fragment useGeneratedBonusTitle_reward on BonusOfferStep {
    reward {
      __typename

      ... on BingoTicketsOfferReward {
        tickets
        ticketValue
        game {
          name
        }
      }

      ... on MatchDepositBonusMoneyOfferReward {
        matchPercentage
      }

      ... on BonusMoneyOfferReward {
        bonusAmount
      }

      ... on RealMoneyOfferReward {
        realAmount
      }

      ... on RefundRealMoneyOfferReward {
        __typename
      }

      ... on FreespinOfferReward {
        spins
        spinValue
        game {
          name
        }
        monetaryType
      }

      ... on FreeBetOfferReward {
        playAmount
        game {
          name
        }
      }

      ... on OddsBoostOfferReward {
        boostedOdds
        game {
          name
        }
      }
    }
  }
`;

type Step = useGeneratedBonusTitleQuery$data['steps'][number];
type InterpolationOptions = {
  recurringTitleDisclaimer: string | undefined;
};
type StepType = Step['__typename'];
type RewardType = NonNullable<Exclude<Step, { __typename: '%other' }>['reward']>['__typename'];

const translationKeyMap = satisfies<Partial<Record<StepType, Partial<Record<RewardType, string>>>>>()({
  PlayRealMoneyOfferStep: {
    BingoTicketsOfferReward: 'play-real-money-get-bingo-tickets.title',
    BonusMoneyOfferReward: 'play-real-money-get-bonus-money.title',
    RealMoneyOfferReward: 'play-real-money-get-real-money.title',
    FreespinOfferReward: 'play-real-money-get-rounds.title',
    FreeBetOfferReward: 'play-real-money-get-free-bet.title',
    OddsBoostOfferReward: 'play-real-money-get-odds-boost.title',
  },
  PlayRealRoundsOfferStep: {
    BingoTicketsOfferReward: 'play-real-rounds-get-bingo-tickets.title',
    BonusMoneyOfferReward: 'play-real-rounds-get-bonus-money.title',
    RealMoneyOfferReward: 'play-real-rounds-get-real-money.title',
    FreespinOfferReward: 'play-real-rounds-get-rounds.title',
    FreeBetOfferReward: 'play-real-rounds-get-free-bet.title',
    OddsBoostOfferReward: 'play-real-rounds-get-odds-boost.title',
  },
  ClaimOfferStep: {
    BingoTicketsOfferReward: 'claim-bingo-tickets.title',
    BonusMoneyOfferReward: 'claim-bonus-money.title',
    RealMoneyOfferReward: 'claim-real-money.title',
    FreespinOfferReward: 'claim-freespins.title',
    FreeBetOfferReward: 'claim-free-bet.title',
    OddsBoostOfferReward: 'claim-odds-boost.title',
  },
  ImmediateOfferStep: {
    BingoTicketsOfferReward: 'immediate-bingo-tickets.title',
    BonusMoneyOfferReward: 'immediate-bonus-money.title',
    RealMoneyOfferReward: 'immediate-real-money.title',
    FreespinOfferReward: 'immediate-freespins.title',
    FreeBetOfferReward: 'immediate-free-bet.title',
    OddsBoostOfferReward: 'immediate-odds-boost.title',
  },
  DepositOfferStep: {
    BingoTicketsOfferReward: 'deposit-for-bingo-tickets.title',
    BonusMoneyOfferReward: 'deposit-for-bonus-money.title',
    RealMoneyOfferReward: 'deposit-for-real-money.title',
    FreespinOfferReward: 'deposit-for-freespins.title',
    FreeBetOfferReward: 'deposit-for-free-bet.title',
    OddsBoostOfferReward: 'deposit-for-odds-boost.title',
  },
  RecentDepositOfferStep: {
    BingoTicketsOfferReward: 'recent-deposit-for-bingo-tickets.title',
    BonusMoneyOfferReward: 'recent-deposit-for-bonus-money.title',
    RealMoneyOfferReward: 'recent-deposit-for-real-money.title',
    FreespinOfferReward: 'recent-deposit-for-freespins.title',
    FreeBetOfferReward: 'recent-deposit-for-free-bet.title',
    OddsBoostOfferReward: 'recent-deposit-for-odds-boost.title',
  },
  SafeBetOfferStep: {
    RefundRealMoneyOfferReward: 'safe-bet-refund-real-money.title',
  },
} as const);

const getMissingRewardTitle = (firstStep: Step) => {
  if (isType(firstStep, 'DepositOfferStep')) {
    return 'deposit-no-direct-reward.title';
  }

  if (isType(firstStep, 'RecentDepositOfferStep')) {
    return 'recent-deposit-no-direct-reward.title';
  }

  if (isType(firstStep, 'PlayRealMoneyOfferStep')) {
    return 'play-real-money-no-direct-reward.title';
  }

  if (isType(firstStep, 'PlayRealRoundsOfferStep')) {
    return 'play-real-rounds-no-direct-reward.title';
  }

  return null;
};

const getTurnoverTitle = (firstStep: Step) => {
  const firstReward = 'reward' in firstStep ? firstStep.reward : null;

  if (isType(firstReward, 'FreespinOfferReward') && firstReward.monetaryType === 'BONUS_MONEY') {
    return 'freespins-for-turnover.title';
  }

  if (isType(firstStep, 'DepositOfferStep')) {
    if (isType(firstReward, 'MatchDepositBonusMoneyOfferReward')) {
      return 'turnover-deposit-match-deposit-percentage.title';
    }
    if (isType(firstReward, 'BonusMoneyOfferReward')) {
      return 'turnover-deposit-bonus-money.title';
    }
  }

  if (isType(firstStep, 'RecentDepositOfferStep')) {
    if (isType(firstReward, 'MatchDepositBonusMoneyOfferReward')) {
      return 'turnover-deposit-match-deposit-percentage.title-without-amount';
    }
    if (isType(firstReward, 'BonusMoneyOfferReward') && firstStep.mustDeposit) {
      return 'turnover-deposit-bonus-money.title-without-amount';
    }
  }

  if (isType(firstStep, 'ClaimOfferStep')) {
    return 'turnover-claim.title';
  }

  if (isType(firstStep, 'ImmediateOfferStep')) {
    return 'turnover-immediate.title';
  }
  return null;
};

const getTitleTranslationKey = (firstStep: Step, secondStep?: Step) => {
  const firstReward = 'reward' in firstStep ? firstStep.reward : null;

  if (firstReward == null) {
    return getMissingRewardTitle(firstStep);
  }

  if (isType(secondStep, 'TurnoverOfferStep', 'ImmediateOfferStep')) {
    const turnoverTitle = getTurnoverTitle(firstStep);
    if (turnoverTitle != null) {
      return turnoverTitle;
    }
  }

  // These are tightly tied together, one cannot exist without the other.
  if (isType(firstStep, 'SafeBetOfferStep')) {
    if (isType(firstReward, 'RefundRealMoneyOfferReward')) {
      return 'safe-bet-refund-real-money.title';
    }

    return null;
  }

  const isValidStepKey = createObjectKeyValidator(translationKeyMap);

  if (isValidStepKey(firstStep.__typename)) {
    const isValidRewardKey = createObjectKeyValidator(translationKeyMap[firstStep.__typename]);
    if (isValidRewardKey(firstReward.__typename)) {
      if (isType(firstStep, 'RecentDepositOfferStep') && !firstStep.mustDeposit) {
        return translationKeyMap['ImmediateOfferStep'][firstReward.__typename];
      }

      return translationKeyMap[firstStep.__typename][firstReward.__typename];
    }
  }

  if (isType(firstReward, 'BingoTicketsOfferReward')) {
    return 'bingo-tickets.title';
  }

  if (isType(firstReward, 'FreespinOfferReward')) {
    return 'freespins.title';
  }

  if (isType(firstReward, 'FreeBetOfferReward')) {
    return 'free-bet.title';
  }

  if (isType(firstReward, 'OddsBoostOfferReward')) {
    return 'odds-boost-reward.title';
  }

  return null;
};

type ValidRewardOnlyTranslationKeys =
  | 'bingo-tickets-reward.title'
  | 'bonus-money-percentage-reward.title'
  | 'real-money-reward.title'
  | 'refund-real-money-reward.title'
  | 'bonus-money-reward.title'
  | 'freespins-reward.title'
  | 'free-bet-reward.title'
  | 'deposit-no-direct-reward.title'
  | 'recent-deposit-no-direct-reward.title'
  | 'odds-boost-reward.title'
  | 'play-real-money-no-direct-reward.title'
  | 'play-real-rounds-no-direct-reward.title';

const resolveRewardStepTranslationKey = (step: Step): ValidRewardOnlyTranslationKeys | null => {
  const reward = 'reward' in step ? step.reward : null;

  if (reward == null) {
    return null;
  }

  if (isType(reward, 'BingoTicketsOfferReward')) {
    return 'bingo-tickets-reward.title';
  }

  if (isType(reward, 'MatchDepositBonusMoneyOfferReward')) {
    return 'bonus-money-percentage-reward.title';
  }

  if (isType(reward, 'RealMoneyOfferReward')) {
    return 'real-money-reward.title';
  }

  if (isType(reward, 'RefundRealMoneyOfferReward')) {
    return 'refund-real-money-reward.title';
  }

  if (isType(reward, 'BonusMoneyOfferReward')) {
    return 'bonus-money-reward.title';
  }

  if (isType(reward, 'FreespinOfferReward')) {
    return 'freespins-reward.title';
  }

  if (isType(reward, 'FreeBetOfferReward')) {
    return 'free-bet-reward.title';
  }

  if (isType(reward, 'OddsBoostOfferReward')) {
    return 'odds-boost-reward.title';
  }

  return null;
};

const getRewardOnlyTitleTranslationKey = (
  steps: readonly Step[],
): {
  key: ValidRewardOnlyTranslationKeys | null;
  stepIndex: number;
} => {
  const stepWithReward = steps.find((step) => 'reward' in step && step.reward != null);

  if (stepWithReward == null) {
    if (isType(steps[0], 'DepositOfferStep')) {
      return { key: 'deposit-no-direct-reward.title', stepIndex: 0 };
    }

    if (isType(steps[0], 'RecentDepositOfferStep')) {
      return { key: 'recent-deposit-no-direct-reward.title', stepIndex: 0 };
    }

    if (isType(steps[0], 'PlayRealMoneyOfferStep')) {
      return { key: 'play-real-money-no-direct-reward.title', stepIndex: 0 };
    }

    if (isType(steps[0], 'PlayRealRoundsOfferStep')) {
      return { key: 'play-real-rounds-no-direct-reward.title', stepIndex: 0 };
    }

    return { key: null, stepIndex: 0 };
  }

  return { key: resolveRewardStepTranslationKey(stepWithReward), stepIndex: steps.indexOf(stepWithReward) };
};

const useGetStepData = () => {
  const { locale } = useRouter();

  const formatAmount = (amount: number) =>
    formatCurrency({
      locale,
      amount,
      decimals: !Number.isInteger(amount),
    });

  const getRewardData = (step: Step, options?: InterpolationOptions) => {
    const reward = 'reward' in step ? step.reward : null;
    if (reward == null) {
      return {};
    }

    if (isType(reward, 'BonusMoneyOfferReward')) {
      return {
        valueWithCurrency: formatAmount(reward.bonusAmount),
        recurringText: options?.recurringTitleDisclaimer,
      };
    }

    if (isType(reward, 'RealMoneyOfferReward')) {
      return {
        valueWithCurrency: formatAmount(reward.realAmount),
        recurringText: options?.recurringTitleDisclaimer,
      };
    }

    if (isType(reward, 'BingoTicketsOfferReward')) {
      return {
        tickets: reward.tickets,
        ticketValueWithCurrency: formatAmount(reward.ticketValue),
        rewardGameName: reward.game?.name,
        recurringText: options?.recurringTitleDisclaimer,
      };
    }

    if (isType(reward, 'FreeBetOfferReward')) {
      return {
        amountWithCurrency: formatAmount(reward.playAmount),
        recurringText: options?.recurringTitleDisclaimer,
      };
    }

    if (isType(reward, 'FreespinOfferReward')) {
      return {
        value: reward.spins,
        spinValueWithCurrency: formatAmount(reward.spinValue),
        rewardGameName: reward.game?.name,
        recurringText: options?.recurringTitleDisclaimer,
      };
    }

    if (isType(reward, 'OddsBoostOfferReward')) {
      return {
        boostedOdds: reward.boostedOdds,
        rewardGameName: reward.game?.name,
        recurringText: options?.recurringTitleDisclaimer,
      };
    }

    if (isType(reward, 'MatchDepositBonusMoneyOfferReward')) {
      return {
        value: reward.matchPercentage,
        recurringText: options?.recurringTitleDisclaimer,
      };
    }

    return {};
  };

  const getRequirementData = (step: Step) => {
    if (isType(step, 'DepositOfferStep')) {
      return {
        targetWithCurrency: formatAmount(step.minDepositAmount),
      };
    }

    if (isType(step, 'PlayRealMoneyOfferStep')) {
      return {
        targetWithCurrency: formatAmount(step.playAmount),
        targetGameName: step.game?.name,
      };
    }

    if (isType(step, 'PlayRealRoundsOfferStep')) {
      return {
        target: step.playRounds,
        targetGameName: step.game?.name,
      };
    }

    if (isType(step, 'SafeBetOfferStep')) {
      return {
        targetWithCurrency: formatAmount(step.playAmount),
      };
    }

    return {};
  };

  return (step: Step, options?: InterpolationOptions) => ({
    ...getRequirementData(step),
    ...getRewardData(step, options),
  });
};

export const useGeneratedBonusTitle = (offerData?: useGeneratedBonusTitleQuery$key | null) => {
  const { t } = useTranslation('bonus');
  const getStepData = useGetStepData();
  const { siteUsesRewardOnlyBonusTitles } = useConfig();

  if (offerData == null) {
    return null;
  }

  const offer = readInlineData(bonusTitleQuery, offerData);

  if (offer.isProgramGroup) {
    return t('program-group.title');
  }

  const [firstStep, secondStep] = offer.steps;
  if (firstStep == null) {
    return null;
  }

  const recurringOffer = offer.recurrence;
  const availableMaxTimes = offer.recurrence?.availableMaxTimes;

  const recurringContinuousOrMaxTimes: string =
    availableMaxTimes != null
      ? t('recurring-times.title', {
          recurringTimes: availableMaxTimes,
        })
      : t('recurring-times-unlimited.title');

  const recurringTitleDisclaimer = recurringOffer != null ? recurringContinuousOrMaxTimes : undefined;

  const interpolationOptions = {
    recurringTitleDisclaimer,
  };

  if (siteUsesRewardOnlyBonusTitles) {
    const { key, stepIndex } = getRewardOnlyTitleTranslationKey(offer.steps);
    if (key == null) {
      return null;
    }

    return t(key, getStepData(offer.steps[stepIndex]));
  }

  const key = getTitleTranslationKey(firstStep, secondStep);
  if (key == null) {
    return null;
  }

  return t(key, getStepData(firstStep, interpolationOptions));
};
