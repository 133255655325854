import { SpeedyColor } from '@pafcloud/style';

export const Colors = {
  Text: SpeedyColor.White,
  TextHover: SpeedyColor.White,
  TextActive: SpeedyColor.White,
  TextShadow: `0 1px ${SpeedyColor.Shadow}`,
  TextTransform: undefined,

  Icon: SpeedyColor.White,
  IconActive: SpeedyColor.Green,

  Background: undefined,
  BackgroundHover: `${SpeedyColor.DarkTransparent25}`,
  BackgroundActive: SpeedyColor.DarkTransparent25,

  ItemDivider: undefined,
  ItemActiveShadow: `inset 4px 0 0 ${SpeedyColor.Primary}`,

  SubMenuBackground: SpeedyColor.DarkTransparent10,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: undefined,
  Weight: 600,
  WeightHover: 600,
  WeightActive: 900,
} as const;
