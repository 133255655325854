import type { Cookie } from './cookie';
import { defaultCookieOptions, secureCookieOptions } from './cookie';

export const JWT_TOKEN: Cookie = {
  ...secureCookieOptions,
  name: '_jwt',
  secure: true,
  sameSite: 'none',
  requiredConsent: 'essential',
};

export const HAS_SESSION: Cookie = {
  ...defaultCookieOptions,
  name: 'hasSession',
  expires: 1,
  requiredConsent: 'essential',
};

export const CSRF_TOKEN: Cookie = {
  ...defaultCookieOptions,
  secure: true,
  expires: undefined,
  name: 'csrfToken',
  requiredConsent: 'essential',
};

// Enable login on mobile devices from localhost
if (process.env.ENVIRONMENT === 'local') {
  JWT_TOKEN.secure = false;
  JWT_TOKEN.sameSite = 'lax';

  CSRF_TOKEN.secure = false;
  CSRF_TOKEN.sameSite = 'lax';
}

export const COOKIE_CONSENT: Cookie = {
  ...defaultCookieOptions,
  name: 'cookieConsent',
  secure: true,
  sameSite: 'none',
  requiredConsent: 'essential',
};

export const LAST_DEPOSIT_VALUE: Cookie = {
  ...defaultCookieOptions,
  name: 'LastDepositValue',
  requiredConsent: 'tracking',
};

export const REMINDER_INTERVAL_STARTED_AT: Cookie = {
  ...defaultCookieOptions,
  name: 'reminderIntervalStartedAt',
  requiredConsent: 'essential',
};

export const GAME_PAUSE_START_TIME: Cookie = {
  ...defaultCookieOptions,
  name: 'sessionStartTime',
  requiredConsent: 'essential',
};

export const AFFILIATE: Cookie = {
  ...defaultCookieOptions,
  name: 'affiliate',
  expires: 28,
  requiredConsent: 'essential', // TODO: Should this be tracking? It would need a rework of how we set it in useSetAffilateCookie
};

export const SELECTED_PROGRAM_ID: Cookie = {
  ...defaultCookieOptions,
  name: 'selectedProgramId',
  expires: 1,
  requiredConsent: 'essential',
};

export const PROGRAM_EXTERNAL_ID: Cookie = {
  ...defaultCookieOptions,
  name: 'programExternalId',
  expires: 1,
  requiredConsent: 'essential', // TODO: Should be handled with query params?
};

// Feature flags for testing.
// Make sure to create it with path "/": document.cookie='featureFlags=["some-feature","some-other-feature"];path=/'
export const FEATURE_FLAGS: Cookie = {
  ...defaultCookieOptions,
  name: 'featureFlags',
  path: '/',
  requiredConsent: 'essential',
};

export const IS_POKER_CLIENT: Cookie = {
  ...defaultCookieOptions,
  name: 'isPokerClient',
  expires: 7,
  requiredConsent: 'essential',
};

export const COUNTRY_REDIRECT: Cookie = {
  ...defaultCookieOptions,
  name: 'countryRedirect',
  requiredConsent: 'essential',
};

export const NEXT_LOCALE: Cookie = {
  name: 'NEXT_LOCALE',
  sameSite: 'lax',
  expires: 365,
  requiredConsent: 'essential',
};

export const SITE_ALERT: Omit<Cookie, 'name'> = {
  // Name is the id of the site-alert.
  requiredConsent: 'essential',
  expires: 1,
};

export const DEFERRED_LOGIN: Cookie = {
  ...secureCookieOptions,
  name: 'deferredLogin',
  secure: true,
  sameSite: 'lax',
  requiredConsent: 'essential',
  httpOnly: false,
  expires: 1,
};

export const BROWSER_TRACE_ID: Cookie = {
  ...defaultCookieOptions,
  expires: undefined,
  sameSite: 'none',
  secure: true,
  name: 'pafBrowserTraceId',
  requiredConsent: 'essential',
};
