import { Color } from '../../color';

export const getBrandBorder = () => ({
  borderTop: `6px solid ${Color.Primitive.Primary}`,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderBottomWidth: 0,
  borderImageSource: Color.Primitive.Gradient,
  borderImageSlice: '1 0',
});
