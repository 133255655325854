/**
 * @generated SignedSource<<5936cab4a575c2f41e9ddf17ddf868bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Contexts_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BonusContext_data" | "FeatureFlagContext_data" | "PlayerContext_data">;
  readonly " $fragmentType": "Contexts_data";
};
export type Contexts_data$key = {
  readonly " $data"?: Contexts_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"Contexts_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Contexts_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BonusContext_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlayerContext_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeatureFlagContext_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "dd5f1b8e01365c9122a8f4716b1a4989";

export default node;
