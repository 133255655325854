/**
 * @generated SignedSource<<8c4ab06398861da42171d267439550df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BrowseAppAccessRequirementName = "ACCEPT_TERMS_AND_CONDITIONS" | "SET_DEPOSIT_LIMITS" | "SET_SOURCE_OF_WEALTH" | "VERIFY_PHONE_NUMBER";
import { FragmentRefs } from "relay-runtime";
export type BrowseAppAccessGuard_requirements$data = ReadonlyArray<{
  readonly __typename: "AcceptRadarAccessRequirement";
  readonly " $fragmentSpreads": FragmentRefs<"AcceptRadarGuard_requirement">;
  readonly " $fragmentType": "BrowseAppAccessGuard_requirements";
} | {
  readonly __typename: "BrowseAppAccessRequirement";
  readonly name: BrowseAppAccessRequirementName;
  readonly skippable: boolean;
  readonly " $fragmentType": "BrowseAppAccessGuard_requirements";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "BrowseAppAccessGuard_requirements";
}>;
export type BrowseAppAccessGuard_requirements$key = ReadonlyArray<{
  readonly " $data"?: BrowseAppAccessGuard_requirements$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrowseAppAccessGuard_requirements">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BrowseAppAccessGuard_requirements",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skippable",
          "storageKey": null
        }
      ],
      "type": "BrowseAppAccessRequirement",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AcceptRadarGuard_requirement"
        }
      ],
      "type": "AcceptRadarAccessRequirement",
      "abstractKey": null
    }
  ],
  "type": "BrowseAccessRequirement",
  "abstractKey": "__isBrowseAccessRequirement"
};

(node as any).hash = "68f073d1c6acd74c74d146ba927d744c";

export default node;
