import { PAFColor } from '@pafcloud/style';

export const Colors = {
  Text: PAFColor.Dark,
  MessageSeenText: PAFColor.Neutral,
  PaginationText: PAFColor.BodyText,

  BorderColor: PAFColor.Shadow,

  Highlight: `${PAFColor.Primary}0D`, //5% opacity
  HighlightBorder: PAFColor.Primary,
};
