import styled from '@emotion/styled';
import { MarkdownParser } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { FontTextSize } from '@pafcloud/style';
import { Color } from '@pafcloud/style';

const HelpText = styled.div({
  padding: '0 16px',
});

const MarkDown = styled(MarkdownParser)({
  fontSize: FontTextSize.Small,
  textAlign: 'left',
  color: Color.MutedText,
});

const Paragraph = styled.p({
  margin: 0,
  color: 'inherit',

  '& + &': {
    marginTop: '1em',
  },
});

const List = styled.ul({
  margin: '8px 0 0 0',
  padding: '0 0 0 1rem',
});

export const PasswordHelpText = () => {
  const { t } = useTranslation('profile');

  return (
    <HelpText>
      <MarkDown components={{ p: Paragraph, ul: List }}>{t('profile.form.password-requirements')}</MarkDown>
    </HelpText>
  );
};
