/**
 * @generated SignedSource<<e1a24258ff3906ad5e45e3fa25533432>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type playAndGet_offer$data = {
  readonly offerId: number;
  readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_offer">;
  readonly " $fragmentType": "playAndGet_offer";
};
export type playAndGet_offer$key = {
  readonly " $data"?: playAndGet_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"playAndGet_offer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "playAndGet_offer"
};

(node as any).hash = "c0fb4665ad8b5877e6172bffee772a6e";

export default node;
