import type { FC, ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import type { ConfigProviderProps } from '@pafcloud/contexts';
import {
  ConfigProvider,
  BonusProvider,
  PlayerProvider,
  RemindersProvider,
  BackLinkProvider,
  GameTrackingProvider,
  CookieConsentProvider,
  LayoutVisibilityProvider,
  FeatureFlagProvider,
} from '@pafcloud/contexts';
import type { Contexts_data$key } from './__generated__/Contexts_data.graphql';

const dataFragment = graphql`
  fragment Contexts_data on Query {
    ...BonusContext_data
    ...PlayerContext_data
    ...FeatureFlagContext_data
  }
`;

type ContextsProps = {
  isLoadingClientData: boolean;
  config: ConfigProviderProps['config'];
  data: Contexts_data$key;
  children: ReactNode;
};

export const Contexts: FC<ContextsProps> = ({ isLoadingClientData, config, children, ...props }) => {
  const data = useFragment(dataFragment, props.data);
  return (
    <LayoutVisibilityProvider>
      <ConfigProvider config={config}>
        <FeatureFlagProvider data={data}>
          <CookieConsentProvider>
            <BonusProvider data={data}>
              <PlayerProvider isLoadingClientData={isLoadingClientData} data={data}>
                <RemindersProvider>
                  <BackLinkProvider>
                    <GameTrackingProvider>{children}</GameTrackingProvider>
                  </BackLinkProvider>
                </RemindersProvider>
              </PlayerProvider>
            </BonusProvider>
          </CookieConsentProvider>
        </FeatureFlagProvider>
      </ConfigProvider>
    </LayoutVisibilityProvider>
  );
};
