export type LogConfig = {
  serviceName?: string;
  env: 'local' | 'stg' | 'test' | 'prod';
  logLevel: 'debug' | 'info' | 'warn' | 'error';
};

export const logConfig: LogConfig = {
  env: (process.env.ENVIRONMENT ?? 'prod') as LogConfig['env'],
  serviceName: process.env.SERVICE_NAME ?? '',
  logLevel: (process.env.LOG_LEVEL ?? 'info') as LogConfig['logLevel'],
};
