/**
 * @generated SignedSource<<377b49cd9b5c046d90f01119417ea36b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SyncAppData_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useAfterLoginReminder_data" | "useIntervalReminders_data" | "useSessionExpiresReminder_data">;
  readonly " $fragmentType": "SyncAppData_data";
};
export type SyncAppData_data$key = {
  readonly " $data"?: SyncAppData_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SyncAppData_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SyncAppData_data",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useIntervalReminders_data",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Player",
          "kind": "LinkedField",
          "name": "player",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Reminders",
              "kind": "LinkedField",
              "name": "reminder",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "interval",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useAfterLoginReminder_data",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Player",
          "kind": "LinkedField",
          "name": "player",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Reminders",
              "kind": "LinkedField",
              "name": "reminder",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "afterLogin",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useSessionExpiresReminder_data",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Player",
          "kind": "LinkedField",
          "name": "player",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Session",
              "kind": "LinkedField",
              "name": "session",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expiresAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "109f3e7f5b502ea49d58a1c2d7f7a26e";

export default node;
