import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import type { FC, HTMLProps } from 'react';
import { Font, FontTextSize } from '@pafcloud/style';
import { FormInputColors } from './form-input-colors';
import { FormInputStyle } from './form-input-style';
import { FormInputProperties } from './form-input-style/properties';
import { FormInputZIndex } from './FormInputZIndex';

type Props = Omit<HTMLProps<HTMLParagraphElement>, 'as'> & {
  type?: 'error' | 'info';
  alignment?: 'top' | 'bottom';
};

const Alert: FC<Props> = ({ children, ...props }) => {
  return (
    <p role="alert" {...props}>
      {children}
    </p>
  );
};

const getArrowStyle = (type: Props['type'], alignment: Props['alignment']): CSSObject => {
  if (alignment === 'top') {
    return {
      borderTopColor: type === 'error' ? FormInputColors.ErrorMessageBackground : FormInputColors.InfoMessageBackground,
      borderBottom: 0,
      bottom: -10,
    };
  }

  return {
    borderBottomColor: type === 'error' ? FormInputColors.ErrorMessageBorder : FormInputColors.InfoMessageBorder,
    borderTop: 0,
    top: -10,
  };
};

export const FormInputMessage = styled(Alert, { shouldForwardProp: (propName) => propName !== 'type' })<Props>(
  ({ type = 'error', alignment = 'top' }) => ({
    width: `100%`,
    minHeight: 56,
    padding: `18px var(${FormInputProperties.TextIndent}) 14px`,
    color: type === 'error' ? FormInputColors.ErrorMessageTextColor : FormInputColors.InfoMessageTextColor,
    fontFamily: Font.Body,
    fontWeight: 'bold',
    fontSize: FontTextSize.Normal,
    lineHeight: 1.5,
    display: 'none',
    flexFlow: 'column',
    position: 'absolute',
    bottom: alignment === 'top' ? 'calc(100% + 4px)' : undefined,
    top: alignment === 'bottom' ? 'calc(100% - 4px)' : undefined,
    left: 0,
    backgroundColor: type === 'error' ? FormInputColors.ErrorMessageBackground : FormInputColors.InfoMessageBackground,
    boxShadow: FormInputColors.MessageDropShadow,
    textAlign: 'left',
    transform: 'translateZ(0)', // IOS safari workaround. Safari left a ghost drop-shadow when the message should be hidden
    borderRadius: 3,
    zIndex: FormInputZIndex.Message,

    // Highlight
    ':before': {
      width: '100%',
      borderTopWidth: 6,
      borderTopStyle: 'solid',
      borderTopColor: type === 'error' ? FormInputColors.ErrorMessageBorder : FormInputColors.InfoMessageBorder,
      position: 'absolute',
      left: '0',
      bottom: 'calc(100% - 6px)',
      borderRadius: '3px 3px 0 0',
      content: '""',
    },
    // Arrow
    '::after': {
      border: '10px solid transparent',
      position: 'absolute',
      left: `calc(var(${FormInputProperties.TextIndent}) - ${FormInputStyle.LabelGap})`,
      content: '""',
      ...getArrowStyle(type, alignment),
    },
  }),
);
