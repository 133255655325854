import styled from '@emotion/styled';
import { Link } from '@pafcloud/base-components';
import { BaseFont, Color, deviceWithHover } from '@pafcloud/style';

export const HeaderMenuLink = styled(Link)({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  fontFamily: BaseFont.BrandFont,
  color: Color.Primitive.Primary,
  outline: 'none',

  // Underline hover effect
  '::before': {
    content: '""',
    position: 'absolute',
    bottom: -4,
    borderRadius: 4,
    height: 2,
    width: 0,
    background: 'currentColor',
    transition: 'width 0.2s ease-in-out',
  },

  '&[aria-current="true"]::before': {
    width: 'calc(100% + 2px)',
  },

  [deviceWithHover]: {
    '&:focus-visible::before, &:hover::before': {
      width: 'calc(100% + 2px)',
    },
  },
});
