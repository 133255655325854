import { createTransparentColor } from '../create-transparent-color';
import type {
  CornerRadiusType,
  ElevationType,
  GeneralType,
  HeroColorType,
  LayoutColors,
  PrimitiveColorType,
  SignalColorType,
  SurfaceColorType,
  TextColorType,
} from './color-types';

const PMLBrandColors = {
  OceanicNoir: '#182F39',

  // Primary Colors
  Primary: '#E5C64A',
  PrimaryLight: '#EED15F',
  PrimaryHighlight: '#EFD980',

  // Alternate Colors
  Alternate: '#26EFD0',
  AlternateLight: '#64F5DF',
  AlternateHighlight: '#9CF3E6',
  AlternateMuted: '#a0d4d4',

  // Neutral Colors
  Dark: '#0a2028',
  Darker: '#020507',
  Neutral: '#787F83',
  Lighter: '#eaf0f3',
  White: '#ffffff',

  // Extra
  Aux: '#EF2656',
};

const Primitive: PrimitiveColorType = {
  Primary: PMLBrandColors.Primary,
  PrimaryTint: PMLBrandColors.PrimaryLight,
  PrimaryContrast: PMLBrandColors.Darker,

  Secondary: PMLBrandColors.Alternate,
  SecondaryTint: PMLBrandColors.AlternateHighlight,
  SecondaryContrast: PMLBrandColors.Darker,

  Accent: PMLBrandColors.Alternate,
  AccentTint: PMLBrandColors.AlternateHighlight,
  AccentContrast: PMLBrandColors.Darker,

  Gradient: PMLBrandColors.Alternate,
  GradientTint: PMLBrandColors.AlternateHighlight,
  GradientContrast: PMLBrandColors.Darker,
};

const TextColors: TextColorType = {
  HeadingText: PMLBrandColors.Primary,
  BodyText: PMLBrandColors.White,
  MutedText: '#a0a8ac',
  HighlightedText: PMLBrandColors.Primary,
  LinkText: PMLBrandColors.Alternate,
  ErrorText: PMLBrandColors.Aux,
  DisabledText: PMLBrandColors.Neutral,
};

const Surface: SurfaceColorType = {
  Base: {
    Background: '#0c1d24',
    Foreground: PMLBrandColors.White,
    Dimmed: createTransparentColor(PMLBrandColors.Alternate, 0.15),
  },
  Nested: {
    Background: PMLBrandColors.Darker,
    Foreground: PMLBrandColors.White,
    Dimmed: createTransparentColor(PMLBrandColors.Alternate, 0.15),
  },
  Disabled: {
    Background: PMLBrandColors.Neutral,
    Foreground: PMLBrandColors.Darker + '80',
    Dimmed: createTransparentColor(PMLBrandColors.Darker, 0.15),
  },
  Floating: {
    Background: PMLBrandColors.OceanicNoir,
    Foreground: PMLBrandColors.White,
    Dimmed: createTransparentColor(PMLBrandColors.Alternate, 0.15),
  },
};

const Signal: SignalColorType = {
  Success: PMLBrandColors.Primary,
  SuccessContrast: PMLBrandColors.Darker,

  Info: PMLBrandColors.AlternateMuted,
  InfoContrast: PMLBrandColors.Darker,

  Attention: PMLBrandColors.Primary,
  AttentionContrast: PMLBrandColors.Darker,

  Danger: PMLBrandColors.Aux,
  DangerContrast: PMLBrandColors.White,
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '8px',
  Large: '32px',
};

const Elevation: ElevationType = {
  Level1: '0 1px 2px var(--shadow-color, rgba(0, 0, 0, 0.1))',
  Level2: '0 4px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 12px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: GeneralType = {
  Bonus: PMLBrandColors.Alternate,
  BonusContrast: PMLBrandColors.Darker,
};

const Layout: LayoutColors = {
  Background: PMLBrandColors.OceanicNoir,
  BrandBorder: Primitive.Primary,
  BrowserTheme: PMLBrandColors.OceanicNoir,
  LoadingColor: Primitive.Accent,
  Overlay: Surface.Base.Background + 'CC',
  Sidebar: {
    Background: PMLBrandColors.OceanicNoir,
    Foreground: PMLBrandColors.Lighter,
  },
};

const Hero: HeroColorType = {
  Heading: Primitive.Primary,
  Text: PMLBrandColors.White,
  TextHighlight: Primitive.Secondary,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Primary,
    Hover: Primitive.PrimaryTint,
    Active: Primitive.PrimaryTint,
    Text: Primitive.PrimaryContrast,
  },
};

export const Color = {
  ...PMLBrandColors,
  ...TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,

  Background: PMLBrandColors.OceanicNoir,
  //Panel bg
  CardBackground: 'rgba(12, 29, 36, 0.75)',

  // Shadows, highlight and transparency
  Shadow: createTransparentColor(PMLBrandColors.Darker, 0.25),
  ShadowInset: createTransparentColor(PMLBrandColors.Lighter, 0.2),

  FocusHighlight: createTransparentColor(PMLBrandColors.Alternate, 0.33),

  AlternateTransparent10: createTransparentColor(PMLBrandColors.Alternate, 0.1),
  AlternateTransparent25: createTransparentColor(PMLBrandColors.Alternate, 0.25),
  AlternateTransparent50: createTransparentColor(PMLBrandColors.Alternate, 0.5),
  AlternateTransparent70: createTransparentColor(PMLBrandColors.Alternate, 0.7),

  PrimaryTransparent10: createTransparentColor(PMLBrandColors.Primary, 0.1),
  PrimaryTransparent30: createTransparentColor(PMLBrandColors.Primary, 0.3),
  PrimaryTransparent50: createTransparentColor(PMLBrandColors.Primary, 0.5),

  LighterTransparent25: createTransparentColor(PMLBrandColors.Lighter, 0.25),
  LighterTransparent50: createTransparentColor(PMLBrandColors.Lighter, 0.5),

  DarkerTransparent20: createTransparentColor(PMLBrandColors.Darker, 0.2),
  DarkerTransparent50: createTransparentColor(PMLBrandColors.Darker, 0.5),
  DarkerTransparent75: createTransparentColor(PMLBrandColors.Darker, 0.75),

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart} ${ColorEnd})`,
  AdaptiveBackground: PMLBrandColors.Alternate,

  // Theme color for mobile browsers
  BrowserTheme: PMLBrandColors.OceanicNoir,
};
