import { useRouter } from 'next/router';
import { formatCurrency, getCurrencySymbol } from '@pafcloud/locale';
import type { FormatCurrencyProps, CurrencyCode } from '@pafcloud/locale';

type Overrides = Omit<FormatCurrencyProps, 'amount' | 'locale'>;

export function useFormatAmount(overrides: Overrides = {}) {
  const router = useRouter();

  return function formatAmount(amount: number | null | undefined, localOverrides: Overrides = {}) {
    return formatCurrency({
      amount,
      locale: router.locale,
      ...overrides,
      ...localOverrides,
    });
  };
}

/**
 * For things like jackpots where it is optimal to have a screen reader read the number in full
 * and without decimals, but with the currency. Example
 * The jackpot "320400989" in euros with useFormatAmount would become:
 * "threehundred and twenty four hundred ninehundred and eighty-nine euros"
 * The same jackpot with useFormatScreenReaderCurrencyAmount becomes:
 * "threehundred and twenty million four hundred thousand nine hundred and eighty-nine euros"
 */
export function useFormatScreenReaderAmount() {
  return function formatScreenReaderAmount(amount: number | null | undefined, currencyCode?: string) {
    if (currencyCode != null) {
      return `${amount?.toString().split(/[.,]/)[0]}${getCurrencySymbol(currencyCode as CurrencyCode)}`;
    }

    return `${amount?.toString().split(/[.,]/)[0]}`;
  };
}
