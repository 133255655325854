export const capitalize = <T extends string>(str: T) => {
  return (str[0].toUpperCase() + str.slice(1)) as Capitalize<T>;
};

export const uncapitalize = <T extends string>(str: T) => {
  return (str[0].toLowerCase() + str.slice(1)) as Uncapitalize<T>;
};

export const lowercase = <T extends string>(str: T) => {
  return str.toLowerCase() as Lowercase<T>;
};

export const upperCase = <T extends string>(str: T) => {
  return str.toUpperCase() as Uppercase<T>;
};

type KebabCase<S extends string> =
  // Handle spaces
  S extends `${infer A} ${infer B}`
    ? KebabCase<`${KebabCase<A>}-${KebabCase<B>}`>
    : // Handle snake case
      S extends `${infer A}_${infer B}`
      ? KebabCase<`${KebabCase<A>}-${KebabCase<B>}`>
      : // Handle upper case
        S extends Uppercase<S>
        ? Lowercase<S>
        : // Handle camel case & pascal case
          S extends `${infer A}${infer B}`
          ? B extends Uncapitalize<B>
            ? `${Uncapitalize<A>}${KebabCase<B>}`
            : `${Uncapitalize<A>}-${KebabCase<B>}`
          : S;

/**
 * @example kebabCase('camelCase') // 'camel-case
 * @example kebabCase('hello world') // 'hello-world'
 * @example kebabCase('hello_world') // 'hello-world'
 * @example kebabCase('some-mixed_string spaces_underscores') // 'some-mixed-string-spaces-underscores'
 */
export const kebabCase = <T extends string>(str: T) => {
  return str
    .trim()
    .replace(/([a-z]|[A-Z])([_|\s])([a-z]|[A-Z])/g, '$1-$3')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .toLowerCase() as KebabCase<T>;
};

export type SnakeCase<S extends string> =
  // Handle spaces
  S extends `${infer A} ${infer B}`
    ? SnakeCase<`${SnakeCase<A>}_${SnakeCase<B>}`>
    : // Handle kebab case
      S extends `${infer A}-${infer B}`
      ? SnakeCase<`${SnakeCase<A>}_${SnakeCase<B>}`>
      : // Handle upper case
        S extends Uppercase<S>
        ? Lowercase<S>
        : // Handle camel case & pascal case
          S extends `${infer A}${infer B}`
          ? B extends Uncapitalize<B>
            ? `${Uncapitalize<A>}${SnakeCase<B>}`
            : `${Uncapitalize<A>}_${SnakeCase<B>}`
          : S;

/**
 * @example snakeCase('camelCase') // 'camel_case
 * @example snakeCase('hello world') // 'hello_world'
 * @example snakeCase('hello-world') // 'hello_world'
 * @example snakeCase('some-mixed_string spaces_underscores') // 'some_mixed_string_spaces_underscores'
 */
export const snakeCase = <T extends string>(str: T) => {
  return str
    .trim()
    .replace(/([a-z]|[A-Z])([-|\s])([a-z]|[A-Z])/g, '$1_$3')
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase() as SnakeCase<T>;
};

/**
 * @example snakeCase('camelCase') // 'CAMEL_CASE
 * @example snakeCase('hello world') // 'HELLO_WORLD'
 * @example snakeCase('hello-world') // 'HELLO_WORLD'
 * @example snakeCase('some-mixed_string spaces_underscores') // 'SOME_MIXED_STRING_SPACES_UNDERSCORES'
 */
export const screamingSnakeCase = <T extends string>(str: T) => {
  return snakeCase(str).toUpperCase() as Uppercase<SnakeCase<T>>;
};

type PascalCase<S extends string> =
  // Handle spaces
  S extends `${infer A} ${infer B}`
    ? PascalCase<`${Capitalize<PascalCase<A>>}${Capitalize<PascalCase<B>>}`>
    : // Handle kebab case
      S extends `${infer A}-${infer B}`
      ? PascalCase<`${Capitalize<PascalCase<A>>}${Capitalize<PascalCase<B>>}`>
      : // Handle snake case
        S extends `${infer A}_${infer B}`
        ? PascalCase<`${Capitalize<PascalCase<A>>}${Capitalize<PascalCase<B>>}`>
        : // Handle upper case
          S extends Uppercase<S>
          ? Capitalize<Lowercase<S>>
          : Capitalize<S>;

/**
 * @example pascalCase('hello_world') // 'HelloWorld
 * @example pascalCase('hello world') // 'HelloWorld'
 * @example pascalCase('hello-world') // 'HelloWorld'
 * @example pascalCase('some-mixed_string spaces_underscores') // 'SomeMixedStringSpacesUnderscores'
 */
export const pascalCase = <T extends string>(str: T) => {
  return snakeCase(str).split('_').map(capitalize).join('') as PascalCase<T>;
};

/**
 * @example camelCase('hello_world') // 'helloWorld
 * @example camelCase('hello world') // 'helloWorld'
 * @example camelCase('hello-world') // 'helloWorld'
 * @example camelCase('some-mixed_string spaces_underscores') // 'someMixedStringSpacesUnderscores'
 */
export const camelCase = <T extends string>(str: T) => {
  return uncapitalize(pascalCase(str));
};
