import { Color, OneOneColor } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  LabelInvalid: Color.Signal.Attention,

  BorderInvalid: Color.BodyText,

  Suffix: OneOneColor.White,
};
