/**
 * @generated SignedSource<<6936b4352440fdcfa7b392b984833edb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RichTextAlignment = "CENTER" | "LEFT" | "RIGHT";
import { FragmentRefs } from "relay-runtime";
export type FooterBlocks_footerBlocks$data = ReadonlyArray<{
  readonly __typename: "FooterImageBlock";
  readonly images: ReadonlyArray<{
    readonly image: {
      readonly height: number;
      readonly url: string;
      readonly width: number;
    };
    readonly name: string;
    readonly url: string | null;
  }>;
  readonly title: string | null;
  readonly " $fragmentType": "FooterBlocks_footerBlocks";
} | {
  readonly __typename: "FooterRichTextBlock";
  readonly text: {
    readonly jsonContent: {
      readonly json: string | null;
    };
    readonly textAlignment: RichTextAlignment;
  } | null;
  readonly " $fragmentType": "FooterBlocks_footerBlocks";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "FooterBlocks_footerBlocks";
}>;
export type FooterBlocks_footerBlocks$key = ReadonlyArray<{
  readonly " $data"?: FooterBlocks_footerBlocks$data;
  readonly " $fragmentSpreads": FragmentRefs<"FooterBlocks_footerBlocks">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "FooterBlocks_footerBlocks",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FooterImage",
          "kind": "LinkedField",
          "name": "images",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "FooterImageBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "RichTextBlock",
          "kind": "LinkedField",
          "name": "text",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "textAlignment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Json",
              "kind": "LinkedField",
              "name": "jsonContent",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "json",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "FooterRichTextBlock",
      "abstractKey": null
    }
  ],
  "type": "FooterBlock",
  "abstractKey": "__isFooterBlock"
};
})();

(node as any).hash = "42cd0b20521978a416866b7e5a344f02";

export default node;
