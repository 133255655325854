/**
 * @generated SignedSource<<4cec25ba50f69893aa604cb415c0b9af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Icon = "ACCOUNT" | "AGE" | "ARROW_LEFT_FAT" | "AWARD" | "BACCARAT" | "BASKETBALL" | "BELT" | "BETTING" | "BINGO" | "BINGO_BALL" | "BLACKJACK" | "BOMB" | "BONUS" | "CARE" | "CASINO_LIVE" | "CHAT_BUBBLES" | "CHECK" | "CHEVRON_DOUBLE_RIGHT" | "CHEVRON_RIGHT" | "CHEVRON_RIGHT_FAT" | "CHEVRON_RIGHT_THIN_ROUNDED" | "CHRISTMAS" | "CLOCK" | "CLOSE_FAT" | "CLOSE_FAT_ROUNDED" | "CLOSE_THIN_ROUNDED" | "COIN" | "COINS" | "COLLECTION" | "DICE" | "DOWNLOAD" | "EMAIL" | "EXCLAMATION" | "EXTERNAL_LINK" | "FANTASY_SPORT" | "FULLSCREEN" | "FULLSCREEN_CLOSE" | "GIFT" | "GLOBE" | "GRAPH" | "HAMBURGER" | "HISTORY" | "HOME" | "HOT" | "HOT_BALLOON" | "HOURGLASS" | "INFO" | "INSURANCE" | "JACKPOT" | "JACKPOT_POOLS" | "KEBAB" | "LIGHTBULB" | "LOCK" | "LOGOUT" | "MAINTENANCE" | "MESSAGE" | "MINUS" | "NOTES" | "NOTIFICATION" | "OPEN_BANKING" | "OTHER_GAMES" | "PACKAGE" | "PAUSE" | "PHONE" | "PLAY" | "PLAYERS" | "PLUS" | "POKER" | "POKER_HAND" | "ROOF" | "ROULETTE" | "ROUNDED_SQUARE" | "RULES" | "SEARCH" | "SETTINGS" | "SHIELD" | "SLIDERS" | "SLOTS" | "SLOT_MACHINE" | "SOUND_OFF" | "SOUND_ON" | "STAR_BADGE" | "STOP_WATCH" | "SUPPORT" | "SUPPORT_CHAT" | "SUPPORT_HANDS" | "TICKET_GRID" | "TICKET_SPECIAL" | "TOGGLE_SEE" | "TOGGLE_UNSEE" | "TOOLS" | "TRASH" | "TROPHY" | "TURNOVER_CONTRIBUTION" | "UPLOAD" | "VIDEO_POKER" | "WALLET";
import { FragmentRefs } from "relay-runtime";
export type MainMenuLink_content$data = {
  readonly iconName: Icon | null;
  readonly text: string;
  readonly url: string;
  readonly " $fragmentType": "MainMenuLink_content";
};
export type MainMenuLink_content$key = {
  readonly " $data"?: MainMenuLink_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"MainMenuLink_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainMenuLink_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "MenuLink",
  "abstractKey": null
};

(node as any).hash = "4cf7c1e0700603c15c29008690a6294a";

export default node;
