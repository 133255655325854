import dynamic from 'next/dynamic';
import type { FC, ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { PageContainer, renderIntoRootContainer } from '@pafcloud/base-components';
import { isType, satisfies } from '@pafcloud/collection-utils';
import type {
  BrowseAppAccessGuard_requirements$key,
  BrowseAppAccessRequirementName,
} from './__generated__/BrowseAppAccessGuard_requirements.graphql';
import { useAccessRequirements } from './useAccessRequirements';

const components = satisfies<Record<BrowseAppAccessRequirementName, unknown>>()({
  ACCEPT_TERMS_AND_CONDITIONS: dynamic(() => import('./guards/terms-and-conditions-guard/TermsAndConditionsGuard')),
  SET_DEPOSIT_LIMITS: dynamic(() => import('./guards/set-deposit-limits/SetDepositLimitsStepGuard')),
  SET_SOURCE_OF_WEALTH: dynamic(() => import('./guards/source-of-wealth/SetSourceOfWealthGuard')),
  VERIFY_PHONE_NUMBER: dynamic(() => import('./guards/verify-phone-number/VerifyPhoneNumberGuardFlow')),
});

const AcceptRadarGuard = dynamic(() => import('./guards/accept-radar-guard/AcceptRadarGuard'));

const requirementsFragment = graphql`
  fragment BrowseAppAccessGuard_requirements on BrowseAccessRequirement @relay(plural: true) {
    __typename
    ... on BrowseAppAccessRequirement {
      name
      skippable
    }
    ... on AcceptRadarAccessRequirement {
      ...AcceptRadarGuard_requirement
    }
  }
`;

type Props = {
  requirements: BrowseAppAccessGuard_requirements$key;
  children?: ReactNode;
};

export const BrowseAppAccessGuard: FC<Props> = (props) => {
  const requirements = useFragment(requirementsFragment, props.requirements);
  const { requirement, complete } = useAccessRequirements(requirements);

  if (requirement == null) {
    return props.children;
  }

  return (
    <>
      <PageContainer />
      {(() => {
        if (isType(requirement, 'BrowseAppAccessRequirement')) {
          const skip = () => {
            if (requirement.skippable) {
              complete();
            }
          };

          const GuardComponent = components[requirement.name];
          return renderIntoRootContainer(<GuardComponent onCompleted={complete} onSkip={skip} />);
        }

        if (isType(requirement, 'AcceptRadarAccessRequirement')) {
          return renderIntoRootContainer(<AcceptRadarGuard requirement={requirement} onCompleted={complete} />);
        }

        return null;
      })()}
    </>
  );
};
