/**
 * @generated SignedSource<<0f5fef18d1d8d359d1749234d0c3fdf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GamingFreezeIssuer = "HAMPI" | "PLAYER" | "SOURCE_OF_FUNDS" | "SOURCE_OF_WEALTH";
export type useLoginTrackingEventQuery$variables = Record<PropertyKey, never>;
export type useLoginTrackingEventQuery$data = {
  readonly player: {
    readonly address: {
      readonly country: string | null;
    } | null;
    readonly ageRange: string | null;
    readonly allowsMarketingEmails: boolean | null;
    readonly allowsMarketingSms: boolean | null;
    readonly currency: string | null;
    readonly depositLimit: {
      readonly daily: {
        readonly amount: number | null;
      };
      readonly monthly: {
        readonly amount: number | null;
      };
      readonly weekly: {
        readonly amount: number | null;
      };
    } | null;
    readonly email: string | null;
    readonly externalId: string;
    readonly gamingFreeze: {
      readonly all: {
        readonly freezeIssuer: GamingFreezeIssuer;
      } | null;
      readonly betting: {
        readonly freezeIssuer: GamingFreezeIssuer;
      } | null;
      readonly bingo: {
        readonly freezeIssuer: GamingFreezeIssuer;
      } | null;
      readonly poker: {
        readonly freezeIssuer: GamingFreezeIssuer;
      } | null;
      readonly slots: {
        readonly freezeIssuer: GamingFreezeIssuer;
      } | null;
      readonly table: {
        readonly freezeIssuer: GamingFreezeIssuer;
      } | null;
    } | null;
    readonly lossLimit: {
      readonly daily: {
        readonly amount: number | null;
      } | null;
      readonly monthly: {
        readonly amount: number | null;
      } | null;
      readonly weekly: {
        readonly amount: number | null;
      } | null;
    } | null;
    readonly phoneNumber: string | null;
    readonly sessionLimit: {
      readonly daily: {
        readonly amount: number | null;
      } | null;
      readonly monthly: {
        readonly amount: number | null;
      } | null;
      readonly weekly: {
        readonly amount: number | null;
      } | null;
    } | null;
    readonly subscribeOffers: boolean | null;
  } | null;
};
export type useLoginTrackingEventQuery = {
  response: useLoginTrackingEventQuery$data;
  variables: useLoginTrackingEventQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "PlayerAddress",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ageRange",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowsMarketingEmails",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowsMarketingSms",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscribeOffers",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "DepositLimit",
  "kind": "LinkedField",
  "name": "daily",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "DepositLimit",
  "kind": "LinkedField",
  "name": "weekly",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "DepositLimit",
  "kind": "LinkedField",
  "name": "monthly",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "SessionLimit",
  "kind": "LinkedField",
  "name": "daily",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "SessionLimit",
  "kind": "LinkedField",
  "name": "weekly",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "SessionLimit",
  "kind": "LinkedField",
  "name": "monthly",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "LossLimit",
  "kind": "LinkedField",
  "name": "daily",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "LossLimit",
  "kind": "LinkedField",
  "name": "weekly",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "LossLimit",
  "kind": "LinkedField",
  "name": "monthly",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v19 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "freezeIssuer",
    "storageKey": null
  }
],
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "GamingFreeze",
  "kind": "LinkedField",
  "name": "all",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "GamingFreeze",
  "kind": "LinkedField",
  "name": "betting",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "GamingFreeze",
  "kind": "LinkedField",
  "name": "bingo",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "GamingFreeze",
  "kind": "LinkedField",
  "name": "poker",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "GamingFreeze",
  "kind": "LinkedField",
  "name": "slots",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "GamingFreeze",
  "kind": "LinkedField",
  "name": "table",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLoginTrackingEventQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "NONE",
            "path": "player.externalId"
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DepositLimits",
            "kind": "LinkedField",
            "name": "depositLimit",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SessionLimits",
            "kind": "LinkedField",
            "name": "sessionLimit",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LossLimits",
            "kind": "LinkedField",
            "name": "lossLimit",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GamingFreezes",
            "kind": "LinkedField",
            "name": "gamingFreeze",
            "plural": false,
            "selections": [
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useLoginTrackingEventQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DepositLimits",
            "kind": "LinkedField",
            "name": "depositLimit",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SessionLimits",
            "kind": "LinkedField",
            "name": "sessionLimit",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LossLimits",
            "kind": "LinkedField",
            "name": "lossLimit",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GamingFreezes",
            "kind": "LinkedField",
            "name": "gamingFreeze",
            "plural": false,
            "selections": [
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          (v26/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f846c58785a8bf7ffe122e4ed01b1b27",
    "id": null,
    "metadata": {},
    "name": "useLoginTrackingEventQuery",
    "operationKind": "query",
    "text": "query useLoginTrackingEventQuery {\n  player {\n    externalId\n    address {\n      country\n    }\n    currency\n    ageRange\n    email\n    phoneNumber\n    allowsMarketingEmails\n    allowsMarketingSms\n    subscribeOffers\n    depositLimit {\n      daily {\n        amount\n      }\n      weekly {\n        amount\n      }\n      monthly {\n        amount\n      }\n      id\n    }\n    sessionLimit {\n      daily {\n        amount\n      }\n      weekly {\n        amount\n      }\n      monthly {\n        amount\n      }\n      id\n    }\n    lossLimit {\n      daily {\n        amount\n      }\n      weekly {\n        amount\n      }\n      monthly {\n        amount\n      }\n      id\n    }\n    gamingFreeze {\n      all {\n        freezeIssuer\n      }\n      betting {\n        freezeIssuer\n      }\n      bingo {\n        freezeIssuer\n      }\n      poker {\n        freezeIssuer\n      }\n      slots {\n        freezeIssuer\n      }\n      table {\n        freezeIssuer\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "180935d5dd5c177df771aa667812448b";

export default node;
