/**
 * @generated SignedSource<<f45c82027e9514e246d4da3272463313>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MessageMenuListQuery$variables = Record<PropertyKey, never>;
export type MessageMenuListQuery$data = {
  readonly inbox: {
    readonly messagePreviews: {
      readonly " $fragmentSpreads": FragmentRefs<"MessageList_list">;
    };
  } | null;
};
export type MessageMenuListQuery = {
  response: MessageMenuListQuery$data;
  variables: MessageMenuListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "includeOpened",
    "value": false
  },
  {
    "kind": "Literal",
    "name": "page",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "size",
    "value": 3
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MessageMenuListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Inbox",
        "kind": "LinkedField",
        "name": "inbox",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": (v0/*: any*/),
              "concreteType": "InboxMessagePreviews",
              "kind": "LinkedField",
              "name": "messagePreviews",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MessageList_list"
                }
              ],
              "storageKey": "messagePreviews(includeOpened:false,page:1,size:3)"
            },
            "action": "NONE",
            "path": "inbox.messagePreviews"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MessageMenuListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Inbox",
        "kind": "LinkedField",
        "name": "inbox",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "InboxMessagePreviews",
            "kind": "LinkedField",
            "name": "messagePreviews",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InboxMessagePreview",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "messageId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subject",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publishedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "opened",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "messagePreviews(includeOpened:false,page:1,size:3)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "69384aee4b6220799240af56b0560bc4",
    "id": null,
    "metadata": {},
    "name": "MessageMenuListQuery",
    "operationKind": "query",
    "text": "query MessageMenuListQuery {\n  inbox {\n    messagePreviews(size: 3, page: 1, includeOpened: false) {\n      ...MessageList_list\n    }\n  }\n}\n\nfragment MessageListItem_item on InboxMessagePreview {\n  messageId\n  category\n  subject\n  publishedAt\n  opened\n}\n\nfragment MessageList_list on InboxMessagePreviews {\n  items {\n    messageId\n    ...MessageListItem_item\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1515e12be5efd50584da32562e636462";

export default node;
