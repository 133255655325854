import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import type { FC } from 'react';
import { Button, ButtonLink } from '@pafcloud/base-components';
import { FontTextSize, SpeedyColor } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import { useLogout } from '../../../../use-logout';

const FooterMenuButtonLink = styled(ButtonLink)({
  fontSize: FontTextSize.Normal,
});

/**
 * State: Disabled
 * Variant: Secondary
 */
const invertedStyles: CSSObject = {
  '--button-text': SpeedyColor.Neutral,
  '--button-border': SpeedyColor.Neutral,
  '--button-background--hover': `${SpeedyColor.White}33`,
  '--button-border--hover': SpeedyColor.White,
  '--button-border--active': SpeedyColor.White,
};

const FooterMenuButtonLinkInverted = styled(FooterMenuButtonLink)(invertedStyles);

const FooterMenuButtonInverted = styled(Button)({
  ...invertedStyles,
});

const LoggedInButtons: FC = () => {
  const { t } = useTranslation(['header', 'profile', 'common']);
  const { getFlowUrl } = useFlowRouter();
  const logout = useLogout();

  return (
    <>
      <FooterMenuButtonLink
        href={getFlowUrl('deposit')}
        aria-label={t('header-button.logged-in.aria-label')}
        type="button"
        variant="primary"
        size="default"
        title="deposit"
        fullWidth
      >
        {t('header-button.logged-in.text')}
      </FooterMenuButtonLink>

      <FooterMenuButtonLinkInverted
        href={getFlowUrl('withdrawal')}
        aria-label={t('profile:dialog.withdraw-money.aria-label')}
        type="button"
        variant="secondary"
        size="default"
        title="withdraw"
        fullWidth
      >
        {t('profile:dialog.withdraw-money.text')}
      </FooterMenuButtonLinkInverted>

      <FooterMenuButtonInverted
        aria-label={t('common:logout.aria-label')}
        type="button"
        variant="secondary"
        size="default"
        title="logout"
        onClick={() => logout()}
        fullWidth
      >
        {t('common:logout.text')}
      </FooterMenuButtonInverted>
    </>
  );
};

const LoggedOutButtons: FC = () => {
  const { t } = useTranslation('header');
  const { getFlowUrl } = useFlowRouter();

  return (
    <>
      <FooterMenuButtonLink
        href={getFlowUrl('deposit')}
        aria-label={t('header-button.logged-in.aria-label')}
        type="button"
        variant="primary"
        size="default"
        title="play"
        fullWidth
      >
        {t('header-button.logged-in.text')}
      </FooterMenuButtonLink>

      <FooterMenuButtonLinkInverted
        href={getFlowUrl('login')}
        aria-label={t('login-button.aria-label')}
        type="button"
        size="default"
        variant="secondary"
        fullWidth
      >
        {t('login-button.text')}
      </FooterMenuButtonLinkInverted>
    </>
  );
};

export const MainMenuFooterContent: FC = () => {
  const isLoggedIn = useIsLoggedIn();

  return isLoggedIn ? <LoggedInButtons /> : <LoggedOutButtons />;
};
