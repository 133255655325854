import styled from '@emotion/styled';
import { Color, Font, FontHeadingSize, FontTextSize } from '@pafcloud/style';
import { lowercase } from '@pafcloud/util';
import { RichTextStyles } from './rich-text-styles';

type RichTextAlignment = 'LEFT' | 'CENTER' | 'RIGHT';

type RichTextProps = { alignment?: RichTextAlignment };
export const RichText = styled.section<RichTextProps>(({ alignment = 'LEFT' }) => ({
  maxWidth: '80ex',
  marginInline: alignment === 'CENTER' ? 'auto' : undefined,
  marginLeft: alignment === 'RIGHT' ? 'auto' : undefined, // Push the RichText section to the right

  // Add a margin between RichText sections
  '& + &': {
    marginTop: '5em',
  },

  textAlign: lowercase(alignment),
  lineHeight: '1.5',
  color: Color.BodyText,

  'h1, h2, h3, h4, h5, h6': {
    margin: 0,
    marginBottom: '0.25em',

    fontSize: FontTextSize.Normal,
    fontWeight: RichTextStyles.DefaultHeadingWeight,
    fontStyle: RichTextStyles.DefaultHeadingStyle,
    letterSpacing: '-0.01em',
    lineHeight: '1.1',

    // Add margin top to all following headings
    '& ~ *:is(h1, h2, h3, h4, h5, h6)': {
      marginTop: '1.5em',
    },

    '> strong': {
      fontWeight: 900,
    },
  },

  h1: {
    marginBottom: '0.5em',
    fontFamily: Font.HeadingAlt,
    fontSize: FontHeadingSize.Normal,
    fontWeight: RichTextStyles.MainHeadingWeight,
    fontStyle: RichTextStyles.MainHeadingStyle,
  },

  'h2, h3, h4': {
    fontFamily: Font.HeadingAlt,
    fontWeight: RichTextStyles.SubHeadingWeight,
    fontStyle: RichTextStyles.SubHeadingStyle,
  },

  h2: {
    fontSize: FontHeadingSize.Small,
  },

  h3: {
    fontSize: FontHeadingSize.Tiny,
  },

  h4: {
    fontSize: FontTextSize.Huge,
  },

  'h5, h6': {
    fontFamily: Font.Heading,
    fontSize: FontTextSize.Big,
    fontWeight: 'bold',
    lineHeight: '1.5',
  },

  p: {
    margin: 0,

    '& + p': {
      marginTop: '1em',
    },
  },

  hr: {
    margin: '2rem 0',
    height: 1,
    background: `${Color.BodyText}1a`,
    border: 'none',
  },

  a: {
    wordBreak: 'break-word',
    fontWeight: 'bold',
    color: Color.LinkText,
  },

  'ol, ul': {
    paddingLeft: alignment === 'LEFT' ? undefined : 0,
    listStylePosition: alignment === 'LEFT' ? 'outside' : 'inside',
  },

  li: {
    marginBottom: '0.75em',

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
