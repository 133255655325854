import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Style as Style_classic } from './style.classic';
import { Style as Style_clean } from './style.clean';
import { Style as Style_golden } from './style.golden';
import { Style as Style_goldenV2 } from './style.goldenV2';
import { Style as Style_quirky } from './style.quirky';
import { Style as Style_quirky_purple } from './style.quirky-purple';
import { Style as Style_dreams } from './style.dreams';
import { Style as Style_momentum } from './style.momentum';

export const FormInputStyle = (() => {
  if ($buildEnv.theme === 'classic') {
    return Style_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return Style_classic;
  }
  if ($buildEnv.theme === 'clean') {
    return Style_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Style_clean;
  }
  if ($buildEnv.theme === 'quirky') {
    return Style_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return Style_quirky_purple;
  }
  if ($buildEnv.theme === 'golden') {
    return Style_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Style_goldenV2;
  }
  if ($buildEnv.theme === 'dreams') {
    return Style_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return Style_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return Style_momentum;
  }

  console.warn('Style not specified for Form Input!');
  return Style_classic;
})();
