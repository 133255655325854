export type AppName = 'paf' | 'speedy' | 'pml' | 'x3000' | 'oneone';

export type Theme =
  | 'classic'
  | 'classic-red'
  | 'golden'
  | 'goldenV2'
  | 'momentum'
  | 'momentum-neon'
  | 'quirky'
  | 'quirky-purple'
  | 'clean'
  | 'clean-blue'
  | 'dreams';

export const allMarkets = ['estonia', 'finland', 'latvia', 'spain', 'sweden', 'switzerland'] as const;

export type Market = (typeof allMarkets)[number];

export const allSites = [
  'paf.se',
  'paf.ee',
  'paf.com',
  'pafbet.lv',
  'paf.es',

  'speedybet.com',
  'speedybet.es',
  'speedycasino.com',

  'noaccountbet.se',
  'noaccountcasino.se',
  'goldenbull.es',
  'goldenbull.se',
  'casinoepic.se',
  'casinogami.se',
  'lokefreja.se',
  'prankcasino.se',
  'pinatacasino.es',

  'x3000.com',
  'x3000.ee',
  'x3000.lv',

  '11.lv',

  'mycasino.ch',
] as const;

export type Site = (typeof allSites)[number];

export type SiteName =
  | 'PAF'
  | 'SWEDEN'
  | 'SWEDEN_NAC'
  | 'SWEDEN_NAB'
  | 'SWEDEN_PRANK'
  | 'SWEDEN_SPEEDY_BET'
  | 'SWEDEN_SPEEDY_SPEL'
  | 'SWEDEN_SPEEDY_CASINO'
  | 'SWEDEN_GOLDEN_BULL'
  | 'SWEDEN_CASINO_EPIC'
  | 'SWEDEN_CASINO_GAMI'
  | 'SWEDEN_LOKE_FREJA'
  | 'ESTONIA'
  | 'ESTONIA_NAB'
  | 'LATVIA'
  | 'LATVIA_11'
  | 'LATVIA_X3000'
  | 'SPAIN'
  | 'SPAIN_SPEEDY_BET'
  | 'SPAIN_GOLDEN_BULL'
  | 'SPAIN_PINATA_CASINO'
  | 'SWITZERLAND';

export const getMinimumBettingAge = () => {
  return 18;
};

export const getMinimumGameOfChanceAge = (market: Market) => {
  if (market === 'estonia') {
    return 21;
  }
  return 18;
};
