import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { Breakpoint, DynamicValues, ZIndex } from '@pafcloud/style';
import { SidebarColors } from './sidebar-colors';
import type { SidebarProps } from './Sidebar';

const SidebarNav = styled.nav<{ isOpen?: boolean }>(({ isOpen }) => ({
  position: 'fixed',
  width: '100%',
  top: DynamicValues.HeaderHeight,
  bottom: 0,
  left: 0,
  overflowY: 'auto',
  zIndex: ZIndex.MainMenu,
  paddingBottom: 32, // Safari hack to avoid jumpy scroll
  transition: 'transform 0.2s',
  transform: isOpen ? undefined : `translateX(-100%)`,
  backgroundColor: SidebarColors.Background,

  display: 'flex',
  flexDirection: 'column',

  [Breakpoint.LaptopOrLarger]: {
    width: 300,
  },
}));

export const Sidebar = forwardRef<HTMLElement, SidebarProps>(({ isOpen, children }, ref) => {
  return (
    <SidebarNav isOpen={isOpen} ref={ref} data-testid="main-menu">
      {children}
    </SidebarNav>
  );
});

Sidebar.displayName = 'Sidebar';
