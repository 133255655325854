import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { LanguageCode } from '../locale';
import { LanguageLocalesMap as LanguageLocalesMap_default } from './languageLocales.default';
import { LanguageLocalesMap as LanguageLocalesMap_switzerland } from './languageLocales.switzerland';

export const getLanguageLocale = (languageCode: LanguageCode) => {
  if ($buildEnv.site === 'mycasino.ch') {
    return LanguageLocalesMap_switzerland[languageCode];
  }

  return LanguageLocalesMap_default[languageCode];
};
