/**
 * @generated SignedSource<<19a498a6fdfa390cd3541ead0f55b03a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSessionExpiresReminder_data$data = {
  readonly player: {
    readonly session: {
      readonly expiresAt: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "useSessionExpiresReminder_data";
};
export type useSessionExpiresReminder_data$key = {
  readonly " $data"?: useSessionExpiresReminder_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSessionExpiresReminder_data">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSessionExpiresReminder_data"
};

(node as any).hash = "cdb8c219dd9b1bb8e5bd648cddb8a799";

export default node;
