/**
 * @generated SignedSource<<68c5210d0fa043e1d44709c020aac3ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlayerContext_data$data = {
  readonly player: {
    readonly wallet: {
      readonly balance: number;
      readonly bonus: number;
      readonly cash: number;
      readonly currentTurnover: number | null;
      readonly totalTurnover: number | null;
    } | null;
  };
  readonly " $fragmentType": "PlayerContext_data";
} | null;
export type PlayerContext_data$key = {
  readonly " $data"?: PlayerContext_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlayerContext_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlayerContext_data",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Wallet",
            "kind": "LinkedField",
            "name": "wallet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "balance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bonus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cash",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentTurnover",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalTurnover",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "NONE",
      "path": "player"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "889eee847216397c214971d29d9fa558";

export default node;
