import styled from '@emotion/styled';
import type { FC, HTMLProps } from 'react';
import { Color, Font } from '@pafcloud/style';

type Props = Omit<HTMLProps<HTMLParagraphElement>, 'as'>;

const StyledFormErrorText = styled.p<Props>({
  color: Color.ErrorText,
  fontFamily: Font.Body,
  fontWeight: 'bold',
});

export const FormErrorText: FC<Props> = ({ children, ...props }) => {
  return (
    <StyledFormErrorText role="alert" {...props}>
      {children}
    </StyledFormErrorText>
  );
};
