/**
 * @generated SignedSource<<1d11913898e21f4ce377db0330a9ff63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BonusContext_data$data = {
  readonly player: {
    readonly bonus: {
      readonly offers: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"bingoTickets_offer" | "cashbackReward_offer" | "freespins_offer" | "playAndGet_offer" | "turnover_offer">;
      }> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "BonusContext_data";
};
export type BonusContext_data$key = {
  readonly " $data"?: BonusContext_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BonusContext_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expires",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stepId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minBetOdds",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysToComplete",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Game",
  "kind": "LinkedField",
  "name": "game",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v2/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "realAmount",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playAmount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "reward",
  "plural": false,
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useBonusOfferSteps_reward",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tickets",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketsRemaining",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketValue",
              "storageKey": null
            },
            (v5/*: any*/),
            (v6/*: any*/),
            (v8/*: any*/)
          ],
          "type": "BingoTicketsOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bonusAmount",
              "storageKey": null
            }
          ],
          "type": "BonusMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v9/*: any*/),
          "type": "RealMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v9/*: any*/),
          "type": "RefundRealMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v9/*: any*/),
          "type": "CashbackOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxMatchAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "matchPercentage",
              "storageKey": null
            }
          ],
          "type": "MatchDepositBonusMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spins",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spinsRemaining",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spinValue",
              "storageKey": null
            },
            (v5/*: any*/),
            (v6/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monetaryType",
              "storageKey": null
            }
          ],
          "type": "FreespinOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "type": "FreeBetOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/)
          ],
          "type": "RedeemBonusOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "boostedOdds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxExtraWinnings",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxStake",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "outcomeIds",
              "storageKey": null
            },
            (v5/*: any*/),
            (v6/*: any*/),
            (v8/*: any*/)
          ],
          "type": "OddsBoostOfferReward",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "storageKey": null
},
v12 = [
  (v2/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "kind": "InlineDataFragmentSpread",
  "name": "useBonusOfferSteps_offer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "steps",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "turnoverTimes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "turnoverAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TurnoverOfferCategory",
                  "kind": "LinkedField",
                  "name": "turnoverCategories",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "contributionPercentage",
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                (v11/*: any*/)
              ],
              "type": "TurnoverOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v12/*: any*/),
              "type": "ClaimOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v12/*: any*/),
              "type": "ImmediateOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remainingAmount",
                  "storageKey": null
                },
                (v4/*: any*/),
                (v8/*: any*/),
                (v11/*: any*/)
              ],
              "type": "PlayRealMoneyOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "playRounds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remainingRounds",
                  "storageKey": null
                },
                (v4/*: any*/),
                (v8/*: any*/),
                (v11/*: any*/)
              ],
              "type": "PlayRealRoundsOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minDepositAmount",
                  "storageKey": null
                },
                (v11/*: any*/)
              ],
              "type": "DepositOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mustDeposit",
                  "storageKey": null
                },
                (v11/*: any*/)
              ],
              "type": "RecentDepositOfferStep",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Game",
                  "kind": "LinkedField",
                  "name": "game",
                  "plural": false,
                  "selections": [
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                (v11/*: any*/)
              ],
              "type": "SafeBetOfferStep",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "IBonusOffer",
      "abstractKey": "__isIBonusOffer"
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v14 = [
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./BonusContextQuery.graphql')
    }
  },
  "name": "BonusContext_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Player",
      "kind": "LinkedField",
      "name": "player",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Bonuses",
          "kind": "LinkedField",
          "name": "bonus",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BonusOffer",
              "kind": "LinkedField",
              "name": "offers",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "cashbackReward_offer",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v13/*: any*/)
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "bingoTickets_offer",
                  "selections": (v14/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "freespins_offer",
                  "selections": (v14/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "playAndGet_offer",
                  "selections": [
                    (v15/*: any*/),
                    (v13/*: any*/)
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "turnover_offer",
                  "selections": [
                    (v15/*: any*/),
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v13/*: any*/)
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "4719f5bb7a390df677ebff633d36a152";

export default node;
