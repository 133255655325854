/**
 * @generated SignedSource<<6a9e297bb70d59ebc359d9a1d0251588>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BonusMenuItemContent_offer$data = {
  readonly offerId: number;
  readonly " $fragmentSpreads": FragmentRefs<"GeneratedBonusTitle_offer" | "useBonusOfferSteps_offer">;
  readonly " $fragmentType": "BonusMenuItemContent_offer";
};
export type BonusMenuItemContent_offer$key = {
  readonly " $data"?: BonusMenuItemContent_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BonusMenuItemContent_offer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stepId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minBetOdds",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysToComplete",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Game",
  "kind": "LinkedField",
  "name": "game",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "realAmount",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "playAmount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "reward",
  "plural": false,
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useBonusOfferSteps_reward",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tickets",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketsRemaining",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ticketValue",
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/),
            (v7/*: any*/)
          ],
          "type": "BingoTicketsOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bonusAmount",
              "storageKey": null
            }
          ],
          "type": "BonusMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v8/*: any*/),
          "type": "RealMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v8/*: any*/),
          "type": "RefundRealMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v8/*: any*/),
          "type": "CashbackOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxMatchAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "matchPercentage",
              "storageKey": null
            }
          ],
          "type": "MatchDepositBonusMoneyOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spins",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spinsRemaining",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spinValue",
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monetaryType",
              "storageKey": null
            }
          ],
          "type": "FreespinOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "type": "FreeBetOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "type": "RedeemBonusOfferReward",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "boostedOdds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxExtraWinnings",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxStake",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "outcomeIds",
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/),
            (v7/*: any*/)
          ],
          "type": "OddsBoostOfferReward",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "storageKey": null
},
v11 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v10/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BonusMenuItemContent_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offerId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GeneratedBonusTitle_offer"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useBonusOfferSteps_offer",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "steps",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "turnoverTimes",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "turnoverAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TurnoverOfferCategory",
                      "kind": "LinkedField",
                      "name": "turnoverCategories",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "category",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contributionPercentage",
                          "storageKey": null
                        },
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v10/*: any*/)
                  ],
                  "type": "TurnoverOfferStep",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v11/*: any*/),
                  "type": "ClaimOfferStep",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v11/*: any*/),
                  "type": "ImmediateOfferStep",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v9/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "remainingAmount",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v7/*: any*/),
                    (v10/*: any*/)
                  ],
                  "type": "PlayRealMoneyOfferStep",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "playRounds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "remainingRounds",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v7/*: any*/),
                    (v10/*: any*/)
                  ],
                  "type": "PlayRealRoundsOfferStep",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "minDepositAmount",
                      "storageKey": null
                    },
                    (v10/*: any*/)
                  ],
                  "type": "DepositOfferStep",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "mustDeposit",
                      "storageKey": null
                    },
                    (v10/*: any*/)
                  ],
                  "type": "RecentDepositOfferStep",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v9/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Game",
                      "kind": "LinkedField",
                      "name": "game",
                      "plural": false,
                      "selections": [
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v10/*: any*/)
                  ],
                  "type": "SafeBetOfferStep",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "IBonusOffer",
          "abstractKey": "__isIBonusOffer"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "BonusOffer",
  "abstractKey": null
};
})();

(node as any).hash = "b893aabbc0741497672098380eec8a05";

export default node;
