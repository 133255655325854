import { createObjectValueValidator } from '@pafcloud/collection-utils';

export enum CountryCode {
  Aland = 'ax',
  Denmark = 'dk',
  Estonia = 'ee',
  FaroeIslands = 'fo',
  Finland = 'fi',
  France = 'fr',
  Germany = 'de',
  Greenland = 'gl',
  Iceland = 'is',
  Italy = 'it',
  Latvia = 'lv',
  Lithuania = 'lt',
  Norway = 'no',
  Russia = 'ru',
  Spain = 'es',
  Sweden = 'se',
  Switzerland = 'ch',
  UnitedKingdom = 'gb',
}

export enum LanguageCode {
  English = 'en',
  Swedish = 'sv',
  Finnish = 'fi',
  German = 'de',
  French = 'fr',
  Italian = 'it',
  Estonian = 'et',
  Russian = 'ru',
  Latvian = 'lv',
  Spanish = 'es',
}

export type Localized<T> = Record<LanguageCode, T>;

export enum CurrencyCode {
  SEK = 'SEK',
  EUR = 'EUR',
  CHF = 'CHF',
}

const languageCodeToNativeNameMap: Localized<string> = {
  en: 'English',
  sv: 'Svenska',
  fi: 'Suomi',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  et: 'Eesti',
  ru: 'Pусский',
  lv: 'Latviešu',
  es: 'Español',
};

export const getNativeLanguageName = (languageCode: `${LanguageCode}`) => {
  return languageCodeToNativeNameMap[languageCode];
};

export const isCurrencyCode = createObjectValueValidator(CurrencyCode);

export const isLanguageCode = createObjectValueValidator(LanguageCode);

export const isCountryCode = createObjectValueValidator(CountryCode);
