import { sharedStyle } from './sharedStyles';

export const Style = {
  ...sharedStyle,
  BorderRadius: '8px',
  BorderWidthUntouched: '2px',
  TextIndent: '16px',
  FontWeight: 700,
  LabelWeight: 400,
  LabelWeightHeavy: 400,
  NoFloatLabelWeight: 400,
};
