import type { Market, AppName, Site, Theme, SiteName } from '@pafcloud/config';
import { allSites } from '@pafcloud/config';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { createArrayValueValidator, satisfies } from '@pafcloud/collection-utils';
import type { CountryCode, LanguageCode } from './locale';

export type NativeApp = {
  slug: string;
  scheme: string;
};

export type SiteProps = {
  appName: AppName;
  theme: Theme;
  marketId: string;
  site: Site;
  market: Market;
  siteName: SiteName;
  defaultLocale: `${LanguageCode}`;
  locales: `${LanguageCode}`[];
  countryCode: `${CountryCode}`;
  baseUrl: string;
  nativeApps?: NativeApp[];
  kambi?: {
    offering: string;
    sportradarId?: string;
    vaixId: string;
  };
};

const sitesMap = satisfies<Record<Site, SiteProps>>()({
  'paf.com': {
    appName: 'paf',
    theme: 'classic',
    marketId: '1',
    site: 'paf.com',
    market: 'finland',
    siteName: 'PAF',
    defaultLocale: 'fi',
    locales: ['fi', 'sv', 'en'],
    countryCode: 'fi',
    baseUrl: 'https://www.paf.com',
    nativeApps: [{ slug: 'PafComCasino', scheme: 'paf-casino-com://' }],
    kambi: {
      offering: 'paf',
      vaixId: 'paf-pafse',
      sportradarId: 'paf',
    },
  },
  'paf.se': {
    appName: 'paf',
    theme: 'classic',
    marketId: '6',
    site: 'paf.se',
    market: 'sweden',
    siteName: 'SWEDEN',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.paf.se',
    nativeApps: [
      { slug: 'PafSeCasino', scheme: 'paf-casino-se://' },
      { slug: 'PafSeBetting', scheme: 'paf-betting-se://' },
    ],
    kambi: {
      offering: 'pafse',
      vaixId: 'paf-pafse',
      sportradarId: 'paf',
    },
  },
  'paf.ee': {
    appName: 'paf',
    theme: 'classic',
    marketId: '4',
    site: 'paf.ee',
    market: 'estonia',
    siteName: 'ESTONIA',
    defaultLocale: 'et',
    locales: ['et', 'ru', 'en'],
    countryCode: 'ee',
    baseUrl: 'https://www.paf.ee',
    kambi: {
      offering: 'pafee',
      vaixId: 'paf-pafse',
      sportradarId: 'paf',
    },
  },
  'pafbet.lv': {
    appName: 'paf',
    theme: 'classic',
    marketId: '7',
    site: 'pafbet.lv',
    market: 'latvia',
    siteName: 'LATVIA',
    defaultLocale: 'lv',
    locales: ['lv', 'ru', 'en'],
    countryCode: 'lv',
    baseUrl: 'https://www.pafbet.lv',
    kambi: {
      offering: 'paflv',
      vaixId: 'paf-pafse',
      sportradarId: 'paf',
    },
  },
  'paf.es': {
    appName: 'paf',
    theme: 'classic',
    marketId: '3',
    site: 'paf.es',
    market: 'spain',
    siteName: 'SPAIN',
    defaultLocale: 'es',
    locales: ['es', 'en'],
    countryCode: 'es',
    baseUrl: 'https://www.paf.es',
    kambi: {
      offering: 'pafes',
      vaixId: 'paf-pafse',
      sportradarId: 'paf',
    },
  },
  'noaccountcasino.se': {
    appName: 'pml',
    theme: 'golden',
    marketId: '10',
    site: 'noaccountcasino.se',
    market: 'sweden',
    siteName: 'SWEDEN_NAC',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.noaccountcasino.se',
    kambi: {
      offering: 'noacse',
      vaixId: 'noaccountbet',
      sportradarId: 'noaccountbet',
    },
  },
  'goldenbull.es': {
    appName: 'pml',
    theme: 'goldenV2',
    marketId: '18',
    site: 'goldenbull.es',
    market: 'spain',
    siteName: 'SPAIN_GOLDEN_BULL',
    defaultLocale: 'es',
    locales: ['es', 'en'],
    countryCode: 'es',
    baseUrl: 'https://www.goldenbull.es',
    kambi: {
      offering: 'pafgoldenes',
      vaixId: 'noaccountbet',
      // sportradarId: 'noaccountbet', // missing agreement
    },
  },
  'goldenbull.se': {
    appName: 'pml',
    theme: 'goldenV2',
    marketId: '21',
    site: 'goldenbull.se',
    market: 'sweden',
    siteName: 'SWEDEN_GOLDEN_BULL',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.goldenbull.se',
  },
  'casinoepic.se': {
    appName: 'pml',
    theme: 'goldenV2',
    marketId: '22',
    site: 'casinoepic.se',
    market: 'sweden',
    siteName: 'SWEDEN_CASINO_EPIC',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.casinoepic.se',
  },
  'casinogami.se': {
    appName: 'pml',
    theme: 'goldenV2',
    marketId: '23',
    site: 'casinogami.se',
    market: 'sweden',
    siteName: 'SWEDEN_CASINO_GAMI',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.casinogami.se',
  },
  'lokefreja.se': {
    appName: 'pml',
    theme: 'goldenV2',
    marketId: '24',
    site: 'lokefreja.se',
    market: 'sweden',
    siteName: 'SWEDEN_LOKE_FREJA',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.lokefreja.se',
  },
  'noaccountbet.se': {
    appName: 'pml',
    theme: 'golden',
    marketId: '11',
    site: 'noaccountbet.se',
    market: 'sweden',
    siteName: 'SWEDEN_NAB',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.noaccountbet.se',
    kambi: {
      offering: 'nocse',
      vaixId: 'noaccountbet',
      sportradarId: 'noaccountbet',
    },
  },
  'prankcasino.se': {
    appName: 'pml',
    theme: 'quirky',
    marketId: '12',
    site: 'prankcasino.se',
    market: 'sweden',
    siteName: 'SWEDEN_PRANK',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.prankcasino.se',
  },
  'pinatacasino.es': {
    appName: 'pml',
    theme: 'quirky-purple',
    marketId: '19',
    site: 'pinatacasino.es',
    market: 'spain',
    siteName: 'SPAIN_PINATA_CASINO',
    defaultLocale: 'es',
    locales: ['es', 'en'],
    countryCode: 'es',
    baseUrl: 'https://www.pinatacasino.es',
  },
  'speedycasino.com': {
    appName: 'speedy',
    brand: 'sc',
    theme: 'clean',
    marketId: '13',
    site: 'speedycasino.com',
    market: 'sweden',
    siteName: 'SWEDEN_SPEEDY_CASINO',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.speedycasino.com',
  },
  'speedybet.com': {
    appName: 'speedy',
    theme: 'clean-blue',
    marketId: '14',
    site: 'speedybet.com',
    market: 'sweden',
    siteName: 'SWEDEN_SPEEDY_BET',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.speedybet.com',
    kambi: {
      offering: 'speedybetse',
      vaixId: 'speedybet',
      sportradarId: 'speedybet',
    },
  },
  'speedybet.es': {
    appName: 'oneone',
    theme: 'momentum-neon',
    marketId: '20',
    site: 'speedybet.es',
    market: 'spain',
    siteName: 'SPAIN_SPEEDY_BET',
    defaultLocale: 'es',
    locales: ['es', 'en'],
    countryCode: 'es',
    baseUrl: 'https://www.speedybet.es',
    kambi: {
      offering: 'pafspeedybetes',
      vaixId: 'speedybet-es',
      // sportradarId: '', // missing agreement
    },
  },
  'x3000.com': {
    appName: 'x3000',
    theme: 'dreams',
    marketId: '15',
    site: 'x3000.com',
    market: 'sweden',
    siteName: 'SWEDEN_SPEEDY_SPEL',
    defaultLocale: 'sv',
    locales: ['sv', 'en'],
    countryCode: 'se',
    baseUrl: 'https://www.x3000.com',
    kambi: {
      offering: 'speedyspelse',
      vaixId: 'paf-x3000',
      sportradarId: 'x3000',
    },
  },
  'x3000.ee': {
    appName: 'x3000',
    theme: 'dreams',
    marketId: '9',
    site: 'x3000.ee',
    market: 'estonia',
    siteName: 'ESTONIA_NAB',
    defaultLocale: 'et',
    locales: ['et', 'ru', 'en'],
    countryCode: 'ee',
    baseUrl: 'https://www.x3000.ee',
    kambi: {
      offering: 'nocee',
      vaixId: 'paf-x3000',
      sportradarId: 'x3000',
    },
  },
  'x3000.lv': {
    appName: 'x3000',
    theme: 'dreams',
    marketId: '16',
    site: 'x3000.lv',
    market: 'latvia',
    siteName: 'LATVIA_X3000',
    defaultLocale: 'lv',
    locales: ['lv', 'ru', 'en'],
    countryCode: 'lv',
    baseUrl: 'https://www.x3000.lv',
    kambi: {
      offering: 'pafx3lv',
      vaixId: 'paf-x3000',
      sportradarId: 'x3000',
    },
  },
  '11.lv': {
    appName: 'oneone',
    theme: 'momentum',
    marketId: '17',
    site: '11.lv',
    market: 'latvia',
    siteName: 'LATVIA_11',
    defaultLocale: 'lv',
    locales: ['lv', 'ru', 'en'],
    countryCode: 'lv',
    baseUrl: 'https://www.11.lv',
    kambi: {
      offering: 'paf11lv',
      vaixId: 'paf-11lv',
      sportradarId: '11lv',
    },
  },
  'mycasino.ch': {
    appName: 'paf',
    theme: 'classic-red',
    marketId: '8',
    site: 'mycasino.ch',
    market: 'switzerland',
    siteName: 'SWITZERLAND',
    defaultLocale: 'de',
    locales: ['de', 'fr', 'it', 'en'],
    countryCode: 'ch',
    baseUrl: 'https://www.mycasino.ch',
  },
} as const);

export const getSiteProps = (site: Site = $buildEnv.site): SiteProps => {
  const siteProps = sitesMap[site];

  if (typeof siteProps === 'undefined') {
    throw new Error(`Could not get props for site: ${site}`);
  }

  return siteProps;
};

export const allSiteConfigs = Object.values(sitesMap);

export const isSite = createArrayValueValidator(allSites);

export const getCountryCode = (site?: Site) => getSiteProps(site).countryCode;

export const getKambiConfig = (site?: Site) => getSiteProps(site).kambi;
