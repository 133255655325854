import { isPast, parseISO } from 'date-fns';
import { useBonus } from '../BonusContext';

type MatchGameToFreespinsReturnType = {
  __typename: string;
  familyName: string;
  spinsRemaining: number;
} | null;

export const useMatchGameToFreespins = (familyName: string): MatchGameToFreespinsReturnType => {
  const { freespins } = useBonus();

  const matchingOffer = freespins?.[familyName];

  if (matchingOffer?.expiresAt != null && isPast(parseISO(matchingOffer.expiresAt))) {
    return null;
  }

  if (matchingOffer?.spinsRemaining && matchingOffer.game?.familyName) {
    return {
      __typename: matchingOffer.__typename,
      familyName: matchingOffer.game.familyName,
      spinsRemaining: matchingOffer.spinsRemaining,
    };
  }

  return null;
};
