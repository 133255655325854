import type { FC } from 'react';
import { rememberGameHomeUrl } from '@pafcloud/react-hook-utils';
import { searchResultStyles } from './search-result-styles/searchResultStyles';
import { useRecentSearch } from './useRecentSearch';
import type { SearchResultChildrenProps } from './SearchResultChildren';

const { ResultImage, ResultLink, ResultSupplier, ResultTitle } = searchResultStyles;

export const SearchResultChildren: FC<SearchResultChildrenProps> = ({ gameListId, onClickResult, searchResult }) => {
  const { insertRecentSearchResult } = useRecentSearch(gameListId);

  return (
    <ResultLink
      onClick={(event) => {
        insertRecentSearchResult(searchResult);
        rememberGameHomeUrl();
        onClickResult?.();
        event.currentTarget.blur();
      }}
      href={searchResult.launchUrl}
    >
      <ResultImage
        src={searchResult.thumbnail.src}
        width={searchResult.thumbnail.width}
        height={searchResult.thumbnail.height}
      />
      <ResultTitle dangerouslySetInnerHTML={{ __html: searchResult.title }} />
      <ResultSupplier dangerouslySetInnerHTML={{ __html: searchResult.supplier }} />
    </ResultLink>
  );
};
