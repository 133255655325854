/**
 * @generated SignedSource<<d39b10649cfa4de669ca0a54765bb568>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InboxMessageSubject = "MESSAGE" | "PAYMENT" | "PROMOTION" | "RADAR" | "RADAR_INBOX" | "RADAR_POPUP" | "WINNER";
import { FragmentRefs } from "relay-runtime";
export type LiveMessageNotifications_messages$data = {
  readonly items: ReadonlyArray<{
    readonly category: InboxMessageSubject;
    readonly publishedAt: string;
    readonly " $fragmentSpreads": FragmentRefs<"MessageListItem_item">;
  } | null>;
  readonly " $fragmentType": "LiveMessageNotifications_messages";
};
export type LiveMessageNotifications_messages$key = {
  readonly " $data"?: LiveMessageNotifications_messages$data;
  readonly " $fragmentSpreads": FragmentRefs<"LiveMessageNotifications_messages">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveMessageNotifications_messages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InboxMessagePreview",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedAt",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MessageListItem_item"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InboxMessagePreviews",
  "abstractKey": null
};

(node as any).hash = "9b88259d3d3379486c1dbd8b78f7d219";

export default node;
