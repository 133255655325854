import type { ComponentProps } from 'react';
import styled from '@emotion/styled';

export const ModalBackgroundOverlay = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
});

export type ModalBackgroundOverlayProps = ComponentProps<typeof ModalBackgroundOverlay>;
