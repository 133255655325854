import type { FC } from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import FocusTrap from 'focus-trap-react';
import { Global } from '@emotion/react';
import {
  asButton,
  Icon,
  renderIntoRootContainer,
  SearchBackgroundOverlay,
  SearchContent,
  SearchField,
  SearchHeader,
  DefaultSearchFieldWrapper,
  ResetButton,
} from '@pafcloud/base-components';
import { SearchResult } from './SearchResult';
import { RecentSearchResults } from './RecentSearchResults';
import { useSearch } from './useSearch';
import { dispatchSearchResultClickedEvent } from './tracking/dispatchSearchResultTrackingEvent';
import { dispatchSearchStartedEvent } from './tracking/dispatchSearchStartedEvent';
import { useRecentSearch } from './useRecentSearch';

const ArrowIcon = styled(asButton(Icon))({
  cursor: 'pointer',
});

type SearchOverlayProps = {
  gameListId: string;
  searchIsOpen?: boolean;
  className?: string;
};

export const SearchOverlay: FC<SearchOverlayProps> = ({ gameListId, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { search, isLoading, reset, results, value } = useSearch(gameListId);
  // This needs to be called when the overlay is opened in order to populate the recent search list
  useRecentSearch(gameListId);

  const onClickResult = () => {
    // We need this to have time to save the search result before React
    // unmounts this whole component
    setTimeout(() => {
      setIsOpen(false);
    }, 0);
  };

  if (isOpen) {
    return renderIntoRootContainer(
      <SearchBackgroundOverlay>
        <Global styles={{ body: { overflow: 'hidden !important' } }} />
        <FocusTrap
          focusTrapOptions={{
            escapeDeactivates: true,
            allowOutsideClick: true,
          }}
        >
          <SearchContent>
            <SearchHeader>
              <ArrowIcon
                name="arrowLeftFat"
                onClick={() => {
                  setIsOpen(false);
                  reset();
                }}
              />
              <SearchField autoFocus onChange={search} value={value} reset={reset} />

              {value && (
                <ResetButton onClick={() => reset()}>
                  <Icon name="closeThinRounded" />
                </ResetButton>
              )}
              {!value && <Icon name="search" />}
            </SearchHeader>
            {value ? (
              <SearchResult
                isLoading={isLoading}
                searchResults={results}
                gameListId={gameListId}
                onClickResult={(searchResult) => {
                  onClickResult();
                  dispatchSearchResultClickedEvent({
                    ...searchResult,
                    term: value,
                  });
                }}
              />
            ) : (
              <RecentSearchResults
                gameListId={gameListId}
                onClickResult={(searchResult) => {
                  onClickResult();
                  dispatchSearchResultClickedEvent({
                    ...searchResult,
                    term: '[recent searches]',
                  });
                }}
              />
            )}
          </SearchContent>
        </FocusTrap>
      </SearchBackgroundOverlay>,
    );
  }

  return (
    <DefaultSearchFieldWrapper>
      <SearchField
        className={className}
        onFocus={() => {
          setIsOpen(true);
          dispatchSearchStartedEvent();
        }}
        value={value}
        reset={reset}
      />
      <Icon name="search" />
    </DefaultSearchFieldWrapper>
  );
};
