import { SpeedyColor } from '@pafcloud/style';

export const Colors = {
  Text: SpeedyColor.White,
  DepositQuickButtons: undefined,
  DepositQuickButtonsHover: undefined,
  Border: undefined,
  Background: SpeedyColor.DarkerBlue,
  SidebarContent: undefined,
  SidebarTopBackground: undefined,
  SidebarOverlay: SpeedyColor.LightTransparent50,
  EmphasizedLink: undefined,
};
