import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_classic, FontStyle as FontStyle_classic } from './colors.classic';
import { Colors as Colors_classic_red, FontStyle as FontStyle_classic_red } from './colors.classic-red';
import { Colors as Colors_clean, FontStyle as FontStyle_clean } from './colors.clean';
import { Colors as Colors_golden, FontStyle as FontStyle_golden } from './colors.golden';
import { Colors as Colors_prank, FontStyle as FontStyle_prank } from './colors.quirky';
import { Colors as Colors_pinata, FontStyle as FontStyle_pinata } from './colors.quirky-purple';
import { Colors as Colors_dreams, FontStyle as FontStyle_dreams } from './colors.dreams';
import { Colors as Colors_momentum_neon, FontStyle as FontStyle_momentum_neon } from './colors.momentum-neon';
import { Colors as Colors_momentum, FontStyle as FontStyle_momentum } from './colors.momentum';

export const MainMenuColors = (() => {
  if ($buildEnv.theme === 'classic') {
    return Colors_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return Colors_classic_red;
  }
  if ($buildEnv.theme === 'clean') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'golden') {
    return Colors_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return Colors_golden;
  }
  if ($buildEnv.theme === 'quirky') {
    return Colors_prank;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return Colors_pinata;
  }
  if ($buildEnv.theme === 'dreams') {
    return Colors_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return Colors_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return Colors_momentum_neon;
  }

  console.warn('Colors not specified for main-menu-colors!');
  return Colors_classic;
})();

export const MainMenuFontStyles = (() => {
  if ($buildEnv.theme === 'classic') {
    return FontStyle_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return FontStyle_classic_red;
  }
  if ($buildEnv.theme === 'clean') {
    return FontStyle_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return FontStyle_clean;
  }
  if ($buildEnv.theme === 'golden') {
    return FontStyle_golden;
  }
  if ($buildEnv.theme === 'goldenV2') {
    return FontStyle_golden;
  }
  if ($buildEnv.theme === 'quirky') {
    return FontStyle_prank;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return FontStyle_pinata;
  }
  if ($buildEnv.theme === 'dreams') {
    return FontStyle_dreams;
  }
  if ($buildEnv.theme === 'momentum') {
    return FontStyle_momentum;
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return FontStyle_momentum_neon;
  }

  console.warn('Colors not specified for main-menu-colors!');
  return FontStyle_classic;
})();
