import type { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import styled from '@emotion/styled';
import { Color } from '@pafcloud/style';
import { Link } from '../link';

const MarkdownLink = styled(Link)({
  fontWeight: 'bold',
  color: Color.LinkText,
});

type Props = {
  children: string;
  components?: object;
  className?: string;
};

type Element = {
  type?: string;
  value?: string;
};

type LinkParser = {
  node: {
    children: Element[];
  };
  href?: string;
};

export const MarkdownParser: FC<Props> = ({ children, components, className }) => {
  return (
    <ReactMarkdown
      className={className}
      components={{
        a({ node, href }: LinkParser) {
          if (!href) {
            return null;
          }
          const nodeValue = node.children[0].value;
          return <MarkdownLink href={href}>{nodeValue}</MarkdownLink>;
        },
        ...components,
      }}
      plugins={[gfm]}
    >
      {children}
    </ReactMarkdown>
  );
};
