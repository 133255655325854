import type { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Color, Font, FontTextSize } from '@pafcloud/style';
import { Icon, InternalLink, NotificationBadge } from '@pafcloud/base-components';
import { ProfileColors } from '../profile-colors';

const NavList = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: 0,
  listStyle: 'none',
  borderTop: ProfileColors.Border,
});

export const NavItem = styled.li({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderBottom: ProfileColors.Border,

  'ul > &': {
    fontWeight: 'bold',
  },
});

export const NavItemContent = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '100%',
  margin: 0,
  padding: 16,
  background: 'transparent',
  border: 0,
  cursor: 'pointer',
  fontSize: FontTextSize.Big,
  fontFamily: Font.Body,
  color: Color.BodyText,

  ':hover': {
    color: Color.LinkText,
  },

  ':focus, :active': {
    outline: 'none',
    color: Color.LinkText,
    boxShadow: `0 0 0 1px ${Color.LinkText}`,
  },
});

export const NavItemLink = styled(NavItemContent)({}).withComponent(InternalLink);

export const NavItemIcon = styled(Icon)({
  marginLeft: 'auto',
});

export const NavNotificationBadge = styled(NotificationBadge)({
  marginRight: 4,
});

export const SubNavList = styled(NavList)({
  border: 0,

  [`& > ${NavItemLink}`]: {
    fontWeight: 'normal',
    padding: '8px 16px',
  },
});

type Props = {
  children: ReactNode;
  className?: string;
};

export const ProfileDialogNav: FC<Props> = ({ children, className }) => {
  return (
    <nav className={className}>
      <NavList>{children}</NavList>
    </nav>
  );
};
