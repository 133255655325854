import type { FC, HTMLProps, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Color, Font, FontTextSize } from '@pafcloud/style';

const Sausage = styled.span({
  padding: '3px 14px',
  fontFamily: Font.Tiny,
  fontSize: FontTextSize.Small,
  fontWeight: 600,
  borderRadius: 12,
  background: Color.Surface.Nested.Background,
  color: Color.HighlightedText,
  boxShadow: Color.Elevation.Level1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
});

type IconType = {
  src: string;
};

const Icon = styled.span<IconType>(({ src }) => ({
  height: 12,
  width: 12,
  background: `url(${src})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  display: 'inline-block',
  marginRight: 3,
}));

type Props = HTMLProps<HTMLLabelElement> & {
  text: string;
  icon?: string;
  className?: string;
  children?: ReactNode;
};

export const InfoSausage: FC<Props> = ({ text, icon, className, children }) => (
  <Sausage className={className}>
    {icon && <Icon src={icon} />}
    {children}
    {text}
  </Sausage>
);
