import type { ComponentType } from 'react';
import { useEffect, useState } from 'react';
import type { AppContext, AppProps } from 'next/app';
import { fetchQuery, useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks';
import type { OperationType, Variables } from 'relay-runtime';
import type { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { cookies, HAS_SESSION } from '@pafcloud/cookies';
import { logger } from '@pafcloud/logging';
import { ConfigProvider } from '@pafcloud/contexts';
import { getClientConfig } from '@pafcloud/config';
import { useHandler } from '@pafcloud/react-hook-utils';
import { getI18n, getInitiatedI18n, I18nextProvider, initClientTranslations, namespaces } from '@pafcloud/i18n';
import { $buildEnv } from '@pafcloud/config/buildEnv';
import { initRelayEnvironment } from './initRelayEnvironment';
import type { AppWithQueryData, WithPage } from './PageWithData';
import { HydratedRelayEnvironmentProvider } from './HydratedRelayEnvironmentProvider';
import { getAllowedSSRCookie } from './getCookie';

type RelayProps = {
  queryArguments: Variables;
  records?: RecordMap;
  statusCode: number;
};

type TranslationProps = {
  translations: Record<string, string>;
};

type InitialProps = {
  initialProps: Record<string, unknown>;
};

type RelayDataProvider = ComponentType<WithPage<AppProps> & Omit<RelayProps, 'records' | 'statusCode'> & InitialProps>;

type WithDataComponent = ComponentType<WithPage<AppProps> & RelayProps & TranslationProps & InitialProps> & {
  getInitialProps(context: WithPage<AppContext>): Promise<RelayProps & TranslationProps & InitialProps>;
};

type WithDataOptions = {
  ErrorPage: ComponentType;
  NotFoundPage: ComponentType;
};

const isServer = typeof window === 'undefined';

export const withData = <T extends OperationType>(AppComponent: AppWithQueryData<T>, options: WithDataOptions) => {
  const RelayDataProvider: RelayDataProvider = ({ Component, queryArguments, initialProps }) => {
    const { query } = Component;

    const environment = useRelayEnvironment();

    // Since SSR was anonymous we need to refetch all data on client to get the user specific data.
    const [isMissingClientData, setIsMissingClientData] = useState(true);

    const refetchQuery = useHandler(async () => {
      const couldBeLoggedIn = cookies.get(HAS_SESSION);

      if (couldBeLoggedIn) {
        await fetchQuery<T>(environment, query, queryArguments).toPromise();
      }

      setIsMissingClientData(false);
    });

    useEffect(() => {
      void refetchQuery();
    }, [refetchQuery]);

    // Since we hydrate the relay store with the data from getIntialProps,
    // we can use store-only here to extract the data relevant for the query.
    const queryData = useLazyLoadQuery<T>(query, queryArguments, {
      fetchPolicy: 'store-only',
    });

    return (
      <AppComponent queryData={queryData} isLoadingClientData={isMissingClientData}>
        <Component pageData={queryData} {...initialProps} />
      </AppComponent>
    );
  };

  const WithDataComponent: WithDataComponent = ({ records, statusCode, ...props }) => {
    if (!isServer) {
      initClientTranslations($buildEnv.site, props.translations);
    }

    const i18n = getI18n($buildEnv.site, props.router.locale);

    if (statusCode === 404) {
      return (
        <I18nextProvider i18n={i18n}>
          <ConfigProvider config={getClientConfig()}>
            <options.NotFoundPage />
          </ConfigProvider>
        </I18nextProvider>
      );
    }

    if (records == null || statusCode === 500) {
      return (
        <I18nextProvider i18n={i18n}>
          <ConfigProvider config={getClientConfig()}>
            <options.ErrorPage />
          </ConfigProvider>
        </I18nextProvider>
      );
    }

    return (
      <I18nextProvider i18n={i18n}>
        <HydratedRelayEnvironmentProvider records={records}>
          <RelayDataProvider {...props} />
        </HydratedRelayEnvironmentProvider>
      </I18nextProvider>
    );
  };

  WithDataComponent.getInitialProps = async ({ ctx, router, Component: Page }) => {
    const initialPageProps = await Page.getInitialProps?.(ctx);

    const { queryArguments = {}, postQuery, ...initialProps } = initialPageProps ?? {};

    const translations: Record<string, string> = {};

    if (isServer) {
      const i18n = await getInitiatedI18n($buildEnv.site, router.locale);

      // Pass translations to the client - we are currently unable to only pass relevant namespaces.
      namespaces.forEach((namespace) => {
        translations[namespace] = i18n.getResourceBundle(i18n.language, namespace);
      });
    }

    // In some cases, this might not be a page we control.
    // If so, we want to return early and not try to fetch any data.
    if (typeof Page.query === 'undefined') {
      return {
        initialProps,
        queryArguments,
        statusCode: ctx.res?.statusCode ?? 200,
        translations,
      };
    }

    // This must know nothing about the client.
    // Don't pass in any other headers, cookies, or anything here that is specific to the player.
    const environment = initRelayEnvironment({
      headers: {
        cookie: getAllowedSSRCookie(ctx.req),
      },
    });

    const appQuery = fetchQuery<T>(environment, Page.query, queryArguments, {
      networkCacheConfig: {
        metadata: {
          language: router.locale ?? router.defaultLocale,
        },
      },
    });

    return new Promise((resolve) => {
      appQuery.subscribe({
        next: postQuery,
        error(reason: unknown) {
          logger.error('Could not load app query', { error: reason });
          if (ctx.res) {
            ctx.res.statusCode = 500;
          }

          resolve({
            initialProps,
            queryArguments,
            statusCode: 500,
            translations,
          });
        },
        complete() {
          const records = environment.getStore().getSource().toJSON();

          resolve({
            initialProps,
            queryArguments,
            records,
            statusCode: ctx.res?.statusCode ?? 200,
            translations,
          });
        },
      });
    });
  };

  return WithDataComponent;
};
