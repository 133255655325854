/**
 * This is to simulate the `satisfies` keyword.
 *
 * Used as `satisfies<Type>({...})` is equivalent to `{} satisfies Type`.
 */
export function satisfies<T>() {
  return <U extends T>(value: U) => {
    return value;
  };
}
