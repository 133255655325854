import type { FieldValues } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@pafcloud/i18n';
import { phoneNumberPrefixes } from '@pafcloud/locale';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { HookFormInput } from '../hook-form-inputs/HookFormInput';
import { DropDownSelect } from '../drop-down-select';
import type { PhoneInputProps } from './PhoneInput';
import { getStrictPhoneValidationPattern } from './getPhoneValidationPattern';

const allowedPrefixes = Object.fromEntries(
  Object.entries(phoneNumberPrefixes).filter(([_market, currentPrefix]) => ![34, 41].includes(currentPrefix)),
);

const createPrefixLabel = (prefix: number) => {
  return '+' + prefix.toString();
};

const modifyValue = (initialValue: string, prefix: string) => {
  const value = initialValue.trim();

  if (!value) {
    return value;
  }

  // These are handled in normalizePhoneNumber.ts
  if (['+', '00', '0'].some((base) => value.startsWith(base))) {
    return value;
  }

  // Strip + from prefix for check
  if (value.startsWith(prefix.slice(1))) {
    return '+' + value;
  }

  return createPrefixLabel(+prefix) + value;
};

const mapPrefixToTranslationKey = (prefix: string) => {
  switch (prefix) {
    case '+45':
      return 'phone-number.prefix.country-code.denmark';
    case '+46':
      return 'phone-number.prefix.country-code.sweden';
    case '+47':
      return 'phone-number.prefix.country-code.norway';
    case '+298':
      return 'phone-number.prefix.country-code.faroe-islands';
    case '+299':
      return 'phone-number.prefix.country-code.greenland';
    case '+354':
      return 'phone-number.prefix.country-code.iceland';
    case '+358':
      return 'phone-number.prefix.country-code.finland';
    case '+370':
      return 'phone-number.prefix.country-code.lithuania';
    case '+371':
      return 'phone-number.prefix.country-code.latvia';
    case '+372':
      return 'phone-number.prefix.country-code.estonia';
    default:
      throw new Error('Unsupported phone number prefix.');
  }
};

export const PhoneInput = <TValues extends FieldValues>({
  name,
  validate,
  register,
  initialPrefix,
  showPrefixDropdown = true,
  children,
  ...props
}: PhoneInputProps<TValues>) => {
  const { t } = useTranslation('form');
  const [state, setState] = useState(
    initialPrefix
      ? {
          prefix: createPrefixLabel(+initialPrefix),
          validationPattern: getStrictPhoneValidationPattern(+initialPrefix),
        }
      : {
          prefix: createPrefixLabel(allowedPrefixes[$buildEnv.market]),
          validationPattern: getStrictPhoneValidationPattern(allowedPrefixes[$buildEnv.market]),
        },
  );
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref } = register(name);

  const showHints = +state.prefix === allowedPrefixes[$buildEnv.market];

  useEffect(() => {
    const input = inputRef.current;
    if (input != null) {
      const initialPhoneNumberPrefix = Object.values(allowedPrefixes).find((prefix) =>
        input.value.startsWith('+' + prefix),
      );

      if (initialPhoneNumberPrefix != null) {
        setState({
          prefix: createPrefixLabel(initialPhoneNumberPrefix),
          validationPattern: getStrictPhoneValidationPattern(initialPhoneNumberPrefix),
        });
      }
    }
  }, [name, register]);

  return (
    <>
      {showPrefixDropdown && (
        <DropDownSelect
          floatingLabel={false}
          label={`${t(mapPrefixToTranslationKey(state.prefix))} (${state.prefix})`}
          options={Object.values(allowedPrefixes)
            .sort((previous, next) => (previous < next ? -1 : 0))
            .map((value) => ({
              label: `${t(mapPrefixToTranslationKey(createPrefixLabel(value)))} (${createPrefixLabel(value)})`,
              value: value.toString(),
            }))}
          onChange={(value) =>
            setState({
              prefix: createPrefixLabel(+value),
              validationPattern: getStrictPhoneValidationPattern(+value),
            })
          }
        />
      )}
      <HookFormInput
        label={t('phone-number.input.label')}
        info={showHints ? t('phone-number.input.format-hint') : undefined}
        {...props}
        type="tel"
        {...register(name, {
          pattern: {
            value: state.validationPattern,
            message: showHints ? t('phone-number.input.invalid-format') : '',
          },
          required: props['aria-required'] && t('phone-number.input.required'),
          validate,
          setValueAs: (value) => modifyValue(value, state.prefix),
        })}
        ref={(instance) => {
          ref(instance);
          inputRef.current = instance;
        }}
      >
        {children}
      </HookFormInput>
    </>
  );
};
