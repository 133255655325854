import styled from '@emotion/styled';
import type { ReactElement } from 'react';
import { Color, FontTextSize, getBrandBorder } from '@pafcloud/style';
import { Icon } from '@pafcloud/base-components';
import { FormInputProperties, FormInputZIndex } from '../form-input';
import type { DropDownSelectOpenDirection } from './DropDownSelect';

export type SelectOption = {
  value: string;
  label: ReactElement | string;
};

export const Wrapper = styled.div({
  '--drop-down-height': '56px',

  fontSize: FontTextSize.Big,
  width: '100%',
  position: 'relative',
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
});

export const DropDownListContainer = styled.div<{ openDirection?: DropDownSelectOpenDirection }>(
  {
    position: 'absolute',
    width: '100%',
    // The list container appears behind other dropdown without zIndex
    zIndex: FormInputZIndex.Dropdown,
    top: 'calc(100% + 4px)',

    // Add extra space below dropdown when reaching end of page
    '::after': {
      content: '""',
      display: 'block',
      marginBottom: 64,
    },
  },
  ({ openDirection }) => {
    if (openDirection === 'up') {
      return {
        top: 0,
        transform: 'translate(0px, calc(-100% + var(--drop-down-height)))',
      };
    }

    return null;
  },
);

export const DropdownContent = styled.div({
  maxHeight: 350,
  overflow: 'auto',
  ...getBrandBorder(),
  borderRadius: 3,
  boxShadow: Color.Elevation.Level2,
  fontSize: FontTextSize.Normal,
  outline: 'none',
});

export const DropDownIcon = styled(Icon)({
  display: 'flex',
  justifySelf: 'flex-end',
  gridArea: '1 / -1',
  pointerEvents: 'none',

  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  marginRight: `calc(var(${FormInputProperties.TextIndent}) / 2)`,
  paddingLeft: `calc(var(${FormInputProperties.TextIndent}) / 2)`,
  color: 'currentColor',

  // Ends up under input on PML sites without this.
  zIndex: 1,
  svg: {
    width: 24,
    height: 24,
    transform: 'rotate(90deg)',
  },
});
