import type { FC } from 'react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFragment, graphql } from 'react-relay/hooks';

import { isLanguageCode, getCountryCode, getLanguage } from '@pafcloud/locale';
import { capitalize } from '@pafcloud/util';
import { initializeTracking, prepareTrackingInfo } from '@pafcloud/tracking';
import { useConfig, useCookieConsent } from '@pafcloud/contexts';

import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { Tracking_data$key } from './__generated__/Tracking_data.graphql';
import { getSiteLayout } from './getSiteLayout';
import { useLoginTrackingEvent } from './useLoginTrackingEvent';
import { usePageviewEvent } from './usePageviewEvent';

const dataFragment = graphql`
  fragment Tracking_data on Query {
    player {
      id
    }
  }
`;

type Props = {
  data: Tracking_data$key;
};

const TrackingComponent: FC<Props> = ({ data }) => {
  const { player } = useFragment(dataFragment, data);
  const config = useConfig();
  const router = useRouter();

  useEffect(() => {
    prepareTrackingInfo('site', {
      brand: config.pafSiteName,
      name: capitalize($buildEnv.market),
      environment: config.env,
      ipCountry: getCountryCode().toUpperCase(),
      layout: getSiteLayout(),
      locale: isLanguageCode(router.locale) ? getLanguage(router.locale) : '',
      version: '2',
    });

    prepareTrackingInfo('page', {
      type: router.pathname,
    });

    if (player == null) {
      prepareTrackingInfo('user', {
        user: {
          category: 'prospect',
          loggedIn: 'no',
        },
      });
    }

    initializeTracking(config.gtmId);
    // We really only want to run this once, when the page loads the first time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePageviewEvent();
  useLoginTrackingEvent();

  return null;
};

export const Tracking: FC<Props> = (props) => {
  const cookieConsent = useCookieConsent();

  if (cookieConsent?.essential) {
    return <TrackingComponent {...props} />;
  }

  return null;
};
