import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useConfig } from '@pafcloud/contexts';
import { useAsync } from '@pafcloud/react-hook-utils';
import type { SearchResult } from './Search';
import { createSearchWorker } from './create-search-worker';

type SearchState = {
  search: (searchValue?: string) => void;
  isSearching: boolean;
  results: SearchResult[];
  total?: number;
};

export function useSearchState(gameListId: string): SearchState {
  const { locale } = useRouter();
  const [results, setResults] = useState<SearchResult[]>([]);
  const [total, setTotal] = useState<number | undefined>();
  const [isSearching, setIsSearching] = useState(false);
  const { enableOpenSearch } = useConfig();

  const [{ data: worker, ...state }, initiateWorker] = useAsync({
    onStart: (value?: string) => {
      const searchWorker = createSearchWorker();

      searchWorker.onmessage = (event) => {
        if (event.data.type === 'result') {
          setResults(event.data.result);
          setTotal(event.data.total);
        }

        setIsSearching(false);
      };

      searchWorker.postMessage({ value, gameListId, locale, enableOpenSearch });

      return Promise.resolve(searchWorker);
    },
  });

  useEffect(() => {
    return () => {
      worker?.terminate();
    };
  }, [worker]);

  const search = (value?: string) => {
    setIsSearching(true);
    if (worker) {
      worker.postMessage({ value, gameListId, locale, enableOpenSearch });
    } else if (state.status === 'idle') {
      initiateWorker(value);
    }
  };

  return { search, isSearching, results, total };
}
