import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_classic } from './colors.classic';
import { Colors as Colors_clean } from './colors.clean';
import { Colors as Colors_quirky } from './colors.quirky';
import { Colors as Colors_quirky_purple } from './colors.quirky-purple';

export const LanguageSelectColors = (() => {
  if ($buildEnv.theme === 'classic') {
    return Colors_classic;
  }
  if ($buildEnv.theme === 'classic-red') {
    return Colors_classic;
  }
  if ($buildEnv.theme === 'clean') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'clean-blue') {
    return Colors_clean;
  }
  if ($buildEnv.theme === 'quirky') {
    return Colors_quirky;
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return Colors_quirky_purple;
  }

  return Colors_default;
})();
