/**
 * @generated SignedSource<<5bd5bd95ac3f72e51c18becf1ed7767c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeatureFlagContext_data$data = {
  readonly site: {
    readonly featureFlags: ReadonlyArray<{
      readonly key: string;
    } | null>;
  };
  readonly " $fragmentType": "FeatureFlagContext_data";
};
export type FeatureFlagContext_data$key = {
  readonly " $data"?: FeatureFlagContext_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeatureFlagContext_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeatureFlagContext_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeatureFlag",
          "kind": "LinkedField",
          "name": "featureFlags",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7063b021f69e9ebb0a403518ea0518ba";

export default node;
