import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { Breakpoint, Color } from '@pafcloud/style';
import { Image } from '../image/Image';
import { Card } from '../card';
import { InternalLink } from '../link';
import { visuallyHiddenStyle } from '../visually-hidden-heading';

const CardWrapper = styled(Card)({
  position: 'relative',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  gridTemplateColumns: '2fr',
  alignContent: 'stretch',
  justifyContent: 'stretch',
  justifyItems: 'stretch',
  alignItems: 'stretch',
  width: '100%',

  [Breakpoint.BigScreenOrLarger]: {
    gridTemplateRows: '2fr',
    gridTemplateColumns: '1fr 1fr',
  },
});

const CardImageContainer = styled.div({
  position: 'relative',
  pointerEvents: 'none',
  minHeight: 128,

  [Breakpoint.BigScreenOrLarger]: { minHeight: 300 },
});

const CardImage = styled(Image)({
  objectFit: 'cover',
});

export const CardBody = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  width: '100%',
  minHeight: 120,
  padding: 16,
  pointerEvents: 'none',
  color: Color.BodyText,

  '> *': {
    pointerEvents: 'auto',
  },

  [Breakpoint.LaptopOrLarger]: {
    padding: 32,
  },
});

const CardLink = styled(InternalLink)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const VisuallyHiddenText = styled.span(visuallyHiddenStyle);

export const VerticalCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '100%',
  overflow: 'hidden',
});

export const CardMedia = styled.div({
  width: '100%',
  position: 'relative',
  flexShrink: 1,
  padding: 16,
  aspectRatio: '16/9',
  display: 'flex',
  justifyContent: 'center',

  // iOS 14 does not support aspect-ratio
  '::before': {
    content: '""',
    width: 1,
    marginLeft: -1,
    float: 'left',
    height: 0,
    paddingTop: '56.25%',
  },
  '::after': {
    content: '""',
    display: 'table',
    clear: 'both',
  },
});

type CardProps = {
  backgroundUrl?: string;
  href?: string;
  scroll?: boolean;
  linkText?: string | null;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
};

export const GameCard: FC<CardProps> = ({
  backgroundUrl,
  href,
  children,
  scroll = false,
  linkText,
  className,
  onClick,
}) => {
  return (
    <CardWrapper data-name="card" className={className}>
      {href && (
        <CardLink href={href} scroll={scroll} onClick={onClick}>
          <VisuallyHiddenText>{linkText}</VisuallyHiddenText>
        </CardLink>
      )}
      {backgroundUrl ? (
        <CardImageContainer>
          <CardImage
            src={backgroundUrl}
            fill
            sizes="
              (max-width: 500px) 95vw,
              (max-width: 1366px) 75vw,
              33vw"
          />
        </CardImageContainer>
      ) : null}
      {children}
    </CardWrapper>
  );
};
