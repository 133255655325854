/**
 * @generated SignedSource<<51408128ac223848503a8edf56dd4a7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SourceOfFundsStatus = "COMPLETED" | "IN_PROGRESS" | "NOT_REQUIRED" | "REJECTED" | "REQUIRED";
import { FragmentRefs } from "relay-runtime";
export type SourceOfFundsReminder_data$data = {
  readonly reminderSentAt: string | null;
  readonly status: SourceOfFundsStatus | null;
  readonly " $fragmentType": "SourceOfFundsReminder_data";
};
export type SourceOfFundsReminder_data$key = {
  readonly " $data"?: SourceOfFundsReminder_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SourceOfFundsReminder_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SourceOfFundsReminder_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reminderSentAt",
      "storageKey": null
    }
  ],
  "type": "SourceOfFunds",
  "abstractKey": null
};

(node as any).hash = "789c2f96855ecd2113c6b24326913de8";

export default node;
