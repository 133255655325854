import styled from '@emotion/styled';

export type NotificationBadgeSeverity = 'low' | 'medium' | 'high';

type NotificationBadgeBaseProps = {
  severity: NotificationBadgeSeverity;
};

export const NotificationBadgeBase = styled.div<NotificationBadgeBaseProps>({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  minWidth: 'var(--badge-size, 19px)',
  height: 'var(--badge-size, 19px)',
  padding: 4,
  borderRadius: '50vw',

  // Using Arial for small text to avoid alignment problems with custom fonts
  fontFamily: 'Arial',
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  lineHeight: 1,
});

NotificationBadgeBase.defaultProps = {
  role: 'status',
};
