import type { ComponentProps, FC, KeyboardEventHandler } from 'react';
import styled from '@emotion/styled';
import { ToggleSwitchColors } from './toggle-switch-colors';

const Switch = styled.label({
  display: 'inline-flex',
});

const SwitchTrack = styled.span<{ checked: boolean }>(({ checked }) => ({
  '--switch-size': '1.75rem',
  '--switch-width': '4rem',
  display: 'inline-block',
  alignItems: 'center',
  cursor: 'pointer',
  width: 'var(--switch-width)',
  padding: 2,
  background: checked ? ToggleSwitchColors.TrackBackgroundChecked : ToggleSwitchColors.TrackBackground,
  boxShadow: ToggleSwitchColors.TrackBorder,
  borderRadius: '100vh',
}));

const SwitchThumb = styled.span<{ checked: boolean }>(({ checked }) => ({
  width: 'var(--switch-size)',
  height: 'var(--switch-size)',
  display: 'block',
  border: ToggleSwitchColors.ThumbBorder,
  borderRadius: '50%',
  background: checked ? ToggleSwitchColors.ThumbBackgroundChecked : ToggleSwitchColors.ThumbBackground,
  boxShadow: ToggleSwitchColors.ThumbBoxShadow,
  transition: 'transform 0.15s linear',
  position: 'relative',
}));

const Input = styled.input<{ checked: boolean }>(({ checked }) => ({
  opacity: 0,
  position: 'absolute',
  [`:checked + ${SwitchTrack} > *`]: {
    transform: checked ? 'translateX(calc(var(--switch-width) / 2))' : 'translateX(0)',
  },
  [`:disabled + ${SwitchTrack}`]: {
    cursor: 'auto',
  },
  [`:focus + ${SwitchTrack} > *::before`]: {
    content: '""',
    boxShadow: ToggleSwitchColors.ThumbBorderFocus,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    borderRadius: 60,
  },
  [`:focus + ${SwitchTrack}`]: {
    boxShadow: ToggleSwitchColors.TrackBorderFocus,
  },
  [`:disabled + ${SwitchTrack}`]: {
    opacity: 0.7,
  },
}));

type ToggleSwitchProps = ComponentProps<typeof Input> & {
  onChange: (checked: boolean) => void;
  checked: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  'aria-label'?: string;
  name?: string;
  id?: string;
  className?: string;
};

export const ToggleSwitch: FC<ToggleSwitchProps> = (props) => (
  <Switch className={props.className}>
    <Input {...props} type="checkbox" role="switch" />
    <SwitchTrack checked={props.checked}>
      <SwitchThumb checked={props.checked} />
    </SwitchTrack>
  </Switch>
);
