import { createTransparentColor } from '../create-transparent-color';
import type {
  CornerRadiusType,
  ElevationType,
  GeneralType,
  HeroColorType,
  LayoutColors,
  PrimitiveColorType,
  SignalColorType,
  SurfaceColorType,
  TextColorType,
} from './color-types';

const PafBrandColors = {
  Silk: '#f3f4f1',

  // Primary Colors
  Primary: '#25ac61',
  PrimaryLight: '#3abd74',
  PrimaryHighlight: '#47c77a',

  // Alternate Colors
  Alternate: '#f04149',
  AlternateLight: '#ff5f60',
  AlternateHighlight: '#fc7680',

  // Neutral Colors
  Black: '#222829',
  Dark: '#4b5253',
  Darker: '#343b3c',
  Light: '#e0e4e3',
  Lighter: '#c7cbcb',
  Neutral: '#929797',
  White: '#ffffff',

  // Extra
  Yellow: '#eaaa3C',
};

const Primitive: PrimitiveColorType = {
  Primary: PafBrandColors.Primary,
  PrimaryTint: PafBrandColors.PrimaryHighlight,
  PrimaryContrast: PafBrandColors.White,

  Secondary: PafBrandColors.Alternate,
  SecondaryTint: PafBrandColors.AlternateLight,
  SecondaryContrast: PafBrandColors.White,

  Accent: PafBrandColors.Primary,
  AccentTint: PafBrandColors.PrimaryHighlight,
  AccentContrast: PafBrandColors.White,

  Gradient: PafBrandColors.Primary,
  GradientTint: PafBrandColors.PrimaryHighlight,
  GradientContrast: PafBrandColors.White,
};

const TextColors: TextColorType = {
  HeadingText: PafBrandColors.Black,
  BodyText: PafBrandColors.Dark,
  MutedText: PafBrandColors.Neutral,
  HighlightedText: PafBrandColors.Black,
  LinkText: PafBrandColors.Primary,
  ErrorText: PafBrandColors.Alternate,
  DisabledText: PafBrandColors.Lighter,
};

const Surface: SurfaceColorType = {
  Base: {
    Background: PafBrandColors.White,
    Foreground: PafBrandColors.Darker,
    Dimmed: PafBrandColors.Lighter,
  },
  Nested: {
    Background: '#f0f3f3',
    Foreground: PafBrandColors.Dark,
    Dimmed: PafBrandColors.Lighter,
  },
  Disabled: {
    Background: PafBrandColors.Light,
    Foreground: PafBrandColors.Neutral,
    Dimmed: PafBrandColors.Lighter,
  },
  Floating: {
    Background: PafBrandColors.White,
    Foreground: PafBrandColors.Darker,
    Dimmed: PafBrandColors.Lighter,
  },
};

const Signal: SignalColorType = {
  Success: PafBrandColors.Primary,
  SuccessContrast: PafBrandColors.White,

  Info: PafBrandColors.Primary,
  InfoContrast: PafBrandColors.White,

  Attention: PafBrandColors.Yellow,
  AttentionContrast: PafBrandColors.White,

  Danger: PafBrandColors.Alternate,
  DangerContrast: PafBrandColors.White,
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '12px',
  Large: '32px',
};

const Elevation: ElevationType = {
  Level1: '0 3px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level2: '0 4px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 18px var(--shadow-color, rgba(0, 0, 0, 0.15)), 0 3px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: GeneralType = {
  Bonus: PafBrandColors.Primary,
  BonusContrast: PafBrandColors.White,
};

const Layout: LayoutColors = {
  Background: PafBrandColors.Silk,
  BrandBorder: Primitive.Primary,
  BrowserTheme: PafBrandColors.Silk,
  LoadingColor: Primitive.Primary,
  Overlay: PafBrandColors.White + 'CC',
  Sidebar: {
    Background: PafBrandColors.Darker,
    Foreground: PafBrandColors.White,
  },
};

const Hero: HeroColorType = {
  Heading: PafBrandColors.White,
  Text: PafBrandColors.White,
  TextHighlight: Primitive.Secondary,

  Button: {
    Solid: Primitive.Secondary,
    Rest: Primitive.Secondary,
    Hover: Primitive.SecondaryTint,
    Active: Primitive.SecondaryTint,
    Text: Primitive.SecondaryContrast,
  },
};

export const Color = {
  ...PafBrandColors,
  ...TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,

  Background: PafBrandColors.Silk,

  // Shadows, highlight and transparency
  LighterHighlight: createTransparentColor(PafBrandColors.Light, 0.5),
  Highlight: createTransparentColor(PafBrandColors.Primary, 0.2),
  FocusHighlight: createTransparentColor(PafBrandColors.Primary, 0.33),
  AlternateFocusHighlight: createTransparentColor(PafBrandColors.Alternate, 0.33),
  Shadow: createTransparentColor(PafBrandColors.Black, 0.15),
  ShadowInset: createTransparentColor(PafBrandColors.White, 0.6),
  LightTransparent: createTransparentColor(PafBrandColors.White, 0.8),
  LightTransparent90: createTransparentColor(PafBrandColors.White, 0.9),
  DarkTransparent20: createTransparentColor(PafBrandColors.Black, 0.2),
  DarkTransparent50: createTransparentColor(PafBrandColors.Black, 0.5),
  DarkTransparent60: createTransparentColor(PafBrandColors.Black, 0.6),
  PrimaryTransparent80: createTransparentColor(PafBrandColors.Primary, 0.8),

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart} ${ColorEnd})`,
  AdaptiveBackground: PafBrandColors.Primary,

  // Theme color for mobile browsers
  BrowserTheme: PafBrandColors.Silk,
};
