import { SpeedyColor } from '@pafcloud/style';

export const Colors = {
  Text: SpeedyColor.BodyText,
  MessageSeenText: SpeedyColor.Alternate,
  PaginationText: SpeedyColor.BodyText,

  BorderColor: SpeedyColor.DarkTransparent10,

  Highlight: `${SpeedyColor.Primary}0D`, //5% opacity
  HighlightBorder: SpeedyColor.Primary,
};
