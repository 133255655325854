import type {
  CornerRadiusType,
  ElevationType,
  GeneralType,
  HeroColorType,
  LayoutColors,
  PrimitiveColorType,
  SignalColorType,
  SurfaceColorType,
  TextColorType,
} from './color-types';

const BrandColors = {
  Primary: '#fab',

  // Alternate Colors
  Alternate: '#fab',
  AlternateLight: '#fab',

  // Neutral Colors
  White: '#fab',
};

const Primitive: PrimitiveColorType = {
  Primary: '#fab',
  PrimaryTint: '#fab',
  PrimaryContrast: '#fab',

  Secondary: '#fab',
  SecondaryTint: '#fab',
  SecondaryContrast: '#fab',

  Accent: '#fab',
  AccentTint: '#fab',
  AccentContrast: '#fab',

  Gradient: '#fab',
  GradientTint: '#fab',
  GradientContrast: '#fab',
};

const TextColors: TextColorType = {
  HeadingText: '#fab',
  BodyText: '#fab',
  MutedText: '#fab',
  HighlightedText: '#fab',
  LinkText: '#fab',
  ErrorText: '#fab',
  DisabledText: '#fab',
};

const Surface: SurfaceColorType = {
  Base: {
    Background: '#fab',
    Foreground: '#fab',
    Dimmed: '#fab',
  },
  Nested: {
    Background: '#fab',
    Foreground: '#fab',
    Dimmed: '#fab',
  },
  Disabled: {
    Background: '#fab',
    Foreground: '#fab',
    Dimmed: '#fab',
  },
  Floating: {
    Background: '#fab',
    Foreground: '#fab',
    Dimmed: '#fab',
  },
};

const Signal: SignalColorType = {
  Success: '#fab',
  SuccessContrast: '#fab',

  Info: '#fab',
  InfoContrast: '#fab',

  Attention: '#fab',
  AttentionContrast: '#fab',

  Danger: '#fab',
  DangerContrast: '#fab',
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '8px',
  Large: '16px',
};

const Elevation: ElevationType = {
  Level1: '0 2px 3px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level2: '0 4px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 12px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: GeneralType = {
  Bonus: '#fab',
  BonusContrast: '#fab',
};

const Layout: LayoutColors = {
  Background: '#fab',
  BrandBorder: '#fab',
  BrowserTheme: '#fab',
  LoadingColor: '#fab',
  Overlay: '#fab',
  Sidebar: {
    Background: '#fab',
    Foreground: '#fab',
  },
};

const Hero: HeroColorType = {
  Heading: '#fab',
  Text: '#fab',
  TextHighlight: '#fab',

  Button: {
    Solid: '#fab',
    Rest: '#fab',
    Hover: '#fab',
    Active: '#fab',
    Text: '#fab',
  },
};

export const Color = {
  ...BrandColors,
  ...TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,

  Background: '#fab',

  // Shadows, highlight and transparency
  FocusHighlight: '#fab',
  Shadow: '#fab',
  ShadowInset: '#fab',
  DarkTransparent50: '#fab',

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart} ${ColorEnd})`,
  AdaptiveBackground: '#fab',

  // Theme color for mobile browsers
  BrowserTheme: '#fab',
};
