/**
 * @generated SignedSource<<1ae65ba09e873e2d81f5daf25db83adb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bingoTickets_offer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useBonusOfferSteps_offer">;
  readonly " $fragmentType": "bingoTickets_offer";
};
export type bingoTickets_offer$key = {
  readonly " $data"?: bingoTickets_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"bingoTickets_offer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "bingoTickets_offer"
};

(node as any).hash = "fa3c997f67830c443096ef20630ec820";

export default node;
