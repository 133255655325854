export const EMAIL_PATTERN = /^\S+@\S+\.\S+$/;
export const NICKNAME_PATTERN = /^[a-zA-Z0-9_]+$/;
export const PASSWORD_PATTERN =
  /^(?=.*[0-9])(?=.*[a-z\u00DF-\u00f6\u00F8-\u00FF\u0451\u0430-\u044F])(?=.*[A-Z\u00C0-\u00D6\u00D8-\u00DE\u0401\u0410-\u042F]).*$/;

export const STREET_ADDRESS_PATTERN = /^[^!"¤%<>?@£*]*$/;
export const CITY_PATTERN = /^[^!"#¤%/<>&()?@£*]*$/;
export const POSTAL_CODE_PATTERNS: Record<string, RegExp> = {
  SE: /^[0-9]{3} ?[0-9]{2}$/,
  LV: /^LV-([0-9]{2})([0-9]{2})$/,
  ES: /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/,
};

export const SSN_PATTERN: Record<string, RegExp> = {
  SE: /^(19|20)?[0-9]{6}[-+ ]?[0-9]{4}$/,
  EE: /^[1-6][0-9]{2}[0,1][0-9][0-9]{2}[0-9]{4}$/,
  ES: /^[X-Z|0-9][0-9]{7}[A-HJ-NP-TV-Z]$/,
  LV: /^((0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(\d{2})-?(0|1|2)(\d{3})(\d)|32\d{9})$/,
};

export const PHONE_NUMBER_PATTERN = /^\s*($|(\+?(\d([()]|\s|-)*){2,49}$))/;

const COUNTRY_CODE_OR_LOCAL_SWISS = /(\+[ \-(]*\d{1,3}|00[ \-(]*\d{1,3}|0[ \-(]*[2-9]\d)/;
const ANY_FOLLOWING_NUMBER = /[ \-()]*\d([ \-()]*\d){2,48}/;

export const MOBILE_PHONE_NUMBER_PATTERNS = {
  SE: /^\s*(((\+|00)46\s*-?0?-?)|0+\s*)(7[02369])\s*-?\s*([\d](\s|-)*){7}$/,
  FI: /^\s*(((\+|00)358\s*-?0?-?)|0+\s*)+\s*(4.|457|50)\s*-?\s*([\d](\s|-)*){7}$/,
  ES: /^\s*(\+34|0034)?[ -]*[67][ -]*([0-9][ -]*){8}$/,
  CH: new RegExp(`^\\s*${COUNTRY_CODE_OR_LOCAL_SWISS.source}${ANY_FOLLOWING_NUMBER.source}\\s*$`),
} as const;

export const LOOSE_NORDIC_AND_BALTIC_NUMBER_PATTERNS =
  /^\s?(((\+|00)(45|46|47|298|299|354|358|370|371|372))|0)[\s-]?(\d(\s|-)?){0,10}$/;

export const STRICT_NORDIC_AND_BALTIC_NUMBER_PATTERNS = {
  DK: /^\s?((\+|00)?45(\s|-)?)?(\d(\s|-)?){8}$/,
  EE: /^\s?((\+|00)?372(\s|-)?)?(\d(\s|-)?){7,8}$/,
  FI: MOBILE_PHONE_NUMBER_PATTERNS.FI,
  FO: /^\s?((\+|00)?298(\s|-)?)?(\d(\s|-)?){6}$/,
  GL: /^\s?((\+|00)?299(\s|-)?)?(\d(\s|-)?){6}$/,
  IS: /^\s?((\+|00)?354(\s|-)?)?(\d(\s|-)?){7}$/,
  LT: /^\s?((\+|00)?370(\s|-)?)?(\d(\s|-)?){8}$/,
  LV: /^\s?((\+|00)?371(\s|-)?)?(\d(\s|-)?){8}$/,
  NO: /^\s?((\+|00)?47(\s|-)?)?(\d(\s|-)?){8}$/,
  SE: MOBILE_PHONE_NUMBER_PATTERNS.SE,
};

export const PIN_CODE_PATTERN = /^[A-Z]{3}-[0-9a-zA-Z]{4}$/;

// A valid IBAN, taken from IbanLookupService
export const IBAN_PATTERN = /^[A-Z]{2}\d{2}[A-Z0-9]{0,30}$/i;

// The ISO 8601 standard date format (yyyy-mm-dd). Returned by date input.
export const ISO_DATE_BIRTH_PATTERN = /^(19|20)[0-9]{2}-[0-9]{2}-[0-9]{2}$/;

// Lativa, Spanish date format (dd-mm-yyyy), (dd/mm/yyyy) or (dd.mm.yyyy).
export const DATE_BIRTH_PATTERN = /^[0-3][0-9][-/.][0-1][0-9][-/.](19|20)[0-9]{2}$/;
