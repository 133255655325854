import type { FC, ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { buildContext } from '../buildContext';
import type {
  FeatureFlagContext_data$data,
  FeatureFlagContext_data$key,
} from './__generated__/FeatureFlagContext_data.graphql';

const dataFragment = graphql`
  fragment FeatureFlagContext_data on Query {
    site {
      featureFlags {
        key
      }
    }
  }
`;

const [FlagContext, useFlag] = buildContext<FeatureFlagContext_data$data['site']['featureFlags'] | null>(
  'FeatureFlagContext',
);

export const useFeatureFlag = (key: FlagConfiguration) => {
  const featureFlags = useFlag();

  if (featureFlags == null) {
    return false;
  }

  return featureFlags.some((flag) => flag?.key === key);
};

type Props = {
  data: FeatureFlagContext_data$key | null;
  children: ReactNode;
};

export const FeatureFlagProvider: FC<Props> = ({ data, children }) => {
  const resolvedData = useFragment(dataFragment, data);

  if (resolvedData == null) {
    return <FlagContext.Provider value={[]}>{children}</FlagContext.Provider>;
  }

  return <FlagContext.Provider value={resolvedData.site.featureFlags}>{children}</FlagContext.Provider>;
};

/**
 * Available Feature Flags
 *
 * format:
 * {
 *   /** description of some-flag that is shown in editor *\/
 *   SomeFlag = 'some-flag',
 * }
 */
export enum FlagConfiguration {
  Signicat = 'signicat',
}
