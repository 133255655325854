import styled from '@emotion/styled';
import { Breakpoint, Color, Font, FontTextSize } from '@pafcloud/style';
import { Image, InternalLink } from '@pafcloud/base-components';

export const ResultImage = styled(Image)({
  gridArea: 'img',
  position: 'relative',
  borderRadius: '25%',
});

export const ResultLink = styled(InternalLink)({
  display: 'grid',
  gridTemplateRows: '30px 30px',
  gridTemplateColumns: '60px auto',
  gridTemplateAreas: `"img title"
           "img supplier"`,

  mark: {
    display: 'inline-block',
    background: 'transparent',
    color: 'inherit',
    textDecoration: 'underline',
    textDecorationThickness: 2,
  },
  outline: 'none',
});

export const ResultList = styled.ol({
  gridColumn: '1 / span 3',
  listStyle: 'none',
  margin: 0,
  padding: '0 0 20px',
  outline: 'none',
});

export const ResultListItem = styled.li({
  display: 'grid',
  justifyContent: 'space-between',
  gridTemplateColumns: '1fr max-content',

  '&:focus-within, &:hover': {
    backgroundColor: `${Color.Primitive.Primary}20`,
  },
  padding: '4px 20px',
});

export const ResultDeleteButton = styled.button({
  color: Color.MutedText,
  padding: 20,
  marginRight: -20,
  border: 'none',
  background: 'transparent',
  opacity: 1,
  alignContent: 'center',
  justifyContent: 'center',
  outline: 'none',
  cursor: 'pointer',
  [`${ResultListItem}:hover &`]: {
    opacity: 1,
  },
  '&:focus, &:hover': {
    opacity: 1,
    color: Color.BodyText,
  },
  [Breakpoint.LaptopOrLarger]: {
    opacity: 0,
  },
});

export const ResultTitle = styled.strong({
  gridArea: 'title',
  margin: '0 0 0 10px',
  padding: 0,
  alignSelf: 'end',
  fontSize: FontTextSize.Normal,
  fontFamily: Font.Heading,
  color: Color.BodyText,
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
});

export const ResultSupplier = styled.span({
  gridArea: 'supplier',
  margin: '0 0 0 10px',
  padding: 0,
  alignSelf: 'start',
  fontSize: FontTextSize.Small,
  fontFamily: Font.Body,
  color: Color.MutedText,
});

export const ResultWrapper = styled.section({
  width: '100%',
  display: 'grid',
  position: 'relative',
  gridTemplateColumns: '20px 1fr 10px',
});

export const ResultHeader = styled.h3({
  gridColumn: 2,
  fontSize: FontTextSize.Normal,
  color: Color.BodyText,
});

export const NoResultMessage = styled.p({
  gridColumn: 2,
  margin: 0,
  padding: '0 0 20px 0',
  color: Color.MutedText,
});
