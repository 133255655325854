import Router from 'next/router';
import { useState, useEffect } from 'react';

const GAME_HOME_LOCATION = 'GameHomeLocation';

export function rememberGameHomeUrl() {
  sessionStorage.setItem(GAME_HOME_LOCATION, `${location.pathname}${location.search}`);
}

export function useGameHomeUrl(fallbackUrl = '/') {
  const [homeUrl, setHomeUrl] = useState(fallbackUrl);

  useEffect(() => {
    const savedUrl = sessionStorage.getItem(GAME_HOME_LOCATION);

    // Do not save a new homeUrl if the users is already in a game
    // This is applicable on prank and pml when opening game from the sidebar search
    if (savedUrl && !savedUrl.includes('/game/')) {
      setHomeUrl(savedUrl);
    }
  }, []);

  useEffect(() => {
    const clearHomeLocation = () => {
      sessionStorage.removeItem(GAME_HOME_LOCATION);
    };

    Router.events.on('routeChangeComplete', clearHomeLocation);
    return () => {
      Router.events.off('routeChangeComplete', clearHomeLocation);
    };
  }, []);

  return homeUrl;
}
